import React, { Component } from 'react';
import styled from 'styled-components';

import { LayoutContainer, PageBody } from '../../layout';

class Loading extends Component {
  render() {
    return (
      <LayoutContainer>
        <PageBody>
          <LoadingLayout>
            <LoadingCenter>
              <LoadingSpinner />
              <LoadingMessage>
                {this.props.children ? this.props.children : null}
              </LoadingMessage>
            </LoadingCenter>
          </LoadingLayout>
        </PageBody>
      </LayoutContainer>
    );
  }
}

const LoadingLayout = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100vh;
`;

const LoadingCenter = styled.div`
  width: 100%;
`;

const LoadingMessage = styled.div`
  display: block;
`;

export const LoadingSpinner = () => (
  <svg
    width="60"
    height="15"
    viewBox="0 0 120 30"
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
    data-testid="loading-spinner">
    <circle cx="15" cy="15" r="15">
      <animate
        attributeName="r"
        from="15"
        to="15"
        begin="0s"
        dur="0.8s"
        values="15;9;15"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fillOpacity"
        from="1"
        to="1"
        begin="0s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="60" cy="15" r="9" fillOpacity="0.3">
      <animate
        attributeName="r"
        from="9"
        to="9"
        begin="0s"
        dur="0.8s"
        values="9;15;9"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fill-opacity"
        from="0.5"
        to="0.5"
        begin="0s"
        dur="0.8s"
        values=".5;1;.5"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="105" cy="15" r="15">
      <animate
        attributeName="r"
        from="15"
        to="15"
        begin="0s"
        dur="0.8s"
        values="15;9;15"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fill-opacity"
        from="1"
        to="1"
        begin="0s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

export default Loading;
