import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const Content = styled.div`
  margin: 0 auto;
  max-width: ${(p) => p.theme.maxWidth.text};

  ${media.small`
  `};

  ${media.medium`
    
  `};

  ${media.large`

  `};
`;
