import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.span.attrs({ 'data-testid': 'spinner' })`
  box-sizing: border-box;
  display: ${(p) => (p.inline ? 'inline-block' : 'block')};
  width: 24px;
  height: 24px;
  margin: auto auto;
  border-radius: 50%;
  /* border: 2px solid ${(p) => p.theme.foreground2}; */
  border-top: 2px solid ${(p) => p.theme.foreground};
  border-right: 2px solid transparent;
  animation: ${spin} 0.6s linear infinite;
`;

export default Spinner;
