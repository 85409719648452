import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const CollectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${(p) => p.theme.padding * 2}px;
  margin-right: ${(p) => p.theme.padding * 2}px;
  margin-top: ${(p) => p.theme.padding * 4}px;

  ${media.medium`
    margin-left: ${(p) => p.theme.padding}px;
    margin-bottom: 36px;
  `}

  ${media.large`
    padding: 0;
    
  `};
`;
