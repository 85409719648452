import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectReleaseById } from '../../releases/releases.selectors';
import { selectArticleCollectionById } from '../../articles/articles.selectors';
import { selectCollectionById } from '../../videos/VideosCollection.redux';
import CollectionList from '../../releases/components/CollectionList';
import { ListBlock, ListTitle, ListExpandButton } from '../../layout';
import { sortByPosition } from '..';

// <EntityList category={category} entity="releases" entityIds={[123,124,145]} />
// type Props = {
//   category?: string; // optional category for a section header
//   entityIds: number[]; // array of item ids
//   entity: string; // name of the state.entities key
//   truncate?: number; // number of items to show before truncating and showing more link
// };

// type State = {
//   isTruncated: boolean;
// };

class CollectionListContainer extends Component {
  // sort by X
  // stretch: collapse after x items, provide some kind of more link
  state = {
    isExpanded: false,
  };

  createEntityUrl = (item) => {
    return `/audio/${item.albumId}`;
  };

  onClickMore = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      return { isExpanded: !prevState.isExpanded };
    });
  };

  getTruncate = (_) => {
    const { truncate = 4 } = this.props;
    const { isExpanded } = this.state;

    if (isExpanded) return 10000000; // TODO this is ridiculous
    return truncate;
  };

  render() {
    const { entities, entity, title, truncate } = this.props;
    const { isExpanded } = this.state;

    // if we can't find any entities, this category is not required here
    if (entities.length === 0) {
      return null;
    }

    return (
      <ListBlock>
        {title && (
          <ListTitle>
            <a href="/" onClick={this.onClickMore}>
              {title} <small>{entities.length}</small>
            </a>
            <button onClick={this.onClickMore}>
              {entities.length > truncate && (
                <ListExpandButton>
                  {isExpanded ? `hide` : `see all`}{' '}
                </ListExpandButton>
              )}
            </button>
          </ListTitle>
        )}

        <CollectionList
          collections={
            truncate < entities.length && isExpanded
              ? entities
              : entities.slice(0, truncate)
          }
          truncate={truncate}
          entity={entity}
          isExpanded={isExpanded}
        />
      </ListBlock>
    );
  }
}

const getEntitySelector = (entity) => {
  switch (entity) {
    case 'collection':
      return selectCollectionById;
    case 'release':
      return selectReleaseById;
    case 'articleCollection':
      return selectArticleCollectionById;
    default:
      return selectReleaseById;
  }
};

const mapStateToProps = (state, ownProps) => {
  const selector = getEntitySelector(ownProps.entity);
  const entities = ownProps.entityIds.map((id) => selector(state, id));
  const filteredEntities = entities.filter((entity) => entity !== undefined);

  return {
    entities: sortByPosition(filteredEntities),
  };
};

export default connect(mapStateToProps)(CollectionListContainer);
