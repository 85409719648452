import * as React from 'react';
import styled from 'styled-components';
import { Icon, sizeCloudinaryImage } from '../../common';
import { ContentLockLabel } from '../../layout';
import { media } from '../../layout/themes/mediaQueries';
import { FadeIn } from '../../common';
import { Release } from '../../common/types/Release';
import { Icons } from '../../common/components/Icon';

type Props = {
  src: string;
  release: Release;
  isLocked: boolean;
};

class ReleaseArtwork extends React.Component<Props> {
  render() {
    const { src, release, isLocked } = this.props;
    return (
      <Comp>
        <FadeIn>
          {(onload) => (
            <>
              <img
                src={sizeCloudinaryImage(src, 300)}
                alt={release.name}
                onLoad={onload}
              />
              {isLocked && (
                <ContentLockLabel data-test="content-lock">
                  <Icon icon={Icons.lock} color="#ffffff" />
                </ContentLockLabel>
              )}
            </>
          )}
        </FadeIn>
      </Comp>
    );
  }
}

const Comp = styled.div`
  position: relative;
  line-height: 0;
  overflow: hidden;
  border-radius: 3px;
  transition: transform 250ms;
  transition-timing-function: cubic-bezier(${(p) => p.theme.cubicBezir});

  div {
    width: 100%;
  }

  img {
    border-radius: 3px;
    overflow: hidden;
    width: 100%;
    transition: transform 250ms;
    transition-timing-function: cubic-bezier(0.54, 1.09, 0.87, 0.33);
  }

  ${media.medium`
    &:hover {
      transform: scale(1.25);
      z-index: 90;
    }

    &:hover img {
      /* transform: scale(1.1); */
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    }
  `}
`;

export default ReleaseArtwork;
