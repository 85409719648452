import { useSelector } from 'react-redux';
import FacebookPixel from './FacebookPixel';
import GoogleAnalytics from './GoogleAnalytics';
import { selectConsent } from '../../user/user.selectors';

const ANALYTICS = {
  tvfit: {
    googleId: 'UA-130109388-1',
    facebookId: '248160526104301',
  },
  tvfit2: {
    googleId: 'UA-130109388-1',
    facebookId: '888261821602686',
  },
  example: {
    googleId: 'UA-66841986-1',
    facebookId: '767799580388793',
  },
  georgeworld: {
    googleId: 'UA-156357913-1',
    facebookId: '2656810237872150',
  },
  motivationalskills: {
    googleId: 'UA-130109388-1',
  },
};

export default function Tracking({ channel }) {
  const consent = useSelector(selectConsent);
  // if we don't have consent we don't render any tracking
  // components at all
  if (!consent.analytics) {
    return null;
  }

  if (!ANALYTICS[channel.cleanname]) {
    return null;
  }

  const { googleId, facebookId } = ANALYTICS[channel.cleanname];

  return (
    <>
      {googleId ? <GoogleAnalytics googleId={googleId} /> : null}
      {facebookId ? <FacebookPixel facebookId={facebookId} /> : null}
    </>
  );
}
