import styled, { css } from 'styled-components';
import { media } from '../themes/mediaQueries';

const ListWrapperGrid = css`
  display: flex;
  flex-flow: wrap;
  padding-left: ${(p) => p.theme.padding}px;
  padding-right: ${(p) => p.theme.padding}px;

  ${media.medium`
    padding-left: 0;
    padding-right: 0;
    margin-left: ${(p) => -p.theme.padding}px;
    margin-right: ${(p) => -p.theme.padding}px;
  `}
`;

const ListWrapperList = css`
  background: ${(p) => p.theme.peak};
  max-width: ${(p) => p.theme.maxWidth.text};
  margin: 0 auto ${(p) => p.theme.padding * 6}px;

  ${media.medium`
    border-radius: ${(p) => p.theme.radius}px;
  `};
`;

export const ListWrapper = styled.ul`
  ${(p) => (p.type === 'list' ? ListWrapperList : ListWrapperGrid)};
`;

export const ListWrapper2 = styled.div`
  display: flex;
  flex-flow: nowrap;
  flex-direction: row;
`;
