import { API_ERROR, CLEAR_ERRORS } from './api.constants';
import { APIErrorAction } from './api.types';

export const doReportError = (errorAction: APIErrorAction) => {
  return {
    ...errorAction,
    failType: errorAction.type,
    type: API_ERROR,
  };
};

export const doClearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
