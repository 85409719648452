import { RSAA } from 'redux-api-middleware';

import { Track } from '../common/types/Track';
import {
  DoFetchStreamAudioAction,
  DoPlaybackTrackAction,
  DoPlaybackPauseAction,
  DoPlaybackSeekAction,
  DoPlaybackPlayAction,
  DoPlaybackStopAction,
  DidPlayerReadyAction,
  DidPlaybackPlayAction,
  DidPlaybackPauseAction,
  DidPlaybackErrorAction,
  DidPlaybackCompleteAction,
  DidPlaybackSeekAction,
} from './player.types';

import {
  REQ_STREAM_AUDIO,
  RES_STREAM_AUDIO_SUCCESS,
  RES_STREAM_AUDIO_FAILURE,
  DO_PLAYBACK_TRACK,
  DO_PLAYBACK_PLAY,
  DO_PLAYBACK_PAUSE,
  DO_PLAYBACK_SEEK,
  DO_PLAYBACK_STOP,
  DID_PLAYER_READY,
  DID_PLAYBACK_PLAY,
  DID_PLAYBACK_PAUSE,
  DID_PLAYBACK_SEEK,
  DID_PLAYBACK_COMPLETE,
  DID_PLAYBACK_ERROR,
  DID_PLAYER_SCRIPT_LOAD,
  DID_PLAYER_SCRIPT_ERROR,
} from './player.constants';

/**
 * Create a fetch audio stream action with a track id.
 *
 * TODO: How do we test these async actions?
 *
 * @param {number} trackId  A track id
 */
export const doFetchStreamAudio = (
  trackId: number,
  channelId: number
): DoFetchStreamAudioAction => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/user/streaming/${trackId}?type=all&channelIdentifier=${channelId}`,
      types: [
        REQ_STREAM_AUDIO,
        RES_STREAM_AUDIO_SUCCESS,
        RES_STREAM_AUDIO_FAILURE,
      ],
    },
  };
};

/**
 * Create a play this track action that include the track object
 * as a payload.
 *
 * @param {Track} track The track to be played
 * @return {PlaybackTrackAction} Playback track action
 */
export const doPlayTrack = (
  track: Track,
  context: Track[] = []
): DoPlaybackTrackAction => {
  // include all tracks after this one in the context
  let trackIndex = 0;
  if (context) {
    trackIndex = context.findIndex((item) => {
      return item.trackId === track.trackId;
    });
  }

  return {
    type: DO_PLAYBACK_TRACK,
    payload: { track, context: context.slice(trackIndex + 1) },
  };
};

/**
 * Create an update seek position action.
 *
 * @param {number} position The current seek position in seconds
 * @param {number} duration The total duration in seconds
 * @return {PlaybackSeekAction}
 */
export const doPlaybackSeek = (
  position: number,
  duration: number
): DoPlaybackSeekAction => {
  return {
    type: DO_PLAYBACK_SEEK,
    payload: { position, duration },
  };
};

/**
 * Create a pause action.
 */
export const doPlaybackPause = (): DoPlaybackPauseAction => {
  return {
    type: DO_PLAYBACK_PAUSE,
    payload: null,
  };
};

/**
 * Create a playback resume action.
 */
export const doPlaybackPlay = (): DoPlaybackPlayAction => {
  return {
    type: DO_PLAYBACK_PLAY,
    payload: null,
  };
};

export const doPlaybackStop = (): DoPlaybackStopAction => {
  return {
    type: DO_PLAYBACK_STOP,
    payload: null,
  };
};

/**
 * The following actions are not exposed outside the player module (index.js).
 * They're trigged by events from the player itself and should remain
 * internal only. We export them for testing purposes…
 */

/**
 * Create a player ready action.
 */
export const didPlayerReady = (): DidPlayerReadyAction => {
  return {
    type: DID_PLAYER_READY,
    payload: null,
  };
};

/**
 * Create a playback begins action.
 */
export const didPlaybackPlay = (): DidPlaybackPlayAction => {
  return {
    type: DID_PLAYBACK_PLAY,
    payload: null,
  };
};

/**
 * Create a playback pause action.
 */
export const didPlaybackPause = (): DidPlaybackPauseAction => {
  return {
    type: DID_PLAYBACK_PAUSE,
    payload: null,
  };
};

/**
 * Create a playback complete action.
 */
export const didPlaybackComplete = (): DidPlaybackCompleteAction => {
  return {
    type: DID_PLAYBACK_COMPLETE,
    payload: null,
  };
};

/**
 * Create a playback error action.
 */
export const didPlaybackError = (message: string): DidPlaybackErrorAction => {
  return {
    type: DID_PLAYBACK_ERROR,
    payload: new Error(message),
  };
};

/**
 * Create a playback seek update event.
 *
 * @param {number} position The current position in seconds
 * @param {number} duration The total track duration in seconds
 */
export const didPlaybackSeek = (
  position: number,
  duration: number
): DidPlaybackSeekAction => {
  return {
    type: DID_PLAYBACK_SEEK,
    payload: {
      position,
      duration,
    },
  };
};

export const didPlayerScriptLoad = () => {
  return {
    type: DID_PLAYER_SCRIPT_LOAD,
  };
};

export const didPlayerScriptError = () => {
  return {
    type: DID_PLAYER_SCRIPT_ERROR,
  };
};
