import { RSAA } from 'redux-api-middleware';
import {
  REQ_AUTH_LOGOUT,
  REQ_AUTH,
  RES_AUTH_SUCCESS,
  RES_AUTH_FAIL,
  REQ_REGISTER,
  RES_REGISTER_FAIL,
  RES_REGISTER_SUCCESS,
  REQ_USER,
  RES_USER_SUCCESS,
  RES_USER_FAIL,
  SET_TOKEN,
  SET_COOKIE_CONSENT,
} from './user.constants';
import { detectChannel } from '../detectChannel';
import { quickCookie } from './quickCookie';

export const doLogin = (fields) => {
  const uniqueName = detectChannel();

  let body = {
    email: fields.email,
    password: fields.password,
  };

  if (fields.license) {
    body.userAgreementTickboxes = {
      analyticsOptIn: fields.analytics,
      channelEulaAgreed: fields.license,
      supapassEulaAgreed: fields.license,
      channelMarketingOptIn:
        fields.channelMarketingOptIn === undefined
          ? null
          : fields.supapassMarketingOptIn,
      supapassMarketingOptIn:
        fields.supapassMarketingOptIn === undefined
          ? null
          : fields.supapassMarketingOptIn,
    };
  }

  return {
    [RSAA]: {
      method: 'POST',
      endpoint: '/user/login',
      headers: {
        'Content-Type': 'application/json',
        'Channel-Identifier': uniqueName,
      },
      body: JSON.stringify(body),
      types: [REQ_AUTH, RES_AUTH_SUCCESS, RES_AUTH_FAIL],
    },
  };
};

export const doRegister = (fields) => {
  const uniqueName = detectChannel();
  return {
    [RSAA]: {
      method: 'POST',
      endpoint: '/user/register',
      headers: {
        'Content-Type': 'application/json',
        'Channel-Identifier': uniqueName,
      },
      body: JSON.stringify({
        email: fields.email,
        password: fields.password,
        userAgreementTickboxes: {
          analyticsOptIn: fields.analytics,
          channelEulaAgreed: fields.license,
          supapassEulaAgreed: fields.license,
          channelMarketingOptIn:
            fields.channelMarketingOptIn === undefined
              ? null
              : fields.supapassMarketingOptIn,
          supapassMarketingOptIn:
            fields.supapassMarketingOptIn === undefined
              ? null
              : fields.supapassMarketingOptIn,
        },
      }),
      types: [REQ_REGISTER, RES_REGISTER_SUCCESS, RES_REGISTER_FAIL],
    },
  };
};

export const doFetchUser = () => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: '/user',
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_USER, RES_USER_SUCCESS, RES_USER_FAIL],
    },
  };
};

export const doSetTokenFromRequest = (req) => {
  const cookie = req ? req.headers.cookie : '';
  const token = quickCookie(cookie);
  return {
    type: SET_TOKEN,
    payload: token || '',
  };
};

export const doLogout = () => {
  return { type: REQ_AUTH_LOGOUT };
};

export function doSetCookieConsent(label, bool) {
  return {
    type: SET_COOKIE_CONSENT,
    payload: { label, bool },
  };
}
