import React, { useState } from 'react';
import format from 'date-fns/format';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { BundleBox } from '../../layout';
import { Bundle } from '../../common/types/Bundle';
import {
  BundleSubscription,
  BundleSubscriptionStatus,
  PaymentProviderCode,
} from '../../common/types/BundleSubscription';
import UnsubscribeButton from './UnsubscribeButton';
import { Channel } from '../../common/types/Channel';
import { doFetchUser } from '../../user';
import { doFetchBundles, doFetchBundlePlans } from '../subscribe.actions';
import configFeatures from '../../configFeatures';

type Props = {
  bundle: Bundle;
  subscription: BundleSubscription;
  channel: Channel;
};

const CARD_STATUS = {
  PC: 'Pending cancellation',
  PPC: 'Pending cancellation',
  A: 'Active',
  C: 'Cancelled',
};

function renderManageSubscriptionMessage(
  paymentProviderCode: PaymentProviderCode
) {
  let devicePlatform = '';
  let helpLink = '';

  if (paymentProviderCode === PaymentProviderCode.S) {
    return null;
  }

  switch (paymentProviderCode) {
    case PaymentProviderCode.A:
      devicePlatform = 'iOS';
      helpLink = 'https://support.apple.com/en-gb/HT202039';
      break;
    case PaymentProviderCode.G:
      devicePlatform = 'Android';
      helpLink =
        'https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en';
      break;
  }

  return (
    <span className="mx-4 mt-4 text-foreground-secondary">
      Manage this subscription from your {devicePlatform} device.{' '}
      {helpLink !== '' ? (
        <a
          className="underline"
          rel="noopener noreferrer"
          target="_blank"
          href={helpLink}>
          Find out how
        </a>
      ) : null}
    </span>
  );
}

function SubscriptionCard({ bundle, subscription, channel }: Props) {
  const [unsubscribeError, setUnsubscribeError] = useState<string>('');
  const dispatch = useDispatch();

  function onUnsubscribeError(error: string) {
    setUnsubscribeError(error);
  }

  function onUnsubscribeSuccess() {
    setTimeout(() => {
      dispatch(doFetchUser());
      dispatch(doFetchBundles(channel.cleanname));
      dispatch(doFetchBundlePlans(channel.cleanname));
    }, 0);
  }

  return (
    <BundleBox align="left" data-test="bundle-card">
      <SubscriptionTitle>{bundle.title}</SubscriptionTitle>
      <SubscriptionStatus>
        <DataColourCode status={subscription.status}>
          {CARD_STATUS[subscription.status] || '-'}
        </DataColourCode>{' '}
        · Subscription
      </SubscriptionStatus>
      <SubscriptionStatus>
        {subscription.cancellationDate && (
          <>
            <p>
              Your subscription was cancelled on{' '}
              <strong>
                {format(subscription.cancellationDate, 'Do MMM YYYY')}
              </strong>
              .
              <br />
              You still have access until{' '}
              <strong>{format(subscription.endDate, 'Do MMM YYYY')}</strong>.
            </p>
          </>
        )}
      </SubscriptionStatus>
      {subscription.status !== BundleSubscriptionStatus.C && (
        <>
          <DataLabel>Active until</DataLabel>
          <DataValue>{format(subscription.endDate, 'Do MMM YYYY')}</DataValue>
        </>
      )}

      {renderManageSubscriptionMessage(subscription.paymentProviderCode)}

      {configFeatures.unsubscribe ? (
        <>
          {subscription.paymentProviderCode === PaymentProviderCode.S &&
            subscription.status === BundleSubscriptionStatus.A && (
              <div className="pt-3 pl-3 flex flex-col">
                <DataValue>{unsubscribeError}</DataValue>
                <UnsubscribeButton
                  subscription={subscription}
                  channelId={channel.artistId}
                  onError={onUnsubscribeError}
                  onSuccess={onUnsubscribeSuccess}
                />
              </div>
            )}
        </>
      ) : null}
    </BundleBox>
  );
}

const DataLabel = styled.span`
  text-transform: uppercase;
  color: ${(p) => p.theme.footerForeground};
  font-size: 16px;
  margin: 0 ${(p) => p.theme.padding * 2}px;
`;

const DataValue = styled.span`
  font-size: 16px;
  margin: 0 ${(p) => p.theme.padding * 2}px;
`;

const DataColourCode = styled.span`
  color: ${(p) => selectColour(p.status, p.theme)};
`;

const SubscriptionStatus = styled.span`
  margin: 0 ${(p) => p.theme.padding * 2}px;
  margin-bottom: ${(p) => p.theme.padding}px;
  font-size: 16px;
`;

const SubscriptionTitle = styled.span`
  font-size: 24px;
  margin: ${(p) => p.theme.padding * 3}px 0 ${(p) => p.theme.padding}px
    ${(p) => p.theme.padding * 2}px;
`;

function selectColour(status, theme) {
  switch (status) {
    case 'A':
      return theme.action2;
    case 'C':
      return theme.action3;
    case 'PC':
      return theme.action1;
    default:
      return theme.foreground2;
  }
}

export default SubscriptionCard;
