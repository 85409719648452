import styled from 'styled-components';
import { media } from '../../layout/themes/mediaQueries';
import { transparentize } from 'polished';

export const SectionList = styled.div`
  section:nth-child(odd) {
    background-color: ${(props) => props.theme.background2};
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: ${({ edges, theme }) => (edges ? 0 : theme.padding * 2)}px;
  padding-top: ${({ theme, hero }) =>
    hero ? theme.padding * 9 : theme.padding * 5}px;
  padding-bottom: ${({ hero, theme }) =>
    hero ? theme.padding * 9 : theme.padding * 5}px;

  /* this is kinda nasty, i guess */
  ${(props) =>
    props.hero
      ? `
      background: linear-gradient(to bottom,
        ${transparentize(0.4, props.theme.background)},
        ${transparentize(0.4, props.theme.background)}
      ), url(${props.backgroundImage});
    `
      : null}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  ${media.large`
    padding-top: ${({ theme, hero }) =>
      hero ? theme.padding * 14 : theme.padding * 10}px;
    padding-bottom: ${({ hero, theme }) =>
      hero ? theme.padding * 14 : theme.padding * 10}px;
  `};
`;

export const Title = styled.h1`
  font-weight: 600;
  max-width: ${({ theme }) => theme.maxWidth.text};
  margin-left: auto;
  margin-right: auto;
  color: ${(p) => (p.hero ? p.theme.foreground : p.theme.foreground)};

  font-size: ${({ theme, hero }) =>
    hero ? theme.type.heroTitle[0] : theme.type.title[0]};
  line-height: ${({ theme, hero }) =>
    hero ? theme.lineHeight.heroTitle[0] : theme.lineHeight.title[0]};

  ${media.large`
    font-size: ${({ theme, hero }) =>
      hero ? theme.type.heroTitle[1] : theme.type.title[1]};
    line-height: ${({ theme, hero }) =>
      hero ? theme.lineHeight.heroTitle[1] : theme.lineHeight.title[1]};
  `};
`;

export const Subtitle = styled.h2`
  margin-top: ${({ theme, hero }) => theme.padding}px;
  max-width: ${({ theme }) => theme.maxWidth.text};
  margin-left: auto;
  margin-right: auto;

  font-size: ${({ theme, hero }) =>
    hero ? theme.type.heroSubtitle[0] : theme.type.subtitle[0]};
  line-height: ${({ theme, hero }) =>
    hero ? theme.lineHeight.heroSubtitle[0] : theme.lineHeight.subtitle[0]};
  color: ${({ theme, hero }) => (hero ? theme.foreground2 : theme.foreground2)};
  font-weight: 500;
  max-width: ${({ theme }) => theme.maxWidth.text};

  ${media.large`
    margin-top: ${(props) => props.theme.padding * 2}px;
    font-size: ${({ theme, hero }) =>
      hero ? theme.type.heroSubtitle[1] : theme.type.subtitle[1]};
    line-height: ${({ theme, hero }) =>
      hero ? theme.lineHeight.heroSubtitle[1] : theme.lineHeight.subtitle[1]};
  `};
`;

export const Button = styled.a`
  background: ${(props) => props.theme.accentColour};
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  display: inline-block;
  font-size: ${(props) => props.theme.type.small};
  font-weight: 600;
  letter-spacing: 1.5px;
  margin: ${({ theme }) => theme.padding * 3}px auto 0;
  padding: ${(props) => props.theme.padding}px
    ${(props) => props.theme.padding * 2}px;
  text-transform: uppercase;
  width: auto;

  ${media.large`
    margin: ${({ theme }) => theme.padding * 4}px auto 0;
  `};
`;

export const Text = styled.div`
  max-width: ${({ theme }) => theme.maxWidth.text};
  margin-left: auto;
  margin-right: auto;

  ${media.large`
    width: ${({ theme }) => theme.maxWidth.text};
  `};
`;

export const Html = styled.div`
  text-align: left;
  font-size: ${(props) => props.theme.type.text[0]};
  line-height: ${(props) => props.theme.lineHeight.small};
  color: ${(props) => props.theme.foreground2};
  max-width: ${({ theme }) => theme.maxWidth.text};
  margin: 0 auto;

  /* if not first child we need some top margin */
  :not(:first-child) {
    margin-top: ${(props) => props.theme.padding * 3}px;

    ${media.large`
      margin-top: ${(props) => props.theme.padding * 4}px;
    `};
  }

  p:last-child {
    margin-bottom: 0;
  }

  ${media.large`
    width: ${({ theme }) => theme.maxWidth.text};
    font-size: ${(props) => props.theme.type.text[1]};
  `};
`;

export const Quote = styled.div`
  position: relative;
  max-width: ${(props) => props.theme.maxWidth.text};
  width: 100%;
  margin: 0 auto ${(props) => props.theme.padding * 2}px auto;
  box-shadow: ${(props) => props.theme.shadow.heavy};
  background: ${(props) => props.theme.background};
  border-radius: 4px;
  text-align: right;
  padding: ${(props) => props.theme.padding * 2}px;
  font-size: 1.2em;

  &:last-child {
    margin: 0 auto;
  }

  blockquote:before {
    display: block;
    position: absolute;
    top: 24px;
    left: 10px;
    content: '"';
    font-size: 44px;
    color: ${(props) => props.theme.accentColour};
  }

  blockquote {
    padding: ${(props) => props.theme.padding * 1}px;
    padding-left: ${(props) => props.theme.padding * 3}px;
    padding-right: 0;
    text-align: left;
    font-size: ${(props) => props.theme.small};
    line-height: ${(props) => props.theme.small};
    font-style: italic;
  }

  cite {
    text-align: right;
    font-style: normal;

    span {
      font-weight: bold;
      display: block;
    }
  }
`;

export const AppStoreButtonSection = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.maxWidth.text};
  margin-top: ${(props) => props.theme.padding * 3}px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;

  /* if first child remove margin top */
  &:first-child {
    margin-top: 0;
  }

  ${media.medium`
    flex-direction: row;
  `}
`;

export const AppStoreButton = styled.div`
  max-width: 200px;
  min-width: 200px;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }

  ${media.medium`
  max-width: ${(props) => (!props.apple ? '200px' : '177px')};
  min-width: ${(props) => (!props.apple ? '200px' : '177px')};
    margin-right: ${(props) => props.theme.padding * 2}px;
  `}
`;
