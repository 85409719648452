import styled from 'styled-components';

export const ContentLockLabel = styled.span`
  display: flex;
  position: absolute;
  width: ${(props) => (props.small ? '36px' : '50px')};
  height: ${(props) => (props.small ? '36px' : '50px')};
  bottom: 0;
  right: 0;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 3px 3px 0;
  box-sizing: border-box;
  background: linear-gradient(
    -45deg,
    ${(props) => props.theme.accentColour} 0%,
    ${(props) => props.theme.accentColour} 50%,
    rgba(0, 0, 0, 0) 51%,
    rgba(0, 0, 0, 0) 100%
  );
`;
