import { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Menu, MenuButton, MenuList, MenuItem } from '@reach/menu-button';

import { Icon } from '../../common';
import { doLogout } from '../../user';
import * as N from './Navigation.styles';
import { User } from '../../common/types/User';
import { Channel } from '../../common/types/Channel';
import { Icons } from '../../common/components/Icon';
import { useConfig } from '../../withConfig';

type Props = {
  user: User;
  channel: Channel;
  canSubscribe: boolean;
};

function UserNavigation({ user, channel, canSubscribe }: Props) {
  const router = useRouter();
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // TODO remove this, obviously
  const { features } = useConfig(channel.cleanname);

  return (
    <N.UserNavigation>
      {user.isAuthed && isMounted && (
        <Menu>
          <MenuButton data-test={`tab--account`}>
            {/* <FormattedMessage id={`app.account.link`} /> */}
            <N.UserAccountButton>
              <Icon icon={Icons.account} />
              {/* <span aria-hidden>▾</span> */}
            </N.UserAccountButton>
          </MenuButton>
          <MenuList className="slide-down">
            <MenuItem onSelect={() => router.push('/settings/profile')}>
              <Link href="/settings/profile">
                <a>Profile settings</a>
              </Link>
            </MenuItem>
            <MenuItem onSelect={() => router.push('/settings/subscriptions')}>
              <Link href="/settings/subscriptions">
                <a>Manage Subscriptions</a>
              </Link>
            </MenuItem>
            <MenuItem onSelect={() => dispatch(doLogout())}>Log out</MenuItem>
          </MenuList>
        </Menu>
      )}

      {canSubscribe && !features.isUtility ? (
        <N.NavButton active={router.pathname === '/subscribe'}>
          <Link href="/subscribe">
            <a>Subscribe</a>
          </Link>
        </N.NavButton>
      ) : !user.isAuthed ? (
        <N.NavButton active={router.pathname === '/register'}>
          <Link href="/register">
            <a>
              <FormattedMessage id="app.user.register" />
            </a>
          </Link>
        </N.NavButton>
      ) : null}
    </N.UserNavigation>
  );
}

export default UserNavigation;
