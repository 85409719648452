import * as React from 'react';
import { compose } from 'redux';
import PlayerBar from './components/PlayerBar';
import { withPlayer } from '../withPlayer';
import AudioPlayerContext from './../AudioPlayerContext';
import { Track } from '../../common/types/Track';

type Player = {
  isPlaying: boolean;
  position: number;
  duration: number;
  isLoading: boolean;
  playbackError: string;
  queue: Array<Track>;
};

type Props = {
  player: Player;
  track: Track;
  url: string;
  doPlaybackPause: () => {};
  doPlaybackPlay: () => {};
  dispatch: Function;
};

type InjectedProps = {
  handlePlayClick: () => {};
  playNextTrack: () => {};
};
/**
 * Data container for sticky audio PlayerBar
 */
class PlayerBarContainer extends React.Component<Props & InjectedProps> {
  render() {
    return (
      <AudioPlayerContext.Consumer>
        {(value) => (
          <PlayerBar
            {...this.props}
            onPlayButtonClicked={this.props.handlePlayClick}
            onNextClicked={this.props.playNextTrack}
            position={value}
          />
        )}
      </AudioPlayerContext.Consumer>
    );
  }
}

export default compose(withPlayer)(PlayerBarContainer);
