import React from 'react';
import Release from './Release';
import { withSubscription } from '../../withSubscription';
import { ListWrapper } from '../../layout';

const CollectionList = ({
  collections,
  entity,
  isSubscribed,
  isExpanded,
  canAccessVideoCollectionById,
  canAccessAudioCollectionById,
  canAccessArticleCollectionById,
}) => {
  function chooseEntityAccess(entity) {
    switch (entity) {
      case 'collection':
        return canAccessVideoCollectionById;
      case 'articleCollection':
        return canAccessArticleCollectionById;
      case 'release':
        return canAccessAudioCollectionById;
      default:
        return canAccessAudioCollectionById;
    }
  }

  function chooseEntityRoute(collectionType: string, collection) {
    switch (collectionType) {
      case 'collection':
        return {
          to: '/collection/[id]',
          as: `/collection/${collection.albumId || collection.id}`,
        };
      case 'articleCollection':
        return {
          to: '/articles/[id]',
          as: `/articles/${collection.albumId || collection.id}`,
        };
      case 'release':
      default:
        return {
          to: '/audio/[id]',
          as: `/audio/${collection.albumId || collection.id}`,
        };
    }
  }

  return (
    <ListWrapper data-test="content-list">
      {collections &&
        collections.map((collection) => {
          let { to, as } = chooseEntityRoute(entity, collection);
          return (
            <Release
              key={collection.albumId || collection.id}
              as={as}
              to={to}
              release={collection}
              isLocked={
                !chooseEntityAccess(entity)(
                  collection.albumId || collection.id // legacy album/release objects
                )
              }
              isExpanded={isExpanded}
              entity={entity}
            />
          );
        })}
    </ListWrapper>
  );
};

// @ts-ignore
export default withSubscription(CollectionList);
