import { combineReducers } from 'redux';
import {
  RES_AUTH_FAIL,
  RES_AUTH_SUCCESS,
  REQ_AUTH,
  REQ_AUTH_LOGOUT,
  RES_USER_SUCCESS,
  SET_USER,
  SET_TOKEN,
  SET_COOKIE_CONSENT,
} from './user.constants';
import { API_ERROR } from '../api';

// reducers
export const isAuthed = (state = false, action) => {
  switch (action.type) {
    case RES_AUTH_SUCCESS:
      return true;
    case REQ_AUTH:
    case REQ_AUTH_LOGOUT:
    case RES_AUTH_FAIL:
      return false;
    case SET_USER:
      return action.payload?.token?.length > 0 ? true : false;
    case RES_USER_SUCCESS:
      return true;
    default:
      return state;
  }
};

export const isAuthenticating = (state = false, action) => {
  switch (action.type) {
    case REQ_AUTH:
      return true;
    case RES_AUTH_SUCCESS:
    case RES_AUTH_FAIL:
    case REQ_AUTH_LOGOUT:
      return false;
    case SET_USER:
      return action.payload.isAuthenticating;
    default:
      return state;
  }
};

export const profile = (state = {}, action) => {
  switch (action.type) {
    case RES_AUTH_SUCCESS:
      return Object.assign({}, action.payload.user);
    case RES_USER_SUCCESS:
      return { ...action.payload.user };
    case SET_USER:
      return action.payload.profile;
    case REQ_AUTH_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const authError = (state = null, action) => {
  switch (action.type) {
    case RES_AUTH_FAIL: {
      const { status } = action.payload; // @TODO find a way to access a strings file here

      let message = 'There was a problem logging in.';

      if (status === 401) {
        message = "That doesn't seem correct. Try again?";
      }

      return message;
    }
    case RES_AUTH_SUCCESS:
    case REQ_AUTH:
    case REQ_AUTH_LOGOUT:
      return null;
    case API_ERROR:
      if (action.payload.status === 401) {
        return null;
      } else {
        return state;
      }

    case SET_USER:
      return action.payload.authError;
    default:
      return state;
  }
};

export const token = (state = '', action) => {
  switch (action.type) {
    case SET_TOKEN:
      return action.payload;
    case REQ_AUTH_LOGOUT:
      return null;
    default:
      return state;
  }
};

export const consent = (state = { analytics: false, seen: false }, action) => {
  switch (action.type) {
    case SET_COOKIE_CONSENT:
      return { ...state, [action.payload.label]: action.payload.bool };
    default:
      return state;
  }
};

export const userAgreementsRequired = (state = false, action) => {
  switch (action.type) {
    case RES_AUTH_FAIL:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.userAgreementsRequired
      ) {
        return true;
      }

      return false;
    default:
      return state;
  }
};

export default combineReducers({
  isAuthed,
  isAuthenticating,
  profile,
  authError,
  token,
  consent,
  userAgreementsRequired,
});
