import { createGlobalStyle, keyframes } from 'styled-components';
import { darken, lighten } from 'polished';

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }  
`;

export const GlobalStyle = createGlobalStyle`

  *, *::after, *::before { box-sizing: border-box; }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  html,
  body,
  #root, #__next {
    min-height: 100vh;
    margin: 0;
  }

  /* #sp-audio {
    display: none;
  } */

  body {
    margin: 0;
    font-size: ${({ theme }) => theme.type.text[0]};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    position: relative;
    font-family: 'museo-sans', helvetica, sans-serif;
    line-height: 24px;
    color: ${({ theme }) => theme.foreground};
    background: ${({ theme }) => theme.background};

    --color-accent: ${({ theme }) => theme.accentColour};
    --color-accent-darker: ${({ theme }) =>
      darken(0.1, `${theme.accentColour}`)};
    --color-accent-lighter: ${({ theme }) =>
      lighten(0.2, `${theme.accentColour}`)};
    
  }

  #__next {
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  blockquote,
  figure,
  ol,
  ul {
    margin: 0;
    padding: 0;
  }
  main,
  li {
    display: block;
  }
  h1,
  h2,
  h3,
  h4 {
    font-size: inherit;
  }
  strong {
    font-weight: bold;
  }
  a,
  button {
    color: inherit;
    /* transition: .3s; */
  }
  a {
    text-decoration: none;
  }
  button {
    overflow: visible;
    border: 0;
    font: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    background: none;
    cursor: pointer;
  }
  ::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  :focus {
    outline: 0;
  }
  img {
    max-width: 100%;
    height: auto;
    border: 0;
  }
  p {
    margin-bottom: 20px;
  }
  .ProgressBar,
  .VolumeSlider {
    position: relative;
    background: #eee;
    border-radius: 4px;

    &-elapsed,
    &-value {
      background: #cccccc;
      border-radius: 4px;
    }

    &-seek {
      position: absolute;
      cursor: pointer;
    }

    &-handle {
      position: absolute;
      width: 16px;
      height: 16px;
      background: green;
      border-radius: 100%;
      transform: scale(0);
      transition: transform 0.2s;
    }

    &.isSeekable,
    &.isEnabled {
      &:hover {
        .ProgressBar-handle,
        .VolumeSlider-handle {
          transform: scale(1);
        }

        .ProgressBar-intent,
        .VolumeSlider-intent {
          position: absolute;
          background: rgba(0,0,0,0.05);
        }
      }

      .ProgressBar-elapsed,
      .VolumeSlider-value {
        background: $green;
      }
    }
  }

  .ProgressBar {
    height: 8px;
    margin: 10px 0;

    &-elapsed {
      height: 100%;
    }

    &-seek {
      top: -6px;
      right: 0;
      bottom: -6px;
      left: 0;
    }

    &-handle {
      top: 0;
      margin: -4px 0 0 -8px;
    }

    &.isSeekable {
      &:hover {
        .ProgressBar-handle {
          transform: scale(1);
        }
        .ProgressBar-intent {
          top: 0;
          left: 0;
          height: 100%;
        }
      }
    }
    &.isLoading {
      opacity: 0.5;
      background: #ccc;
      animation: barLoading 2s infinite;
    }
  }

  #nprogress .bar {
    background: ${(props) => props.theme.accentColour};
  }

  #nprogress .spinner-icon {
    border-top-color: ${(props) => props.theme.accentColour};
    border-left-color: ${(props) => props.theme.accentColour};
    display: none;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px ${(props) => props.theme.accentColour}, 0 0 5px ${(
  props
) => props.theme.accentColour};
  }

  /* REACH UI Global modifications */  
  [data-reach-menu] {
    z-index: 110;
  }

  [data-reach-menu-list] {
    border: none; 
    /* padding: 10px 10px; */
    background: ${(p) => p.theme.peak};
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);   
    font-family: inherit;
  }

  .slide-down[data-reach-menu-list] {
    border-radius: 5px;
    animation: ${slideDown} 0.2s ease;
  }

  [data-reach-menu-item] {
    font-size: 16px;
    /* padding-left: 8px; */
    /* padding-right: 8px;     */
  }

  [data-reach-menu-item][data-selected] {
    background: ${(p) => p.theme.accentColour};
    /* border-radius: 4px; */
  }

  [data-reach-menu-button] {
    margin-top: 5px;
  }
`;
