export default {
  theme: 'light',
  background: '#FFFFFF',
  background2: '#F5F7FA',
  backgroundActive: '#FAFAFA',
  backgroundCard: '#ffffff',
  base: '#ffffff',
  peak: '#EAECF2', // '#D8DCE6',
  hairline: 'rgba(0,0,0,.08)',
  foreground: '#313847',
  foreground2: '#616B80',
  foreground3: '#c1c1c1',
  hover: 'rgba(188,191,204,.3)',
  footerBackground: '#F5F7FA',
  footerForeground: '#777777',
  border: '#cccccc',
  borderActive: '#999999',
  textShadow: 'none',
  rowGradientStart: '#ffffff',
  rowGradientEnd: '#f2f2f7',
};
