import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const ListBlock = styled.div`
  position: relative;
  overflow: no-scroll;
  padding-top: ${(p) => p.theme.padding * 2}px;
  padding-bottom: ${(p) => p.theme.padding * 2}px;
  background: linear-gradient(
    ${(p) => p.theme.rowGradientStart},
    ${(p) => p.theme.rowGradientEnd}
  );

  ${media.medium`
    padding-left: 4%;
    padding-right: 4%;
    padding-top: ${(p) => p.theme.padding * 3}px;
    padding-bottom: ${(p) => p.theme.padding * 3}px;
  `};

  ${media.large`
    padding-top: ${(p) => p.theme.padding * 4}px;
    padding-bottom: ${(p) => p.theme.padding * 4}px;
  `};
`;

export const ListExpandButton = styled.span`
  font-weight: bold;
  font-size: 16px;

  ${media.medium`
    font-size: 18px;
  `};
`;
