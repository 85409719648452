import * as React from 'react';
import Head from 'next/head';
import { CookieConsent } from '../../user';
import { GlobalStyle } from '../themes/globalStyles';
import { Header2, channelsWithLogos } from '../../layout/components/Header2';
import Footer from '../../layout/components/Footer';
import { PlayerBarContainer } from '../../player';
import Tracking from '../../user/tracking/Tracking';
import { User } from '../../common/types/User';
import { Channel } from '../../common/types/Channel';

type Props = {
  activeTheme: string;
  children?: any;
  doToggleTheme: Function;
  doLogout: Function;
  doClearErrors?: Function;
  user: User;
  accentColour: string;
  useAccent?: boolean;
  hasTabs?: boolean;
  errors: Array<Object>;
  channel: Channel;
  isSubscribed: boolean;
  hideChrome: boolean;
};

const doAsyncWork = () => Promise.reject(new Error('Client Test 1'));

function Layout({
  activeTheme,
  children,
  doToggleTheme,
  doLogout,
  doClearErrors,
  user,
  accentColour,
  useAccent,
  hasTabs,
  errors,
  channel,
  isSubscribed,
  hideChrome = false,
}: Props) {
  if (!channel) {
    return <div>App cannot be found.</div>;
  }

  return (
    <>
      <Head>
        {channel &&
        channel.cleanname &&
        channelsWithLogos.includes(channel.cleanname) ? (
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`https://s3-eu-west-1.amazonaws.com/supapass-public/favicons/${channel.cleanname}/favicon.png`}
          />
        ) : null}

        {channel && channel.themeColor ? (
          <meta name="theme-color" content={channel.themeColor} />
        ) : null}

        {channel && channel.themeColor ? (
          <meta name="msapplication-TileColor" content={channel.themeColor} />
        ) : null}
      </Head>
      <GlobalStyle />
      {!hideChrome && (
        <Header2 isSubscribed={isSubscribed} user={user} channel={channel} />
      )}
      <div className={`${activeTheme}-mode`} style={{ flex: '1 0 auto' }}>
        {children}
        <button onClick={doAsyncWork}>make error</button>
      </div>

      {!hideChrome && <Footer isSubscribed={isSubscribed} channel={channel} />}
      {!hideChrome && <PlayerBarContainer accentColour={accentColour} />}
      {!hideChrome && <CookieConsent />}

      <Tracking channel={channel} />
    </>
  );
}

export default Layout;
