import React, { Component } from 'react';
import Helmet from 'next/head';
import Link from 'next/link';
import { LayoutContainer, PageBody } from '../../layout';
import styled from 'styled-components';

class Error404 extends Component {
  render() {
    return (
      <LayoutContainer>
        <Helmet>
          <title>Page not found</title>
          <meta name="description" content="Page not found" />
        </Helmet>
        <PageBody>
          <AlignCenter>
            <LargeHeader>Oops!</LargeHeader>
            <p>We can't seem to find that page ☹.</p>
            <Link href="/">
              <a>Go to the homepage.</a>
            </Link>
          </AlignCenter>
        </PageBody>
      </LayoutContainer>
    );
  }
}

export const AlignCenter = styled.div`
  text-align: center;
  margin-top: 50px;
`;

export const LargeHeader = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;
export default Error404;
