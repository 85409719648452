import { AppState } from '../common/types/AppState';

export const selectUser = (state: AppState) => state.user;
export const selectUserId = (state: AppState) => state.user.profile.uid;
export const selectIsAuthed = (state: AppState) => state.user.isAuthed;
export const selectIsAuthenticating = (state: AppState) =>
  state.user.isAuthenticating;
export const selectAuthError = (state: AppState) => state.user.authError;
export const selectToken = (state: AppState) => state.user.token;
export const selectConsent = (state: AppState) => state.user.consent;
export const selectUserAgreementsRequired = (state: AppState) =>
  state.user.userAgreementsRequired;
