import * as React from 'react';
import styled from 'styled-components';
import { media } from '../../../layout/themes/mediaQueries';
import SeekBar from './SeekBar';
import { Icon, FadeIn, Spinner, sizeCloudinaryImage } from '../../../common';
import { toHHMMSS } from '../../playerTools';
import { Track } from '../../../common/types/Track';
import { Icons } from '../../../common/components/Icon';
import PlayButton from './PlayButton';
import { useRelease } from 'src/app/user/user.hooks';

type Props = {
  track: Track;
  player: any;
  onPlayButtonClicked: () => {};
  onNextClicked: Function;
  accentColour?: string;
  position: {
    position: number;
    duration: number;
  };
};

export default ({
  player,
  onPlayButtonClicked,
  onNextClicked,
  accentColour,
  position, // from MediaContext not Redux
}: Props) => {
  const { track } = player;
  const collection = useRelease(track?.albumId);

  return track ? (
    <Comp
      background={track.imageUrl}
      active={track.trackId === undefined ? false : true}>
      <PlayerBarInner>
        <AlbumArt>
          <FadeIn>
            {(onLoad) => (
              <img
                src={sizeCloudinaryImage(
                  track?.imageUrl || collection?.imageUrl,
                  300
                )}
                alt={`${track.name} ${track.artist}`}
                onClick={onPlayButtonClicked}
                onLoad={onLoad}
              />
            )}
          </FadeIn>
        </AlbumArt>

        <PlayButton
          isPlaying={player.isPlaying}
          isLoading={player.isLoading}
          onPlayButtonClicked={onPlayButtonClicked}
        />

        <NextButton onClick={onNextClicked}>
          <Icon icon={Icons.skip} size={46} />
        </NextButton>

        <PlayerBarMiddle>
          {player.playbackError === '' && (
            <TrackInfo>
              <TrackName>{track.name}</TrackName>
              <TrackChannel>{track.artist}</TrackChannel>
            </TrackInfo>
          )}
          {player.playbackError !== '' && (
            <TrackInfo>{player.playbackError}</TrackInfo>
          )}
          <Progress>
            <Time align="right">{toHHMMSS(position.position)}</Time>
            <SeekBar
              duration={position.duration}
              position={position.position}
              accentColour={accentColour}
            />
            <Time>{toHHMMSS(position.duration)}</Time>
          </Progress>
        </PlayerBarMiddle>
      </PlayerBarInner>
    </Comp>
  ) : null;
};

const Comp = styled.div`
  display: block;
  position: fixed;
  bottom: ${(props) => (props.active ? '0px' : '-84px')};
  width: 100%;
  box-shadow: 0px -3px 6px ${(props) => props.theme.shadow.opacity};
  background: ${(props) => props.theme.base};
  border-top: 1px solid ${(props) => props.theme.peak};
  transition: bottom 0.5s;
  overflow: hidden;
  z-index: 1200;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    opacity: 0.15;
    background-image: url(${(props) => props.background});
    background-color: ${(props) => props.theme.base};
    background-position: center;
    background-size: cover;
    filter: blur(10px);
  }
`;

const PlayerBarInner = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${(p) => p.theme.maxWidth.page};
  display: flex;
  align-items: center;
  flex: 1;
  z-index: 200;
  height: 56px;

  ${media.medium`
    height: 84px;
    margin-left: 16px;
  `};

  ${media.large`
    margin-left: auto;
  `};
`;

const AlbumArt = styled.div`
  height: 56px;
  width: 56px;

  ${media.medium`
    border-radius: 2px;
  `}
`;

const NextButton = styled.div`
  cursor: pointer;
  /* margin-top: 7px; */
  margin-left: 7px;

  svg:hover {
    fill: ${(p) => p.theme.foreground};
    border-radius: 4px;
  }
`;

const PlayerBarMiddle = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const TrackInfo = styled.div`
  white-space: nowrap;
  min-width: 0;
  margin: 0 12px;
  overflow: hidden;

  ${media.medium`
    text-align: center;
  `};
`;

const TrackName = styled.span`
  display: block;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  font-size: 15px;
`;

const Progress = styled.div`
  display: none;
  margin: 0 16px 3px;
  ${media.medium`
    display: flex;
  `};
`;

const Time = styled.span`
  font-size: 12px;
  margin: 0 8px;
  width: 35px;
  text-align: ${(props) => (props.align ? props.align : 'left')};
`;

const TrackChannel = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  overflow: hidden;
  font-size: 15px;
`;
