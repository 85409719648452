//
// isLoaded: whether the jw script is loaded
// isJwConfigured: whether the player is configured with a call to jwplayer(element).setup();
// shouldPlay: whether we think we should be playing
// isPlaying: whether we are playing

import React, { Component, RefObject } from 'react';
import { createSourcesList, setupJwPlayer } from '../playerTools';
import { Script } from '../../common';
import { Offscreen } from '../../layout';
import { withPlayer } from '../withPlayer';
import { Track } from '../../common/types/Track';

import {
  didPlayerReady,
  didPlaybackPlay,
  didPlayerScriptLoad,
  didPlayerScriptError,
} from '../player.actions';
import { Dispatch } from 'redux';
import { StreamUrls } from '../player.types';

type Props = {
  playerId: string;
  file?: string;
  playlist?: Object;
  playerElement: HTMLElement;
  urls: StreamUrls;
  track: Track;
  isPlaying?: boolean;
  shouldPlay: boolean;
  dispatch: Dispatch;
  queue: Array<Track>;
  onTimeUpdate: Function;
  playNextTrack: Function;
  player: any;
};

type State = {
  isJwConfigured: boolean;
  isLoaded: boolean;
};

/**
 * jwPlayer base options object
 */
let opts = {
  width: '1px',
  height: '1px',
  stretching: 'fill',
  primary: 'html5',
  preload: 'none',
  logo: { hide: true, file: '', position: 'top-left', margin: '10' },
  autostart: true,
  repeat: false,
  image: '',
  rtmp: { bufferlength: 0.1 },
  controls: true,
  androidhls: 1,
  playlist: [],
};

/**
 * Manages jwPlayer, hooking up to event listeners,
 * managing component updates, rerenders and player actions.
 *
 * It renders only a hidden element with jw player in it.
 */
class HiddenAudioPlayerContainer extends Component<Props, State> {
  state = {
    isJwConfigured: false,
    isLoaded: false,
  };
  // dom reference for jacking in jw player
  playerRef: RefObject<any> = React.createRef();
  // jwplayer object
  jsPlayer: any | null | undefined = null;

  /**
   * Capture the player from the window
   */
  getPlayer = () => {
    if (!this.state.isLoaded) {
      // console.log('Player not loaded');
      return this.jsPlayer;
    }
    if (!this.jsPlayer) {
      this.jsPlayer = setupJwPlayer(this.playerRef.current);
    }
    return this.jsPlayer;
  };

  /**
   * Only update if particular props change to avoid
   * interrupting playback
   *
   * @param {Props} nextProps
   * @param {State} nextState
   */
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { player } = this.props;
    const nextPlayer = nextProps.player;

    // jw is not configured
    if (!this.state.isJwConfigured) {
      return true;
    }

    // if the url to the stream has changed
    if (nextPlayer.urls && nextPlayer.urls.dash !== player.urls.dash) {
      return true;
    }

    // if the play state was different
    if (nextPlayer.isPlaying !== player.isPlaying) {
      return true;
    }

    // if we changed our mind about playing
    if (nextPlayer.shouldPlay !== player.shouldPlay) {
      return true;
    }

    return false;
  }

  /**
   * If we're updating we're initialising or loading
   * a new streaming url. When loaded === true we can
   * configure jwPlayer if necessary.
   *
   * Here's a useful mp3 sample for testing streaming:
   * https://allthingsaudio.wikispaces.com/file/view/Shuffle%20for%20K.M.mp3/139190697/Shuffle%20for%20K.M.mp3
   * http://www.sample-videos.com/audio/mp3/wave.mp3
   * https://files-cijqeclsvc.now.sh/samplefile.mp3
   */
  componentDidUpdate(prevProps: Props) {
    const { shouldPlay, urls } = this.props.player;
    const { isLoaded, isJwConfigured } = this.state;

    // get the player/element
    const jsPlayer = this.getPlayer();
    if (!jsPlayer) {
      // console.log('Updated, but no player available');
      return;
    }

    // if jw isn't loaded, try next time
    if (!isLoaded) {
      // console.log('Waiting for jw player to load...');
      return;
    }

    // if we have jw, let's configure and add listeners
    // if (!isJwConfigured) {
    // } // this.configureJwPlayer();
    // if the url changes but not to nothing, load it

    if (urls.dash !== prevProps.player.urls.dash && urls.dash !== '') {
      // console.log(
      //   `Loading dash: ${this.props.player.urls.dash} /
      //         hls: ${this.props.player.urls.hls} into player...`
      // );

      this.configureJwPlayer();
    }

    // now let's see about playing
    if (isJwConfigured) {
      if (shouldPlay) {
        jsPlayer.play();
      } else {
        jsPlayer.pause();
      }
    }
  }

  configureJwPlayer = () => {
    const { dispatch } = this.props;
    const { urls } = this.props.player;
    const jsPlayer = this.getPlayer();
    const playlist = typeof urls !== 'undefined' ? createSourcesList(urls) : [];

    // console.log('Configuring player...');
    if (!jsPlayer) {
      // console.log('No player to configure!');
      return;
    }

    // include playlist in options object and setup
    // @ts-ignore
    opts['playlist'] = playlist;
    jsPlayer.setup(opts);
    // player.on('all', (event, data) => console.log(event, data));
    jsPlayer.on('ready', this.onJwPlayerReady);
    jsPlayer.on('play', (_) => dispatch(didPlaybackPlay()));
    jsPlayer.on('complete', (_) => this.props.playNextTrack());
    jsPlayer.on('time', (event) =>
      this.props.onTimeUpdate(event.position, event.duration)
    );
    jsPlayer.on('destroyPlugin', (_) => this.props.onTimeUpdate(0, 0));
    this.setState({ isJwConfigured: true });
  };

  onJwPlayerReady = () => {
    // console.log('Player ready.');
    const { urls, shouldPlay } = this.props.player;
    const { dispatch } = this.props;
    const jsPlayer = this.getPlayer();
    const playlist = typeof urls !== 'undefined' ? createSourcesList(urls) : [];
    if (!jsPlayer) {
      return new Error('JWPlayer not ready');
    }
    jsPlayer.load(playlist);
    if (shouldPlay) {
      jsPlayer.play();
    }
    dispatch(didPlayerReady());
  };

  handleScriptCreate() {
    this.setState({ isLoaded: false });
  }

  handleScriptError() {
    const { dispatch } = this.props;
    dispatch(didPlayerScriptError());
    this.setState({ isLoaded: false });
  }

  handleScriptLoad() {
    const { dispatch } = this.props;
    dispatch(didPlayerScriptLoad());
    this.setState({ isLoaded: true });
  }

  render() {
    const { isLoaded } = this.state;

    if (!isLoaded) {
      return (
        <Script
          url="/static/vendor/jwplayer.js"
          onCreate={this.handleScriptCreate.bind(this)}
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        />
      );
    }
    return (
      <Offscreen>
        <div ref={this.playerRef} />
      </Offscreen>
    );
  }
}

export default withPlayer(HiddenAudioPlayerContainer);
