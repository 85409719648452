import React from 'react';
import Link from 'next/link';
import * as S from './Footer.styles';
import { dangerousHTML, createAppleLink, createGoogleLink } from '../../common';
import { withPlayer } from '../../player/withPlayer';

const Footer = ({ channel, player: { track } }) => (
  <S.Footer hasPlayer={track ? true : false}>
    <S.FooterInner>
      <S.FooterLinks>
        <ul>
          <li>
            <Link href="/support">
              <a>Tech Support</a>
            </Link>
          </li>
          {(channel.faqExternalUrl || channel.faqInternalUrl) && (
            <li>
              <a
                href={channel.faqExternalUrl || '/faq'}
                rel="noopener noreferrer"
                target="_blank">
                Frequently Asked Questions
              </a>
            </li>
          )}
          {(channel.eulaExternalUrl || channel.eulaInternalUrl) && (
            <li>
              <a
                href={channel.eulaExternalUrl || '/eula'}
                rel="noopener noreferrer"
                target="_blank">
                {channel.name} Terms &amp; Privacy
              </a>
            </li>
          )}
          <li>
            <a
              href="https://eula.supapass.com"
              rel="noopener noreferrer"
              target="_blank">
              {channel.showPoweredBySupaPass ? 'SupaPass' : 'General'} Terms
              &amp; Privacy
            </a>
          </li>

          {channel.appleId ? (
            <li>
              <a href={createAppleLink(channel.appleId)}>iOS App</a>
            </li>
          ) : null}
          {channel.hasGoogleApp ? (
            <li>
              <a href={createGoogleLink(channel.cleanname)}>Android App</a>
            </li>
          ) : null}
        </ul>
        <ul>
          {channel.homepage.footerLinks.map((link) => (
            <li key={link.url + link.label}>
              <a
                href={link.url}
                dangerouslySetInnerHTML={dangerousHTML(link.label)}
              />
            </li>
          ))}
        </ul>
      </S.FooterLinks>
      <PoweredBy channel={channel} />
    </S.FooterInner>
  </S.Footer>
);

function PoweredBy({ channel }) {
  return (
    <>
      {channel.customPoweredByText && channel.customPoweredByUrl && (
        <S.PoweredBy>
          <a href={channel.customPoweredByUrl}>{channel.customPoweredByText}</a>
        </S.PoweredBy>
      )}
      {channel.showPoweredBySupaPass && (
        <S.PoweredBy>
          Powered by{' '}
          <a
            href="https://www.supapass.app"
            target="_blank"
            rel="noopener noreferrer">
            SUPAPASS
          </a>
        </S.PoweredBy>
      )}
    </>
  );
}

export default withPlayer(Footer);
