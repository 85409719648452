import { RES_CHANNEL_SUCCESS } from '../channel/channel.constants';
import { ToggleThemeAction, ConfigSuccessAction } from './layout.types';
import { AppState } from '../common/types/AppState';

// theme actions
export const TOGGLE_THEME = 'layout/TOGGLE_THEME';
export const SET_THEME = 'SET_THEME';

// theme constants
export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';

// theme action creator
export const doToggleTheme = () => {
  return { type: TOGGLE_THEME };
};

export const doSetTheme = (theme) => {
  return { type: SET_THEME, payload: theme };
};

// theme reducer
export const activeTheme = (
  state: string = THEME_LIGHT,
  action: ToggleThemeAction | ConfigSuccessAction | any
) => {
  switch (action.type) {
    case TOGGLE_THEME:
      return state === THEME_DARK ? THEME_LIGHT : THEME_DARK;
    case SET_THEME:
      return action.payload;
    case RES_CHANNEL_SUCCESS:
      return action.payload.defaultTheme
        ? action.payload.defaultTheme
        : THEME_LIGHT;
    default:
      return state;
  }
};

export const accentColour = (state: string = '', action: any) => {
  switch (action.type) {
    case RES_CHANNEL_SUCCESS:
      return action.payload.themeColor;
    default:
      return state;
  }
};

// selectors
export const selectActiveTheme = (state: AppState) => {
  return state.layout.activeTheme;
};

export const selectAccentColour = (state: AppState) =>
  state.layout.accentColour;
