// https://res.cloudinary.com/supapasscloud/image/upload/v1486120396/theravenage/photos/1_1.png
// c_fit,h_60,w_60
// https://res.cloudinary.com/supapasscloud/image/upload/c_fit,h_60,w_60/v1486120396/theravenage/photos/1_1.png

export const sizeCloudinaryImage = (
  url: string,
  width: number = 100,
  height: number | null | undefined = null
) => {
  // const regex = /.+?(?=upload\/)upload\/(.*?)\//;
  if (!url) {
    return '';
  }
  const needle = 'image/upload/';
  const pos = url.indexOf(needle) + needle.length;
  const sizing = width && height ? `w_${width},h_${height}` : `w_${width}`;
  const newUrl = `${url.substring(0, pos)}${sizing}/${url.substring(pos)}`;

  return newUrl;
};

export const sizeImageProxy = (
  url: string,
  width: number = 100,
  height: number | null | undefined = null
) => {
  const sizing = width && height ? `w_${width},h_${height}` : `w_${width}`;
  return `https://res.cloudinary.com/supapasscloud/image/fetch/${sizing}/${url}`;
};
