import { StreamingUrlTypes } from '../common/types/StreamingUrlTypes';

/**
 * Convert a raw number of seconds into a displayable
 * hh:mm:ss, while removing leading zeros. eg: 1:02:32, 2:32, 0:32
 * @param {number} secs  number of seconds
 */
export const toHHMMSS = (secs: number): string => {
  // @ts-ignore
  let seconds = parseInt(secs, 10);
  const hours = Math.floor(seconds / 3600) % 24;
  const minutes = Math.floor(seconds / 60) % 60;
  seconds = seconds % 60;
  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? '0' + v : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':')
    .replace(/^0/, '');
};

export const getUserAgent = () => {
  // we have browser window
  if (typeof window !== 'undefined') {
    return window.navigator.userAgent;
  }
  return 'NoUserAgent';
};

/**
 * UserAgent detection for checking HLS support
 * as used in drupal version mbaso_nodejs.js.
 */
export const shouldUseHLS = (userAgent: string) => {
  var mac = userAgent.indexOf('Mac');
  var iPhone = userAgent.indexOf('iPhone');
  var iPad = userAgent.indexOf('iPad');
  var android = userAgent.indexOf('Android');
  // var windows = userAgent.indexOf('Windows');
  // var linux = userAgent.indexOf('Linux');
  // var iPod = userAgent.indexOf('iPod');
  // var webOS = userAgent.indexOf('webOS');

  if (mac > 0 || iPad > 0 || iPhone > 0 || android > 0) {
    return true;
  }

  return false;
};

/**
 * Build an array of sources likely to be supported on this device
 *
 * @param {StreamingUrlTypes} links object of streaming protocol urls
 */
export const createSourcesList = (
  links: StreamingUrlTypes,
  image: string = ''
) => {
  let sources = [];
  // always use dash if possible
  sources.push({ file: links.dash, type: 'dash' });
  // if HLS is likely to work include that, in case dash is unsupported
  if (shouldUseHLS(getUserAgent()))
    sources.push({ file: links.hls, type: 'hls' });
  // let's no bother with flash for now
  // sources.push({ file: links.rtmp });
  return { sources, image };
};

export function setupJwPlayer(ref) {
  let player = null;
  if (typeof window !== 'undefined' && typeof window.jwplayer !== 'undefined') {
    player = window.jwplayer(ref);
    window.jwplayer.key = '168ZwZTm2jznxSSgiz9xczkhOpOTYiPg6GDOQx5xpaY=';
  }
  return player;
}

export const defaultJwConfig = {
  autostart: true,
  height: '100%',
  width: '100%',
  androidhls: true,
  controls: true,
  logo: { hide: true, file: '', position: 'top-left', margin: '10' },
  stretching: 'fill',
  primary: 'html5',
  aspectratio: '16:9',
  cast: {},
  floating: true,
};
