import { normalize, schema } from 'normalizr';

export const defaultEntityReducerState = {
  isLoading: false,
  lastReceived: 0,
  byId: {},
  allIds: [],
};

/**
 * createEntityReducer returns a NetworkCollection reducer
 * for handling network responses like:
 * {
 *   id: 1231
 *   {entityKey}: [{ id: 1, ...}, ...]
 * }
 *
 * Action constants array mirrors redux-api-middleware:
 * actions = [REQ, RES_SUCCESS, RES_FAIL] (list actions).
 * singleActions = same as above but for a single item
 *
 */
export default (
  entity: { key: string; id: string; merge?: boolean },
  actions: string[] = [],
  singleActions: string[] = []
) => (state = defaultEntityReducerState, action) => {
  switch (action.type) {
    case actions[0]:
      // req
      return { ...state, isLoading: true };
    case actions[2]:
      // fail
      return { ...state, isLoading: false, error: { ...action.payload } };
    case actions[1]: {
      // success
      const entities = normalizeObjectArray(entity, action.payload[entity.key]);
      return {
        ...state,
        byId: entity.merge
          ? { ...state.byId, ...entities.byId }
          : entities.byId,
        lastReceived: new Date().getTime(),
        allIds: entity.merge
          ? dedupeArrays(state.allIds, entities.allIds)
          : entities.allIds,
        isLoading: false,
      };
    }
    case singleActions[1]:
      return {
        ...state,
        byId: { ...state.byId, [action.payload[entity.id]]: action.payload },
      };
    default:
      return state;
  }
};

export const dedupeArrays = (one, two) => {
  const uniques = [];
  one.map((item) => (!~uniques.indexOf(item) ? uniques.push(item) : null));
  two.map((item) => (!~uniques.indexOf(item) ? uniques.push(item) : null));
  return uniques;
};

/**
 * Normalize an array of objects with Normalizr,
 * returning an object with byId keys and allIds array.
 */
export const normalizeObjectArray = (
  entity: { id: string; key: string },
  payload: Array<Object>
) => {
  const entitySchema = new schema.Entity(
    entity.key,
    {},
    { idAttribute: entity.id }
  );
  const listSchema = [entitySchema];
  const data = normalize(payload, listSchema);
  return {
    byId: data.entities[entity.key],
    allIds: data.result,
  };
};
