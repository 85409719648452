import * as React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import ReleaseArtwork from './ReleaseArtwork';
import { media } from '../../layout/themes/mediaQueries';
import { Release } from '../../common/types/Release';

type Props = {
  release: Release;
  to: string;
  isLocked: boolean;
  as: string;
  entity: string;
  isExpanded: boolean;
};

class ReleaseView extends React.Component<Props> {
  static defaultProps: Props;

  renderMeta() {}

  render() {
    const { release, to, as, isLocked, isExpanded, entity } = this.props;

    return (
      <Comp data-test="content-list-item" isExpanded={isExpanded}>
        <Link href={to} as={as} passHref={true}>
          <BlockLink>
            <ReleaseArtwork
              src={release.imageUrl}
              release={release}
              isLocked={isLocked}
            />
            {/*
            // @ts-ignore */}
            <Name>{release.name || release.title}</Name>
          </BlockLink>
        </Link>
        <Meta>{renderEntityMeta(entity, release)}</Meta>
      </Comp>
    );
  }
}

function renderEntityMeta(entity, release) {
  const itemCount = release.tracks
    ? release.tracks.length
    : release.items.length;
  const isPlural = itemCount === 0 || itemCount > 1 ? true : false;
  let metaString = '';
  switch (entity) {
    case 'collection':
      metaString = `${itemCount} video${isPlural ? 's' : ''}`;
      break;
    case 'articleCollection':
      metaString = `${itemCount} article${isPlural ? 's' : ''}`;
      break;
    case 'release':
    default:
      metaString = `${
        release.year ? release.year + ' • ' : ''
      }${itemCount} track${isPlural ? 's' : ''}`;
      break;
  }

  return metaString;
}

const Comp = styled.li`
  /* overflow: hidden; */
  box-sizing: border-box;
  text-overflow: ellipsis;
  flex: 0 1 50%;
  padding: ${(p) => p.theme.padding}px;
  width: 50%;

  ${media.medium`
    flex: 0 1 33.3%;
    width: 33.3%;
    display: block; 
    
    /* &:nth-of-type(1n + 3) {
      opacity:  1 
      height: auto;
    }

    &:nth-of-type(1n + 4) {
      opacity: ${(p) => (p.isExpanded ? 1 : 0)};
      height: ${(p) => (p.isExpanded ? 'auto' : '0px')};
    } */
  `};

  ${media.large`
    flex: 0 1 25%;
    width: 25%;
    /* &:nth-of-type(1n + 4) {
      opacity:  1 
      height: auto;
    }
    &:nth-of-type(1n + 5) {
      opacity: ${(p) => (p.isExpanded ? 1 : 0)};
      height: ${(p) => (p.isExpanded ? 'auto' : '0px')};
    } */
  `};

  ${media.xlarge`
    flex: 0 1 20%;
    width: 20%;
    /* &:nth-of-type(1n + 5) {
      opacity:  1 
      height: auto;
    }
    &:nth-of-type(1n + 6) {
      opacity: ${(p) => (p.isExpanded ? 1 : 0)};
      height: ${(p) => (p.isExpanded ? 'auto' : '0px')};
    } */
  `};
`;

const Name = styled.div`
  font-weight: 500;
  margin-top: ${(p) => p.theme.padding}px;
  line-height: 1.4em;
  font-size: 14px;

  ${media.medium`
    font-size: 16px;
  `};

  ${media.large`
    font-size: 16px;
  `};

  ${media.xlarge`
    font-size: 18px;
  `};
`;

const Meta = styled.span`
  display: block;
  color: ${(props) => props.theme.foreground2};
  font-size: 13px;
  margin-top: -3px;
`;

const BlockLink = styled.a`
  display: block;
`;

export default ReleaseView;
