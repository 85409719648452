import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  selectCurrentlyPlaying,
  selectCurrentlyPlayingUrl,
  selectIsPlaying,
  selectIsLoading,
  selectShouldPlay,
  selectQueue,
  selectCurrentTrackDuration,
  selectPlayerError,
} from './player.selectors';
import {
  doPlayTrack,
  doPlaybackPause,
  doPlaybackPlay,
  doPlaybackStop,
  doFetchStreamAudio,
} from './player.actions';
import { selectActiveChannel } from '../channel/channel.selectors';

export const withPlayer = (WrappedComponent) => {
  class PlayerWrapper extends Component {
    handlePlayClick = () => {
      const { player, doPlaybackPause, doPlaybackPlay } = this.props;
      if (player.isPlaying) {
        doPlaybackPause();
      } else {
        doPlaybackPlay();
      }
    };

    playNextTrack = () => {
      const { queue } = this.props.player;
      const { doPlaybackStop } = this.props;

      // if the queue is empty
      if (!queue.length) {
        doPlaybackStop();
        return;
      }

      const nextTrack = this.getNextTrack();

      if (!nextTrack) {
        doPlaybackStop();
        return;
      }

      this.playTrack(nextTrack, queue);
    };

    getNextTrack = () => {
      const { player } = this.props;
      return player.queue[0];
    };

    playTrack = (track, queue) => {
      const { doPlayTrack, doFetchStreamAudio } = this.props;
      doPlayTrack(track, queue);
      doFetchStreamAudio(track?.trackId, this.props.player?.channel?.artistId);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          playTrack={this.playTrack}
          handlePlayClick={this.handlePlayClick}
          playNextTrack={this.playNextTrack}
        />
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      player: {
        track: selectCurrentlyPlaying(state),
        urls: selectCurrentlyPlayingUrl(state),
        isPlaying: selectIsPlaying(state),
        isLoading: selectIsLoading(state),
        shouldPlay: selectShouldPlay(state),
        queue: selectQueue(state),
        duration: selectCurrentTrackDuration(state),
        playbackError: selectPlayerError(state),
        channel: selectActiveChannel(state),
      },
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    doPlayTrack: (nextTrack, queue) => dispatch(doPlayTrack(nextTrack, queue)),
    doFetchStreamAudio: (trackId, channelId) =>
      dispatch(doFetchStreamAudio(trackId, channelId)),
    doPlaybackPause: () => dispatch(doPlaybackPause()),
    doPlaybackPlay: () => dispatch(doPlaybackPlay()),
    doPlaybackStop: () => dispatch(doPlaybackStop()),
    dispatch: dispatch,
  });

  return connect(mapStateToProps, mapDispatchToProps)(PlayerWrapper);
};
