import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const CollectionImage = styled.div`
  padding: 0px;
  box-sizing: border-box;
  max-width: 128px;
  margin-right: 24px;
  width: 100%;
  overflow: hidden;

  img {
    margin-right: 48px;
    border-radius: 3px;
  }

  ${media.medium`
    max-width: 264px;
    margin-right: 48px;
  `};
`;
