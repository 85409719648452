import React from 'react';
import { doFetchConfig } from './channel';

export default function (Child) {
  return class Higher extends React.Component {
    static async getInitialProps(ctx) {
      const { dispatch } = ctx.ctx.store;

      await dispatch(doFetchConfig(ctx.ctx.channelName));

      return Child.getInitialProps(ctx);
    }
    render() {
      return <Child {...this.props} />;
    }
  };
}

const utilitySites = ['woodyshaw', 'tvfit2', 'coolevents'];

export function useConfig(channelUniqueName: string) {
  return {
    features: {
      isUtility: utilitySites.includes(channelUniqueName),
    },
  };
}
