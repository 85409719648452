import styled, { keyframes } from 'styled-components';
import { Icon, Spinner } from 'src/app/common';
import { Icons } from 'src/app/common/components/Icon';

const renderPlayButtonIcon = (
  isPlaying = false,
  isLoading = false,
  onPlayButtonClicked: Function,
  size = 46
) => {
  // if (isLoading) {
  //   return <Spinner />;
  // }
  if (isPlaying || isLoading) {
    return <Icon icon={Icons.pause} size={size} />;
  } else {
    return <Icon icon={Icons.play} size={size} />;
  }
};

type Props = {
  onPlayButtonClicked: () => void;
  isPlaying: boolean;
  isLoading: boolean;
  isLight?: boolean;
  size?: number;
};

export default function PlayButton({
  onPlayButtonClicked,
  isPlaying,
  isLoading,
  isLight = false,
  size = 46,
}: Props) {
  return (
    <PlayPauseButton
      onClick={onPlayButtonClicked}
      pulse={isLoading}
      isLight={isLight}>
      {renderPlayButtonIcon(isPlaying, isLoading, onPlayButtonClicked, size)}
    </PlayPauseButton>
  );
}

const pulse = keyframes`
  0% {
    opacity: .5;
  }


  50% {
    opacity: 1
  }

  100% {
    opacity: .5;
  }
`;

const PlayPauseButton = styled.div`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  /* margin-left: 12px; */
  /* margin-top: 7px; */
  display: inline-block;
  animation: ${(p) => (p.pulse ? pulse : null)} 1s linear infinite;

  svg {
    fill: ${(p) => (p.isLight ? '#fff' : p.themeforeground2)};
  }

  svg:hover {
    fill: ${(p) => p.theme.foreground};
    border-radius: 4px;
  }
`;
