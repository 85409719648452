import React, { Component } from 'react';
import { sizeCloudinaryImage } from '../../common';

type ApiImage = {
  url: string;
  height: number;
  width: number;
};

type Props = {
  images: Array<ApiImage>;
};

class ContentImages extends Component<Props> {
  render() {
    const { images } = this.props;
    return images.map((image) => (
      <img key={image.url} src={sizeCloudinaryImage(image.url, 800)} alt="" />
    ));
  }
}

export default ContentImages;
