import { AppState } from '../common/types/AppState';

export const selectBundleById = (state: AppState, id) =>
  state.entities &&
  state.entities.bundles &&
  state.entities.bundles.byId &&
  state.entities.bundles.byId[id];

export const selectBundles = (state: AppState) =>
  state.entities.bundles.allIds.map((id) => selectBundleById(state, id));

export const selectPaidBundles = (state: AppState) =>
  state.entities.bundles.allIds
    .map((id) => selectBundleById(state, id))
    .filter((bundle) => bundle.accessTypeCode === 'PAID');

export const selectAnonymousBundles = (state: AppState) =>
  state.entities.bundles.allIds
    .map((id) => selectBundleById(state, id))
    .filter((bundle) => bundle.accessTypeCode === 'ANONYMOUS');

export const selectBundlePlanById = (state: AppState, id) =>
  state.entities &&
  state.entities.bundlePlans &&
  state.entities.bundlePlans.byId &&
  state.entities.bundlePlans.byId[id];

export const selectBundlePlans = (state: AppState) =>
  state.entities.bundlePlans.allIds.map((id) =>
    selectBundlePlanById(state, id)
  );
