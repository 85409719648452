import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  selectActiveChannelId,
  selectChannelById,
} from './channel/channel.selectors';
import {
  selectBundles,
  selectBundlePlans,
} from './subscribe/subscribe.selectors';
import { selectUser } from './user/user.selectors';
import {
  selectArticleCollectionById,
  selectArticleById,
} from './articles/articles.selectors';
import {
  isUserSubscribedToAnyPaidBundle,
  isUserSubscribedToContent,
  isContentOnAnonymousBundle,
  isContentOnRegisteredBundle,
} from './user/user.helpers';

// direct import because importing /videos/index.js includes a component
// which requires this file, resulting in a weird circular dependency
// that cannot be resolved...
import {
  selectCollectionById,
  selectVideoById,
} from './videos/VideosCollection.redux';
import { selectReleaseById } from './releases/releases.selectors';
import { AppState } from './common/types/AppState';
import { Channel } from './common/types/Channel';
import { User } from './common/types/User';

const LOG = false;

function log(...args) {
  if (LOG) console.log(...args);
}

interface InjectedProps {
  channel: Channel;
  user: User;
}

export const withSubscription = <BaseProps extends InjectedProps>(
  WrappedComponent: React.ComponentType<BaseProps>
) => {
  class SubscriptionWrapper extends Component {
    static getInitialProps(ctx) {
      // @ts-ignore
      if (WrappedComponent.getInitialProps) {
        // @ts-ignore
        return WrappedComponent.getInitialProps(ctx);
      }
      return {};
    }
    render() {
      // @ts-ignore
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state: AppState, ownProps: Object) => {
    const activeChannelCleanName = selectActiveChannelId(state);
    const channel = selectChannelById(state, activeChannelCleanName);
    const user = selectUser(state);
    const bundles = selectBundles(state);
    const bundlePlans = selectBundlePlans(state);

    function canAccess(content) {
      log(
        'access check content item :: ',
        content.name || content.title,
        content.bundleUniqueNames
      );
      log('user has: ', user.profile.bundleSubscriptions);
      log('bundles: ');
      bundles.forEach((bundle) =>
        log('--', bundle.uniqueName, bundle.accessTypeCode)
      );

      if (isContentOnAnonymousBundle(bundles, content)) {
        log('allowing content because on free bundle');
        return true;
      }

      if (isContentOnRegisteredBundle(bundles, content) && user.isAuthed) {
        log(
          'allowing because content on registered bundle and user is logged in'
        );
        return true;
      }

      // log('check if user is suscribed to the content');
      return isUserSubscribedToContent(channel, user, content);
    }

    function getRedirectRoute(content) {
      // registration is required, but we're logged out?
      if (isContentOnRegisteredBundle(bundles, content) && !user.isAuthed) {
        log('content is on registered bundle, redirect /register');
        return '/register';
      }
      log(
        'user has no access, content not on registered bundle, redirect /subscribe'
      );
      return '/subscribe';
    }

    function canAccessVideoCollectionById(collectionId) {
      const { items: itemIds } = selectCollectionById(state, collectionId);
      const access = itemIds
        .map((itemId) => selectVideoById(state, itemId))
        .map((video) => canAccess(video));
      return access.includes(true);
    }

    function canAccessAudioCollectionById(releaseId) {
      const { tracks } = selectReleaseById(state, releaseId);
      const access = tracks.map((track) => canAccess(track));
      return access.includes(true);
    }

    function canAccessArticleCollectionById(articleCollectionId) {
      const { items: itemIds } = selectArticleCollectionById(
        state,
        articleCollectionId
      );
      const access = itemIds
        .map((itemId) => selectArticleById(state, itemId))
        .map((video) => canAccess(video));
      return access.includes(true);
    }

    return {
      isSubscribed: isUserSubscribedToAnyPaidBundle(user, bundles),
      channel,
      user,
      bundles,
      bundlePlans,
      canAccess,
      getRedirectRoute,
      canAccessVideoCollectionById,
      canAccessAudioCollectionById,
      canAccessArticleCollectionById,
    };
  };

  return connect(mapStateToProps)(SubscriptionWrapper);
};
