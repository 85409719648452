import { RSAA } from 'redux-api-middleware';

import {
  REQ_CHANNEL,
  RES_CHANNEL_FAIL,
  RES_CHANNEL_SUCCESS,
  REQ_RELEASES,
  RES_RELEASES_FAIL,
  RES_RELEASES_SUCCESS,
  REQ_VIDEOS,
  RES_VIDEOS_FAIL,
  RES_VIDEOS_SUCCESS,
  REQ_VIDEO,
  RES_VIDEO,
  RES_VIDEO_FAIL,
  REQ_FEED,
  RES_FEED_FAIL,
  RES_FEED_SUCCESS,
  REQ_VIDEO_LINK,
  RES_VIDEO_LINK_FAIL,
  RES_VIDEO_LINK_SUCCESS,
  SET_ACTIVE_CHANNEL,
  REQ_RELEASE,
  RES_RELEASE,
  RES_RELEASE_FAIL,
  REQ_CONFIG,
  RES_CONFIG_SUCCESS,
  RES_CONFIG_FAIL,
  SET_ACTIVE_COLLECTION,
} from './channel.constants';

export const doFetchChannel = (channelId) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/artist/${channelId}`,
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_CHANNEL, RES_CHANNEL_SUCCESS, RES_CHANNEL_FAIL],
    },
  };
};

export const doFetchChannelByUniqueName = (uniqueName) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/artist/${uniqueName}`,
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_CHANNEL, RES_CHANNEL_SUCCESS, RES_CHANNEL_FAIL],
    },
  };
};

export const doFetchChannelReleases = (uniqueName) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/artist/${uniqueName}/albums`,
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_RELEASES, RES_RELEASES_SUCCESS, RES_RELEASES_FAIL],
    },
  };
};

export const doFetchReleaseById = (uniqueName, albumId) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/artist/${uniqueName}/album/${albumId}`,
      types: [REQ_RELEASE, RES_RELEASE, RES_RELEASE_FAIL],
    },
  };
};

export const doFetchChannelVideos = (uniqueName) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/artist/${uniqueName}/videos`,
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_VIDEOS, RES_VIDEOS_SUCCESS, RES_VIDEOS_FAIL],
    },
  };
};

export const doFetchVideoById = (uniqueName, videoId) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/channel/${uniqueName}/video/${videoId}`,
      types: [REQ_VIDEO, RES_VIDEO, RES_VIDEO_FAIL],
    },
  };
};

export const doFetchChannelFeed = (uniqueName) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/artist/${uniqueName}/vip?limit=100`,
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_FEED, RES_FEED_SUCCESS, RES_FEED_FAIL],
    },
  };
};

export const doFetchVideoStreamingLink = (
  videoId: number,
  channelId: number
) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/user/streaming/video/${videoId}?videoType=all&channelIdentifier=${channelId}`,
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_VIDEO_LINK, RES_VIDEO_LINK_SUCCESS, RES_VIDEO_LINK_FAIL],
    },
  };
};

export const doSetActiveChannel = (channelId: number) => {
  return {
    type: SET_ACTIVE_CHANNEL,
    channelId,
  };
};

export const doSetActiveCollection = (collectionId: string) => {
  return {
    type: SET_ACTIVE_COLLECTION,
    payload: parseInt(collectionId, 10),
  };
};

export const doFetchConfig = (uniqueName) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/artist/${uniqueName}/config`,
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_CONFIG, RES_CONFIG_SUCCESS, RES_CONFIG_FAIL],
    },
  };
};
