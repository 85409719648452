import { ModalAction } from './layout.types';
import { MODAL_OPEN, MODAL_CLOSE } from './layout.constants';

export const activeModal = (
  state: string = '',
  action: ModalAction
): string => {
  switch (action.type) {
    case MODAL_OPEN:
      return action.payload.modalType;
    default:
      return state;
  }
};

export const isModalOpen = (
  state: boolean = false,
  action: ModalAction
): boolean => {
  switch (action.type) {
    case MODAL_OPEN:
      return true;
    case MODAL_CLOSE:
      return false;
    default:
      return state;
  }
};
