import { AppState } from '../common/types/AppState';

export const selectActiveChannelId = (state: AppState) =>
  state.currentChannel.channelId;

export const selectChannelById = (state: AppState, channelId: number) =>
  state.entities.channels[channelId];

export const selectChannelByUniqueName = (
  state: AppState,
  uniqueName: string
) => state.entities.channels[selectActiveChannelId(state)];

export const selectCurrentRelease = (state: AppState) =>
  state.currentChannel.currentRelease;

export const selectCurrentReleaseChannel = (state: AppState) =>
  state.currentChannel.currentReleaseChannel;

export const selectCurrentVideo = (state: AppState) =>
  state.currentChannel.currentVideo;

export const selectActiveChannel = (state: AppState) => {
  return selectChannelById(state, selectActiveChannelId(state));
};

export const selectActiveCollectionId = (state: AppState) => {
  return state.currentChannel.currentCollection;
};
