import React, { useEffect, useState } from 'react';
import Router from 'next/router';
import { Script } from '../../common';
import * as gtagHelpers from './gtagHelpers';

export default function GoogleAnalytics({ googleId }) {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    Router.events.on('routeChangeComplete', gtagHelpers.pageview);
    return () => {
      Router.events.off('routeChangeComplete', gtagHelpers.pageview);
    };
  }, [googleId]);

  function handleScriptCreate() {
    setIsScriptLoaded(false);
  }

  function handleScriptLoad() {
    setIsScriptLoaded(true);
    gtagHelpers.init(googleId);
  }

  function handleScriptError() {
    setIsScriptLoaded(false);
  }

  return (
    <>
      {!isScriptLoaded ? (
        <Script
          url={`https://www.googletagmanager.com/gtag/js?id=${googleId}`}
          onCreate={handleScriptCreate}
          onError={handleScriptError}
          onLoad={handleScriptLoad}
        />
      ) : null}
    </>
  );
}
