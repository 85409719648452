import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { media } from '../../themes/mediaQueries';
import { luminance } from '../../../common/tools/luminanceHelper';

export const MastheadStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 56px;
  z-index: 1000;
  box-sizing: border-box;
  background-color: ${(props) => transparentize(0.7, props.theme.background)};
`;

export const MastheadInner = styled.div`
  margin: 0 auto;
  max-width: 1080px;
  overflow: auto;
  position: relative;
  z-index: 50;
`;

export const Burger = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.medium`
    display: none;
  `};
`;

export const LogoStyled = styled.div`
  margin: 0 auto;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.medium`
    display: none;
  `};
`;

export const WordmarkStyled = styled.div`
  display: none;

  ${media.medium`
    float: left;
    align-items: center;
    height: 56px;
    display: flex;
    margin-left: 12px;
  `};

  ${media.large`
    margin: 0;
  `};
`;

export const Page = styled.div`
  position: relative;
  font-family: 'museo-sans', helvetica, sans-serif;
  line-height: 24px;
  overflow: auto;
  min-height: 100vh;
  padding-bottom: 84px; // always scroll the height of the player
  box-sizing: border-box;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.foreground};

  ${media.medium`
    padding-bottom: 120px;
  `};
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  height: 56px;
  width: 100%;
  z-index: 1000;
`;

export const NavPrimary = styled.nav`
  overflow: auto;

  float: right;
  display: flex;
  align-content: space-around;
  flex-direction: row;
  justify-content: flex-end;
`;

export const LoginButton = styled.a`
  margin-top: 10px;
  margin-right: 8px;
`;

export const SignupButton = styled.a`
  margin-top: 10px;
`;

export const StyledLink = styled.a`
  display: block;
  overflow: hidden;
  margin: 10px;
  font-size: 12px;
  color: ${(props) =>
    props.useAccent ? props.theme.white : props.theme.foreground} !important;

  ${media.medium`

  `};

  &:hover {
    opacity: 1;
  }

  &.active {
    font-weight: bold;
  }
`;

export const OverflowLink = styled.a`
  display: none;
  margin: 10px;
  font-size: 12px;
  color: ${(props) =>
    props.useAccent ? props.theme.white : props.theme.foreground};

  ${media.medium`
    display: inline-block;
   `};
`;

export const FullscreenBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  min-height: 100%;
  opacity: 0.5;
  filter: blur(15px);
`;

export const BackgroundFade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(
    0deg,
    ${(props) => props.theme.background},
    rgba(0, 0, 0, 0)
  );
`;

const ContentPadding = css`
  padding-left: ${(p) => p.theme.padding * 2}px;
  padding-right: ${(p) => p.theme.padding * 2}px;
  ${media.large`
    padding-left: 0;
    padding-right: 0;
  `};
`;

export const ContentDate = styled.span`
  display: block;
  font-size: 14px;
  color: ${(props) => props.theme.foreground2};
  margin-bottom: ${(p) => p.theme.padding * 3}px;

  ${ContentPadding};
`;

export const ContentTitle = styled.h1`
  font-size: 1.75em;
  font-weight: bold;
  line-height: 1.4em;
  margin-top: ${(p) => p.theme.padding * 2}px;
  margin-bottom: ${(p) => p.theme.padding * 2}px;
  margin-left: auto;
  margin-right: auto;
  max-width: ${(p) => p.theme.maxWidth.page};
  ${ContentPadding};

  ${media.large`
    font-size: 2.75em;
  `};
`;

export const ContentSubtitle = styled.span`
  font-size: 1.5em;
  display: block;
  margin-bottom: ${(p) => p.theme.padding * 2}px;
  ${ContentPadding};
`;

export const ContentPerformers = styled.span`
  display: block;
  color: ${(p) => p.theme.foreground2};
  margin-bottom: ${(p) => p.theme.padding * 2}px;
  ${ContentPadding};
`;

export const ContentBody = styled.div`
  margin: 24px auto 56px auto;
  max-width: ${(p) => p.theme.maxWidth.text};
  ${ContentPadding};
  font-size: 1.1em;
  line-height: 1.3em;

  a {
    text-decoration: underline;
    color: ${(props) =>
      luminance(props.theme.background, props.theme.accentColour)};
  }

  /* ul {
    display: block;
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 24px;
    margin-bottom: ${(p) => p.theme.padding * 2}px;

    li {
      display: list-item;
    }
  } */

  ${media.medium`
  font-size: 1.3em;
  line-height: 1.5em;

  `}
`;

export const YouTubeContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 12px;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
