import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Link from 'next/link';
import { Button } from '../../layout/components/Button';
import { Channel } from '../../common/types/Channel';

const PAYMENT_URL = process.env.SP_PAYMENT_BASE_URL;

type Props = {
  channel: Channel;
  flatTop?: boolean;
  label?: string;
  period?: string;
  customLink?: string;
  narrow?: boolean; // a link to over ride the regular subscription link
  inline?: boolean;
  padding?: boolean;
};

export default (props: Props) => {
  // link to the subscribe page on the old website
  const subscribeLink = `${PAYMENT_URL}/${
    props.channel.cleanname
  }/subscribe?redirect_to_app=1&period=${props.period || 'vip'}`;

  // regisration page
  const registerLink = '/register';

  let link = props.channel.isSubscribable ? subscribeLink : registerLink;

  // eslint-disable-next-line no-extra-boolean-cast
  if (!!props.customLink) {
    link = props.customLink;
  }

  // external links render a plain anchor
  if (
    props.customLink &&
    (props.customLink.includes('http://') ||
      props.customLink.includes('https://'))
  ) {
    return (
      <ButtonStyled
        href={link}
        {...props}
        accent={props.channel.themeColor}
        flatTop={props.flatTop}>
        {!props.label ? (
          <FormattedMessage id="app.channel.button.subscribe" />
        ) : (
          props.label
        )}
      </ButtonStyled>
    );
  }

  // internal/relative links route with next/Link
  return (
    <Link href={link} passHref={true}>
      <ButtonStyled
        {...props}
        accent={props.channel.themeColor}
        flatTop={props.flatTop}>
        {!props.label ? (
          <FormattedMessage id="app.channel.button.subscribe" />
        ) : (
          props.label
        )}
      </ButtonStyled>
    </Link>
  );
};

const ButtonStyled = styled(Button)``;
