/**
 * Player related events can originate from two sources: the user and the player itself.
 *
 * User events:
 * User drives, firing events to update player state, player reacts to state updates
 *
 * - play track (clicked track): DO_PLAYBACK_TRACK
 * - pause: DO_PLAYBACK_PAUSE
 * - resume (currently loaded track): DO_PLAYBACK_RESUME
 * - seek: DO_SEEK
 *
 * Player events:
 * While the user drives, player is the authority on what is actually going on
 * so these events should take precedence when confirming state of play
 *
 * - track is playing: DID_PLAYBACK_PLAY
 * - track is paused: DID_PLAYBACK_PAUSE
 * - current track is complete: DID_PLAYBACK_COMPLETE
 * - error: DID_PLAYBACK_ERROR
 * - seek: DID_SEEK
 */

import { combineReducers } from 'redux';
import {
  RES_STREAM_AUDIO_SUCCESS,
  RES_STREAM_AUDIO_FAILURE,
  DO_PLAYBACK_TRACK,
  DO_PLAYBACK_PLAY,
  DO_PLAYBACK_PAUSE,
  DO_PLAYBACK_STOP,
  DID_PLAYER_READY,
  DID_PLAYBACK_PLAY,
  DID_PLAYBACK_PAUSE,
  DID_PLAYBACK_SEEK,
  DID_PLAYBACK_COMPLETE,
  DID_PLAYBACK_ERROR,
  DID_PLAYER_SCRIPT_ERROR,
  DID_PLAYER_SCRIPT_LOAD,
} from './player.constants';
import { RES_VIDEO_LINK_SUCCESS } from '../channel/channel.constants';
import { REQ_AUTH_LOGOUT } from '../user/user.constants';
import { Track } from '../common/types/Track';

import { StreamUrls, PlayerAction } from './player.types';
import { ChannelAction } from '../channel/channel.types';

/**
 * The url of the currently playing track
 *
 * @param {string} state
 * @param {Action} action
 */
const initialCurrentlyPlayUrl: StreamUrls = {
  dash: '',
  hls: '',
  rtmp: '',
};
export const currentlyPlayingUrl = (
  state: StreamUrls = initialCurrentlyPlayUrl,
  action: ChannelAction | PlayerAction
): StreamUrls | null | undefined => {
  switch (action.type) {
    case RES_STREAM_AUDIO_SUCCESS:
      //@ts-ignore
      return action.payload;
    case DO_PLAYBACK_TRACK:
      return initialCurrentlyPlayUrl;
    default:
      return state;
  }
};

/**
 * Should the player be playing?
 *
 * @param {boolean} state should the player be playing?
 * @param {Action} action
 */
export const shouldPlay = (
  state: boolean = false,
  action: PlayerAction | ChannelAction
): boolean => {
  switch (action.type) {
    case DO_PLAYBACK_TRACK:
    case DO_PLAYBACK_PLAY:
    case RES_STREAM_AUDIO_SUCCESS:
    // TODO clean this up if not removed during player rebuild
    //@ts-ignore eslint-disable-line no-fallthrough
    case RES_VIDEO_LINK_SUCCESS:
      return true;
    case DO_PLAYBACK_PAUSE:
    case DO_PLAYBACK_STOP:
    // @ts-ignore eslint-disable-line no-fallthrough
    case REQ_AUTH_LOGOUT:
      return false;
    default:
      return state;
  }
};

/**
 * Is the player loading?
 *
 * @param {boolean} state is the player loading?
 * @param {Action} action
 */
const isLoading = (state: boolean = false, action: PlayerAction): boolean => {
  switch (action.type) {
    case DO_PLAYBACK_TRACK:
      return true;
    case DID_PLAYBACK_PLAY:
    case DID_PLAYBACK_SEEK:
    case DID_PLAYBACK_ERROR:
      return false;
    default:
      return state;
  }
};

/**
 * Is the player playing?
 *
 * @param {boolean} state is the player playing?
 * @param {Action} action
 */
const isPlaying = (state: boolean = false, action: PlayerAction): boolean => {
  switch (action.type) {
    case DID_PLAYBACK_PLAY:
      return true;
    case DID_PLAYER_READY:
    case DID_PLAYBACK_COMPLETE:
    case DID_PLAYBACK_ERROR:
    case DID_PLAYBACK_PAUSE:
    case DO_PLAYBACK_PAUSE:
    case DO_PLAYBACK_STOP:
      return false;
    default:
      return state;
  }
};

const startedPlayingAt = (state: number = 0, action: PlayerAction): number => {
  switch (action.type) {
    case DID_PLAYBACK_PLAY:
      return Math.round(new Date().getTime() / 1000);
    default:
      return state;
  }
};

/**
 * The current (or most recently) playing track
 *
 * @param {Track} state the currently playing Track
 * @param {Action} action
 */
export const currentlyPlaying = (
  state: Track | null | undefined = null,
  action: PlayerAction
): Track | null | undefined => {
  switch (action?.type) {
    case DO_PLAYBACK_TRACK:
      return Object.assign({}, action?.payload?.track);
    //@ts-ignore
    case DO_PLAYBACK_STOP:
      break;
    //@ts-ignore
    case REQ_AUTH_LOGOUT:
      //@ts-ignore
      return null;
    default:
      return state;
  }
};

/**
 * The current player seek position
 *
 * @param {number} state the current seek position
 * @param {Action} action
 */
export const position = (state: number = 0, action: PlayerAction): number => {
  switch (action.type) {
    case DID_PLAYBACK_SEEK: {
      const { position } = action.payload;
      return position;
    }
    case DO_PLAYBACK_TRACK:
      return 0;
    default:
      return state;
  }
};

/**
 * The duration of the active track
 *
 * @param {number} state the current player duration
 * @param {Action} action
 */
export const duration = (state: number = 0, action: PlayerAction): number => {
  switch (action.type) {
    case DID_PLAYBACK_SEEK: {
      const { duration } = action.payload;
      return duration;
    }
    case DO_PLAYBACK_TRACK:
      return 0;
    default:
      return state;
  }
};

export const queue = (state: Array<Track> = [], action: PlayerAction) => {
  switch (action.type) {
    case DO_PLAYBACK_TRACK:
      return [...action.payload.context];
    default:
      return state;
  }
};

const playbackError = (state = '', action) => {
  switch (action.type) {
    case RES_STREAM_AUDIO_FAILURE:
      return action.payload.response.error;
    case RES_STREAM_AUDIO_SUCCESS:
      return '';
    default:
      return state;
  }
};

const isPlayerScriptReady = (state = false, action) => {
  switch (action.type) {
    case DID_PLAYER_SCRIPT_LOAD:
      return true;
    case DID_PLAYER_SCRIPT_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  currentlyPlaying,
  currentlyPlayingUrl,
  shouldPlay,
  isLoading,
  isPlaying,
  startedPlayingAt,
  queue,
  position,
  duration,
  playbackError,
  isPlayerScriptReady,
});
