import React, { Component } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

type Props = {
  to: string;
  as: string;
  or: string;
  isActive: boolean;
  children: Object;
};

class ConditionalLink extends Component<Props> {
  render() {
    const { children, isActive, to, as, or } = this.props;
    return isActive ? (
      <Link href={to} as={as} passHref={true}>
        <StyledLink>{children}</StyledLink>
      </Link>
    ) : (
      <Link href={or} passHref={true}>
        <StyledLink>{children}</StyledLink>
      </Link>
    );
  }
}

const StyledLink = styled.a`
  display: block;
`;

export default ConditionalLink;
