// rudimentary feature flags.
//
// Using process.env.NODE_ENV === 'test' to always enable features in test mode,
// enabling test suites to run

function isTestMode() {
  return process.env.NODE_ENV === 'test';
}

export default isTestMode()
  ? {
      unsubscribe: true,
      username: true,
    }
  : {
      unsubscribe: true,
      username: true,
    };
