import Sticky from 'react-stickynode';
import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export default styled(Sticky)`
  .sticky-inner-wrapper {
    &.active {
      padding-top: 50px;
    }

    ${media.large`
      &.active {
        padding-top: 70px;
      }
    `};
  }
`;
