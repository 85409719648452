let _googleAnalyticsId; // active id in init
let _gtagSend; // push events to data layer

export function gtag() {
  window.dataLayer.push(arguments);
}

export function init(googleId) {
  if (typeof window === 'undefined') {
    return;
  }

  if (window.__GA_ID__) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  // function gtag() {
  //   window.dataLayer.push(arguments);
  // }
  _gtagSend = gtag;
  window.gtagSend = gtag;

  _googleAnalyticsId = googleId;
  window.gtagSend('js', new Date());
  window.gtagSend('config', `${googleId}`);
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  // if we have window.gtag we're proably using a Data Stream ID
  // and need to call the window.gtag
  if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
    setTimeout(() => {
      window.gtagSend('config', window.__GA_ID__);
    }, 0); // 0ms timeout gives Next.js page title time to update
  }

  // if we have window.ga we're probably using the universal analytics ID
  // which means we need to use the gtag function to push items to the
  // dataLayer...
  if (typeof window !== 'undefined' && typeof window.ga !== 'undefined') {
    setTimeout(() => {
      window.gtagSend('set', 'page', url);
      window.gtagSend('config', _googleAnalyticsId);
    }, 0); // 0ms timeout gives Next.js page title time to update
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
    setTimeout(() => {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    }, 0);
  }

  if (typeof window !== 'undefined' && typeof window.ga !== 'undefined') {
    setTimeout(() => {
      _gtagSend('event', action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    }, 0);
  }
};
