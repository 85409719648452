import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const PageTitle = styled.h1`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 24px;
  font-weight: bold;

  ${media.small`
    
    
  `};

  ${media.medium`
    
  `};

  ${media.large`
    

  `};
`;
