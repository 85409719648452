import { Bundle } from '../common/types/Bundle';
import { User } from '../common/types/User';
import { BundleSubscription } from '../common/types/BundleSubscription';
import { Channel } from '../common/types/Channel';

// visitor - anon
// member - registered
// customer - subscriber

const LOG = false;

function log(...args) {
  if (LOG) console.log(...args);
}

export function isUserSubscribedToBundle(user: User, bundle: Bundle) {
  const userBundles = getActiveUserBundles(user);
  const userBundlesUniqueNames = convertBundleSubscriptionArryToBundleUniqueNameArray(
    userBundles
  );
  // if one of the users bundles matches the bundle uniqueName
  if (arrayIntersect(userBundlesUniqueNames, [bundle.uniqueName])) {
    log('user is subscribed to bundle' + bundle.uniqueName);
    return true;
  }
  log('user is not subscribed to bundle' + bundle.uniqueName);

  return false;
}

export function isContentOnAnonymousBundle(bundles: Bundle[], content) {
  const anonymousBundles = filterAnonymousBundles(bundles);
  const anonymousBundleUniqueNames = convertBundleArrayToBundleUniqueNameArray(
    anonymousBundles
  );

  if (arrayIntersect(anonymousBundleUniqueNames, content.bundleUniqueNames)) {
    log('content is on anonymous bundle');
    return true;
  }
  log('content is not on anonymous bundle');
  return false;
}

export function isContentOnRegisteredBundle(bundles: Bundle[], content) {
  const registeredBundles = filterRegisteredBundles(bundles);
  const registeredBundleUniqueNames = convertBundleArrayToBundleUniqueNameArray(
    registeredBundles
  );

  if (arrayIntersect(registeredBundleUniqueNames, content.bundleUniqueNames)) {
    log('content is on registered bundle');
    return true;
  }
  log('content is not on registered bundle');
  return false;
}

export function isContentOnRegisteredBundleAndUserLoggedIn(
  bundles: Bundle[],
  content,
  user: User
) {
  if (!user.isAuthed) {
    log('user is not logged in.');
    return false;
  }
  log('user is logged in');
  return isContentOnRegisteredBundle(bundles, content);
}

export function isUserSubscribedToContent(channel, user: User, content) {
  const userBundles = getActiveUserBundles(user);
  const userBundlesUniqueNames = convertBundleSubscriptionArryToBundleUniqueNameArray(
    userBundles
  );

  // is the user subscribed to a bundle that contains this content?
  if (arrayIntersect(userBundlesUniqueNames, content.bundleUniqueNames)) {
    log('user is subscribed to content');
    return true;
  }
  log('user is not subscribed to content');
  return false;
}

export function isUserSubscribedToAnyPaidBundle(user: User, bundles: Bundle[]) {
  const userBundles = getActiveUserBundles(user);
  const paidBundles = filterPaidBundles(bundles);
  const bundleUniqueNames = convertBundleArrayToBundleUniqueNameArray(
    paidBundles
  );

  if (arrayIntersect(userBundles, bundleUniqueNames)) {
    log('user is subscribed to a paid bundle');
    return true;
  }
  log('user is not subscribed to any paid bundle');
  return false;
}

export function isPaidSubscriptionBundleAvailableToUser(
  user: User,
  bundles: Bundle[]
) {
  const userBundles = getActiveUserBundles(user);
  const userBundleUniqueNames = convertBundleSubscriptionArryToBundleUniqueNameArray(
    userBundles
  );
  const paidBundles = filterPaidBundles(bundles);
  const paidBundleUniqueNames = convertBundleArrayToBundleUniqueNameArray(
    paidBundles
  );

  // remove all the user's bundles from the list of paid bundles
  // if remainingPaidBundles is greater than 0 we can show the subscribe button
  const remainingPaidBundles = paidBundleUniqueNames.filter(
    (paidBundleUniqueName) =>
      !userBundleUniqueNames.includes(paidBundleUniqueName)
  );

  return remainingPaidBundles.length > 0;
}

function arrayIntersect(one, two) {
  return one.filter((value) => two.includes(value)).length;
}

// TODO do we need to check dates or is reset on page refresh good enough?
function getActiveUserBundles(user: User) {
  if (user && user.profile && user.profile.bundleSubscriptions) {
    return user.profile.bundleSubscriptions;
  }
  return [];
}

function filterPaidBundles(bundles: Bundle[]) {
  return bundles.filter((bundle) => bundle.accessTypeCode === 'PAID');
}

function filterAnonymousBundles(bundles: Bundle[]) {
  return bundles.filter((bundle) => bundle.accessTypeCode === 'ANONYMOUS');
}

function filterRegisteredBundles(bundles: Bundle[]) {
  return bundles.filter((bundle) => bundle.accessTypeCode === 'REGISTERED');
}

function convertBundleArrayToBundleUniqueNameArray(bundles: Bundle[]) {
  return bundles.map((bundle) => bundle.uniqueName);
}

function convertBundleSubscriptionArryToBundleUniqueNameArray(
  bundles: BundleSubscription[]
) {
  return bundles.map((bundle) => bundle.bundleUniqueName);
}

export function isChannelSubscribable(channel: Channel) {
  return channel.isSubscribable;
}

const RESTRICTED_CHANNEL = 'tvfit2';
const PERMITTED_COUNTRY_CODE = 'AE';

// TODO HACK WARNING. Sorry. Quickly restricting login on tvfit2 channel to a UAE
export function allowAccountCreation(channel: Channel, geo) {
  // return true for all channels except this special case, in which case
  // return false unless the geo.geo.country field matches the permitted country
  if (channel.cleanname === RESTRICTED_CHANNEL) {
    if (geo && geo.geo && geo.geo.country === PERMITTED_COUNTRY_CODE) {
      return true;
    }
    return false;
  }
  return true;
}
