import { AppState } from '../common/types/AppState';
import { Track } from '../common/types/Track';

export const selectShouldPlay = (state: AppState): boolean => {
  return state.player.shouldPlay;
};

export const selectIsPlaying = (state: AppState): boolean => {
  return state.player.isPlaying;
};

export const selectIsLoading = (state: AppState): boolean => {
  return state.player.isLoading;
};

export const selectCurrentlyPlaying = (state: AppState): Track => {
  return state.player.currentlyPlaying;
};

export const selectCurrentlyPlayingUrl = (state: AppState): string => {
  return state.player.currentlyPlayingUrl;
};

export const selectCurrentSeekPosition = (state: AppState): number => {
  return state.player.position;
};

export const selectCurrentTrackDuration = (state: AppState): number => {
  return state.player.duration;
};

export const selectQueue = (state: AppState): Array<Track> => {
  return state.player.queue;
};

export const selectPlayerError = (state: AppState): string => {
  return state.player.playbackError;
};

export const selectIsPlayerScriptReady = (state) => {
  return state.player.isPlayerScriptReady;
};
