import React from 'react';
import { FormattedMessage } from 'react-intl';
import Head from 'next/head';
import { sizeCloudinaryImage } from '..';

interface Props {
  titleId: string;
  descriptionId: string;
  values?: any;
}

export default ({ titleId, descriptionId, values }: Props) => {
  const { image, url, large } = values || {};

  return (
    <FormattedMessage id={titleId} values={values}>
      {(title) => (
        <FormattedMessage id={descriptionId} values={values}>
          {(description) => (
            <Head>
              <title>{title}</title>
              <meta property="og:title" content={title} />
              <meta name="twitter:title" content={title} />
              <meta name="description" content={description} />
              <meta property="og:description" content={description} />
              <meta name="twitter:description" content={description} />
              <meta property="og:type" content="website" />
              {image && (
                <>
                  <meta
                    name="twitter:image"
                    content={sizeCloudinaryImage(image, 800)}
                  />
                  <meta name="twitter:card" content="summary_large_image" />
                  <meta
                    property="og:image"
                    content={sizeCloudinaryImage(image, 800)}
                  />
                </>
              )}
              {url && <meta property="og:url" content={url} />}
              {large && (
                <meta name="twitter:card" content="summary_large_image" />
              )}
            </Head>
          )}
        </FormattedMessage>
      )}
    </FormattedMessage>
  );
};
