import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { LayoutContainer, PageBody, Button } from '../../layout';
import { HelmetIntl, Spinner } from '../../common';
import { withSubscription } from '../../withSubscription';
import { BASE_URL } from '../../api';
import {
  H1,
  Form,
  StyledTextField,
  StyledNote,
  StyledRaisedButton,
  StyledFormError,
} from './styles';
import AppStoreButtons from '../../homepage/components/AppStoreButtons';

function PasswordResetPage({ token, email, user, channel, hideChrome }) {
  return (
    <LayoutContainer hideChrome={hideChrome}>
      <HelmetIntl
        titleId="app.meta.passwordreset.title"
        descriptionId="app.meta.passwordreset.description"
      />
      <PageBody isContainer>
        {token ? (
          <PasswordReset channel={channel} token={token} />
        ) : (
          <PasswordResetRequest
            channel={channel}
            email={email}
            hideChrome={hideChrome}
          />
        )}
      </PageBody>
    </LayoutContainer>
  );
}

function getValidationSchemaRequest(values) {
  return Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address.')
      .required('Email address is required.'),
  });
}

const validateRequest = (values) => {
  const validationSchema = getValidationSchemaRequest(values);
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
};

function getErrorsFromValidationError(validationError) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
}

function PasswordResetRequest({ channel, email, hideChrome }) {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState();

  async function onSubmit(values) {
    try {
      const res = await fetch(`${BASE_URL}/user/reset-password-request`, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: values.email,
          channelId: channel.artistId,
        }),
      });

      if (res.status !== 204) {
        const data = await res.json();
        if (data.status && (data.status >= 400 || data.status <= 503)) {
          setError('There was a problem requesting your password reset.');
          console.error(data);
        }
      }

      if (res.status === 200 || res.status === 204) {
        setSubmitted(true);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      {!submitted ? (
        <Formik
          validate={validateRequest}
          initialValues={{ email: email }}
          onSubmit={(values, { setSubmitting, isValid }) => {
            return onSubmit(values).then((action) => {
              setSubmitting(false);
            });
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} method="POST">
              <H1>
                <FormattedMessage id="app.meta.passwordreset.title" />
              </H1>

              <label htmlFor="email">Email</label>
              <StyledTextField
                id="email"
                label="Email"
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />

              {touched.email ? (
                <StyledFormError>{errors.email}</StyledFormError>
              ) : null}

              {error ? <p>{error}</p> : null}
              <StyledRaisedButton
                variant="raised"
                type="submit"
                label="Sign in"
                color="primary"
                tabIndex="2"
                disabled={isSubmitting || values.email === ''}>
                {isSubmitting ? (
                  <Spinner />
                ) : (
                  <FormattedMessage id="app.meta.passwordreset.title" />
                )}
              </StyledRaisedButton>

              {!hideChrome && (
                <StyledNote>
                  Nevermind,{' '}
                  <Link href="/login">
                    <a>I remember it</a>
                  </Link>
                  .
                </StyledNote>
              )}
            </Form>
          )}
        </Formik>
      ) : null}

      {submitted ? (
        <>
          <h1>Please check your email</h1>
          <p>
            If your email was found in our system you will receive a reset link
            to reset your password.
          </p>
        </>
      ) : null}
    </>
  );
}

function getValidationSchema(values) {
  return Yup.object().shape({
    password: Yup.string()
      .min(8, 'Minimum of 8 characters.')
      .required('A password is required.'),

    confirm: Yup.string()
      .oneOf([values.password], 'Passwords do not match.')
      .required('Password confirmation is required.'),
  });
}

const validate = (values) => {
  const validationSchema = getValidationSchema(values);
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
};

function PasswordReset({ token, email, channel }) {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState();

  async function onSubmit(values) {
    try {
      const res = await fetch(`${BASE_URL}/user/reset-password`, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          password: values.password,
          token: token,
        }),
      });

      if (res.status !== 204) {
        const data = await res.json();
        if (data.status && (data.status >= 400 || data.status <= 503)) {
          setError('There was a problem requesting your password reset.');
          console.error(data);
        }
      }

      if (res.status === 401) {
        setError(
          'There was a problem resetting your password. Your email link may have expired.'
        );
      }

      if (res.status === 200 || res.status === 204) {
        setSubmitted(true);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      {!submitted ? (
        <Formik
          validate={validate}
          initialValues={{ password: '', confirm: '' }}
          onSubmit={(values, { setSubmitting, isValid }) => {
            return onSubmit(values).then((action) => {
              setSubmitting(false);
            });
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} method="POST">
              <H1>
                <FormattedMessage id="app.meta.passwordreset.title" />
              </H1>

              <label htmlFor="password">Password</label>
              <StyledTextField
                id="password"
                type="password"
                name="password"
                placeholder="New password"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.password}
                flatBottom={values.password ? true : false}
              />

              {touched.password ? (
                <StyledFormError>{errors.password}</StyledFormError>
              ) : null}

              <label htmlFor="confirm">Confirm password</label>
              <StyledTextField
                id="confirm"
                type="password"
                name="confirm"
                placeholder="Confirm password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirm}
              />
              {touched.confirm ? (
                <StyledFormError>{errors.confirm}</StyledFormError>
              ) : null}

              {error ? <p>{error}</p> : null}

              <StyledRaisedButton
                variant="raised"
                type="submit"
                label="Reset password"
                color="primary"
                tabIndex="2"
                disabled={false}>
                {isSubmitting ? (
                  <Spinner />
                ) : (
                  <FormattedMessage id="app.meta.passwordreset.title" />
                )}
              </StyledRaisedButton>

              <StyledNote>
                Nevermind, I{' '}
                <Link href="/">
                  <a>changed my mind</a>
                </Link>
                .
              </StyledNote>
            </Form>
          )}
        </Formik>
      ) : null}

      {submitted ? (
        <SubmitttedPasswordReset>
          <h1>Your password was reset successfully.</h1>
          <br />
          <br />

          <Link href="/login" passHref={true}>
            <Button>Login on Web</Button>
          </Link>
          <br />
          <br />
          <h2>Download our app</h2>
          <AppStoreButtons
            style={{ textAlign: 'center', alignItems: 'center' }}
            channel={channel}
          />
        </SubmitttedPasswordReset>
      ) : null}
    </>
  );
}

const SubmitttedPasswordReset = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export default withSubscription(PasswordResetPage);
