import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

const PIXEL_OPTIONS = {
  debug: false,
  autoConfig: false,
};

export default function Pixel({ facebookId, event = null }) {
  useEffect(() => {
    ReactPixel.init(facebookId, null, PIXEL_OPTIONS);
    ReactPixel.pageView();
    // ReactPixel.trackCustom('randomevent', { randomData: true });
  }, [facebookId]);

  return null;
}
