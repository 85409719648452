import { useState, useRef } from 'react';
import { useScrollPosition, useOnClickOutside } from '../layout.hooks';
import Link from 'next/link';
import Navigation from './Navigation';
import UserNavigation from './UserNavigation';
import * as S from './Header2.styles';
import { isPaidSubscriptionBundleAvailableToUser } from '../../user/user.helpers';
import { useSubscription } from '../../user/user.hooks';
import { Channel } from '../../common/types/Channel';
import { User } from '../../common/types/User';

type Props = {
  channel: Channel;
  user: User;
  isSubscribed: boolean;
};

// define some logos, i bet there's more than one eventually
export const channelsWithLogos = ['theartschannel', 'tvfit', 'tvfit2'];

// base bucket directory with logos in it
const logoBucket = 'https://s3-eu-west-1.amazonaws.com/supapass-public/logos/';

export function Header2({ channel, user }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const position = useScrollPosition();
  const ref = useRef();
  const burgerRef = useRef();
  const { bundlePlans } = useSubscription();

  const isSubscriptionAvailable = isPaidSubscriptionBundleAvailableToUser(
    user,
    bundlePlans
  );

  useOnClickOutside([ref, burgerRef], () => setIsMenuOpen(false));

  const isScrolled = position.y > 10 ? true : false;

  return (
    <S.Header isMenuOpen={isMenuOpen} isScrolled={isScrolled}>
      <S.Burger
        isMenuOpen={isMenuOpen}
        ref={burgerRef}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}>
        <span />
        <span />
        <span />
      </S.Burger>
      <BrandSwitch channel={channel} />
      <Navigation
        reference={ref}
        channel={channel}
        user={user}
        isMenuOpen={isMenuOpen}
        isScrolled={isScrolled}
        canSubscribe={isSubscriptionAvailable}
      />
      <UserNavigation
        channel={channel}
        user={user}
        canSubscribe={isSubscriptionAvailable}
      />
    </S.Header>
  );
}

function BrandSquare({ channel }) {
  return (
    <S.Logo data-test="home">
      <Link href="/">
        <a>
          <img
            src={channel.imageUrl} // 'https://s3-eu-west-1.amazonaws.com/supapass-public/logos/theartschannel.png'
            alt={channel.name}
          />
        </a>
      </Link>
    </S.Logo>
  );
}

function BrandLogo({ channel }) {
  return (
    <S.Logo data-test="home">
      <Link href="/">
        <a>
          <S.AppIconWide
            src={`${logoBucket}${channel.cleanname}.png`}
            alt={channel.homepage.logoName || channel.name}
          />
        </a>
      </Link>
    </S.Logo>
  );
}

function BrandSwitch({ channel }) {
  // check if this channel has an entry in the channelLogos object
  // if it does we won't show the wordmark
  if (~channelsWithLogos.indexOf(channel.cleanname)) {
    return <BrandLogo channel={channel} />;
  }

  return <BrandSquare channel={channel} />;
}
