// TODO do sessions/cookie handling for server requests in a more
// sensible way
import cookieParse from 'cookie-parse';
import { TOKEN } from './user.constants';

export function quickCookie(cookie) {
  let token = '';
  if (cookie) {
    const parsedCookie = cookieParse.parse(cookie);
    token = parsedCookie[TOKEN];
  }
  return token;
}
