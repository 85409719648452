import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { media } from '../themes/mediaQueries';
import { doToggleTheme } from '../Layout.redux';
import { doLogout } from '../../user/user.actions';

/**
 * TODO: Refactor to make a sensible component that doesn't work like this one.
 */
class Dropdown extends Component {
  state = {
    showMenu: false,
  };

  showMenu = this.showMenu.bind(this);
  closeMenu = this.closeMenu.bind(this);
  dropdownMenu = React.createRef();

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  render() {
    return (
      <React.Fragment>
        <MenuToggle onClick={this.showMenu} data-test="tab--account">
          {this.props.children}
        </MenuToggle>

        {this.state.showMenu ? (
          <MenuList ref={this.dropdownMenu}>
            <MenuItem onClick={this.props.doToggleTheme}>Change theme</MenuItem>
            <MenuItem onClick={this.props.doLogout}>Logout</MenuItem>
          </MenuList>
        ) : null}
      </React.Fragment>
    );
  }
}

export const DropdownLabel = styled.div`
  display: none;
  ${media.large`
      display: inline-block;
      margin-right: 10px;
    `};
`;

export const MenuToggle = styled.span`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
  text-align: center;
  cursor: pointer;
  svg {
    display: block;
    margin: auto;
  }
`;

const MenuList = styled.div`
  position: fixed;
  z-index: 1000;
  right: 0px;
  border: 1px solid ${(props) => props.theme.hairline};
  border-radius: 3px;
  top: 52px;
  background-color: ${(props) => props.theme.background};
  box-sizing: border-box;

  ${media.large`
    top: 72px;
    right: auto;
  `};
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
`;

const MenuItem = styled.a`
  padding: 10px 20px;
  cursor: pointer;
  display: block;

  &:hover {
    background: ${(props) => props.theme.peak};
  }
`;

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return {
    doToggleTheme: () => dispatch(doToggleTheme()),
    doLogout: () => dispatch(doLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
