import { API_ERROR, CLEAR_ERRORS } from './api.constants';
import { APIErrorAction } from './api.types';

// const initError: APIError = {
//   message: '',
//   status: 0,
//   statusText: ''
// };

/**
 *
 * @param {object} state
 * @param {object} action
 */
export const reducer = (state: Object[] = [], action: APIErrorAction) => {
  switch (action.type) {
    case API_ERROR:
      return [action.payload, ...state];
    case CLEAR_ERRORS:
      return [];
    default:
      return state;
  }
};
