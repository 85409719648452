import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doLogin, doRegister } from './user.actions';
import {
  selectIsAuthed,
  selectAuthError,
  selectUserAgreementsRequired,
  selectConsent,
} from './user.selectors';
import LoginForm from './components/LoginForm';
import AccountForm from './components/AccountForm';
import Router from 'next/router';
import { withSubscription } from '../withSubscription';
import { selectActiveChannel } from '../channel';

type Props = {
  register?: boolean;
};

function LoginContainer(props: Props) {
  const { register } = props;

  const dispatch = useDispatch();
  const isAuthed = useSelector(selectIsAuthed);
  const authError = useSelector(selectAuthError);
  const userAgreementsRequired = useSelector(selectUserAgreementsRequired);
  const consent = useSelector(selectConsent);
  const channel = useSelector(selectActiveChannel);

  useEffect(() => {
    if (isAuthed) {
      if (channel.counts.video) {
        Router.push('/video');
      } else if (channel.counts.album) {
        Router.push('/audio');
      } else if (channel.counts.article) {
        Router.push('/articles');
      } else {
        Router.push('/settings/profile');
      }
    }

    return () => {};
  }, [isAuthed]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (fields) => {
    return dispatch(doLogin(fields));
  };

  const onSubmitRegistration = (fields) => {
    return dispatch(doRegister(fields));
  };

  if (register) {
    return (
      <AccountForm
        {...props}
        onSubmit={onSubmitRegistration}
        onRegisterSuccess={onSubmit}
        authError={authError}
        consent={consent}
      />
    );
  }

  return (
    <LoginForm
      {...props}
      onSubmit={onSubmit}
      authError={authError}
      userAgreementsRequired={userAgreementsRequired}
      consent={consent}
    />
  );
}

// @ts-ignore
export default withSubscription(LoginContainer);
