export default {
  black: '#000000',
  white: '#ffffff',
  grey: '#dadada',
  slate: '#313847',
  action1: '#F23D55',
  action2: '#30BF99',
  action3: '#4954E6',
  clear: '#FFFFFF',
  brand: 'rgba(255, 125, 0, 1.00)',
  footerBackground: '#111111',
  footerForeground: '#ccc',
  padding: 8,
  radius: 4,
  animationNormal: 0.25,
  easing: 'ease-in-out',
  socials: {
    twitter: '#1da1f2',
    facebook: '#3b5998',
  },
  type: {
    xsmall: '12px',
    small: '14px',
    regular: '16px',
    large: '20px',
    heroTitle: ['24px', '40px'],
    heroSubtitle: ['16px', '24px'],
    title: ['20px', '24px'],
    subtitle: ['16px', '20px'],
    text: ['14px', '16px'],
  },
  lineHeight: {
    small: '24px',
    heroTitle: ['32px', '56px'],
    heroSubtitle: ['24px', '32px'],
    title: ['24px', '32px'],
    subtitle: ['24px', '24px'],
    text: ['24px', '24px'],
  },
  shadow: {
    heavy: `0 2px 8px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1)`,
    opacity: 'rgba(29,33,41,0.13)',
  },
  maxWidth: {
    block: '540px',
    text: '712px',
    page: '1280px',
  },
  accentColour: '#ff7d00',
  burgerSize: 24,
  cubicBezier: '.28,.62,.71,.92',
};
