import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import Layout from './components/Layout';
import SupaPassThemeProvider from './SupaPassThemeProvider';
import {
  doToggleTheme,
  selectActiveTheme,
  selectAccentColour,
} from './Layout.redux';
import { doLogout, selectUser } from '../user';
import { selectAPIError } from '../api';
import { selectPlayerError } from '../player';
import { withSubscription } from '../withSubscription';

import { User } from '../common/types/User';
import { AppState } from '../common/types/AppState';
import { Channel } from '../common/types/Channel';

type Props = {
  activeTheme: string;
  children?: any;
  doToggleTheme: Function;
  doLogout: Function;
  doClearErrors?: Function;
  user: User;
  accentColour: string;
  useAccent?: boolean;
  hasTabs?: boolean;
  errors: Array<Object>;
  playerError: any;
  channel: Channel;
  isSubscribed: boolean;
  hideChrome: boolean;
};

function LayoutContainer({
  activeTheme,
  children,
  doToggleTheme,
  doLogout,
  doClearErrors,
  user,
  accentColour,
  useAccent,
  hasTabs,
  errors,
  playerError,
  channel,
  isSubscribed,
  hideChrome,
}) {
  return (
    <>
      <SupaPassThemeProvider
        activeTheme={activeTheme}
        accentColour={accentColour}>
        <Layout
          activeTheme={activeTheme}
          doToggleTheme={doToggleTheme}
          doLogout={doLogout}
          doClearErrors={doClearErrors}
          user={user}
          accentColour={accentColour}
          useAccent={useAccent}
          hasTabs={hasTabs}
          errors={errors}
          channel={channel}
          isSubscribed={isSubscribed}
          hideChrome={hideChrome}
          children={children}
        />
      </SupaPassThemeProvider>
    </>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    activeTheme: selectActiveTheme(state),
    user: selectUser(state),
    accentColour: selectAccentColour(state),
    errors: selectAPIError(state),
    playerError: selectPlayerError(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ doToggleTheme, doLogout }, dispatch);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSubscription
)(LayoutContainer);
