import { useState, useEffect } from 'react';
import * as localAPI from '../api/localAPI';
import { useSelector } from 'react-redux';
import {
  selectActiveChannelId,
  selectChannelById,
  selectActiveChannel,
} from '../channel';
import { selectUser } from '.';
import {
  selectBundles,
  selectBundlePlans,
} from '../subscribe/subscribe.selectors';
import {
  isUserSubscribedToContent,
  isUserSubscribedToBundle,
  allowAccountCreation,
} from './user.helpers';
import { LayoutContainer, PageBody } from '../layout';
import Disallowed from './components/Disallowed';
import { selectTracksById } from '../tracks/TrackCollection.redux';
import { selectReleaseById } from '../releases/releases.selectors';

export function useSubscription() {
  const cleanName = useSelector(selectActiveChannelId);
  const channel = useSelector((state) => selectChannelById(state, cleanName));
  const user = useSelector(selectUser);
  const bundles = useSelector(selectBundles);
  const bundlePlans = useSelector(selectBundlePlans);

  function isSubscribedToBundle(bundle) {
    return isUserSubscribedToBundle(user, bundle);
  }

  function isSubscribedToContent(content) {
    return isUserSubscribedToContent(user, content);
  }

  return {
    cleanName,
    channel,
    user,
    bundles,
    bundlePlans,
    isSubscribedToContent,
    isSubscribedToBundle,
  };
}

export function useGeo() {
  const [geo, setGeo] = useState(null);

  useEffect(() => {
    (async () => {
      const geo = await localAPI.doGeoLookup();
      if (geo === null) {
        setGeo(false);
      } else {
        setGeo(geo.geo === null ? false : geo);
      }
    })();
  }, []);

  return geo;
}

export function useChannel() {
  return useSelector((state) => selectActiveChannel(state));
}

// useTrack returns a track object for a particular trackId
// we need the collectionId to provide the track's album or podcast context
// this affects how the track is displayed
export function useTrack(trackId) {
  return useSelector((state) => selectTracksById(state, trackId));
}

export function useRelease(releaseId) {
  return useSelector((state) => selectReleaseById(state, releaseId));
}

export function withGeo(Component) {
  return (props) => {
    const geo = useGeo();

    return <Component {...props} geo={geo} />;
  };
}

export function withRegionCheck(Component) {
  return (props) => {
    const geo = useGeo();
    const channel = useChannel();
    if (!allowAccountCreation(channel, geo)) {
      return (
        <LayoutContainer>
          <PageBody isContainer>
            <Disallowed geo={geo} />
          </PageBody>
        </LayoutContainer>
      );
    }
    return <Component {...props} geo={geo} />;
  };
}
