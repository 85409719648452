import { TOKEN } from '../user';
import Cookies from 'js-cookie';

export const PROD = process.env.NODE_ENV === 'production' ? true : false;
export const BASE_URL = PROD
  ? process.env.SP_API_BASE_URL_PROD
  : process.env.SP_API_BASE_URL_TEST;

export async function callAPI(
  endpoint: string,
  body: any = null,
  auth: boolean = false,
  httpMethod: string = 'GET'
) {
  let headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  if (auth) {
    headers = { ...headers, Authorization: Cookies.get(TOKEN) };
  }

  const res = await fetch(`${BASE_URL}${endpoint}`, {
    method: httpMethod,
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin',
    headers: headers,
    body: body ? JSON.stringify(body) : undefined,
  });

  return await checkResponse(res);
}

// TODO remove this. Temporarily working around API returning invalid JSON responses.
async function checkResponse(res) {
  const contentType = res.headers.get('Content-Type');
  const contentLength = res.headers.get('Content-Length');

  const isJson = contentType && contentType.includes('application/json');
  const hasBody = contentLength && contentLength > 0;

  try {
    if (isJson) {
      const json = await res.json();
      return { data: json, status: res.status };
    }

    if (!isJson && hasBody) {
      const text = await res.text();
      return { data: text, status: res.status };
    }

    return res;
  } catch (e) {
    throw new Error(e);
  }
}
