import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const ListTitle = styled.h2`
  padding: ${(p) => p.theme.padding}px ${(p) => p.theme.padding * 2}px;

  margin-bottom: ${(p) => p.theme.padding}px;
  padding-bottom: 0;
  /* letter-spacing: 2px; */
  /* text-transform: uppercase; */
  color: ${(props) => props.theme.foreground2};
  font-weight: 500;
  font-size: 20px;
  width: 100%;
  display: flex;

  ${media.medium`
    padding: ${(p) => p.theme.padding}px 0;
  `}

  ${media.xlarge`
    
    font-size: 24px;
  `};

  small {
    font-size: 14px;
    text-transform: lowercase;
    letter-spacing: 0;
    color: ${(p) => p.theme.foreground3};
    margin-left: ${(p) => p.theme.padding / 2}px;

    ${media.large`
      font-size: 16px;
    `};
  }

  button {
    margin-left: auto;
    padding: 0;
    font-size: 14px;
    color: ${(p) => p.theme.accentColour};
    white-space: nowrap;
  }
`;
