import { createAppleLink, createGoogleLink } from '../../common';
import * as S from './Homepage.styles';

export default function AppStoreButtons({ channel }) {
  const { appleId, hasGoogleApp } = channel;

  return (
    <>
      {appleId || hasGoogleApp ? (
        <S.AppStoreButtonSection>
          {appleId ? (
            <S.AppStoreButton apple>
              <a href={createAppleLink(appleId)}>
                <img
                  src="/static/img/apple-app-store-badge.svg"
                  alt="Download on the Apple App Store"
                />
              </a>
            </S.AppStoreButton>
          ) : null}
          {hasGoogleApp ? (
            <S.AppStoreButton>
              <a href={createGoogleLink(channel.cleanname)}>
                <img
                  src="/static/img/google-play-badge.png"
                  alt="Download on Google Play"
                />
              </a>
            </S.AppStoreButton>
          ) : null}
        </S.AppStoreButtonSection>
      ) : null}
    </>
  );
}
