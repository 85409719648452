export const REQ_AUTH = 'user/REQ_AUTH';
export const REQ_AUTH_LOGOUT = 'user/REQ_AUTH_LOGOUT';
export const RES_AUTH_SUCCESS = 'user/RES_AUTH_SUCCESS';
export const RES_AUTH_FAIL = 'user/RES_AUTH_FAIL';
export const REQ_REGISTER = 'user/REQ_REGISTER';
export const RES_REGISTER_SUCCESS = 'user/RES_REGISTER_SUCCESS';
export const RES_REGISTER_FAIL = 'user/RES_REGISTER_FAIL';
export const REQ_USER = 'user/REQ_USER';
export const RES_USER_SUCCESS = 'user/RES_USER_SUCCESS';
export const RES_USER_FAIL = 'user/RES_USER_FAIL';
export const SET_USER = 'SET_USER';

export const STATE_AUTHED = 'user/AUTHED';
export const STATE_UNAUTHED = 'user/UNAUTHED';
export const STATE_AUTHENTICATING = 'user/AUTHENTICATING';
export const TOKEN = 'SPID';
export const SET_TOKEN = 'user/SET_TOKEN';
export const SET_COOKIE_CONSENT = 'user/SET_COOKIE_CONSENT';
