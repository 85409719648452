import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  createSourcesList,
  defaultJwConfig,
  setupJwPlayer,
} from '../../playerTools';
import { YouTubeContainer } from '../../../layout';

import { getYouTubeId } from '../videoTools';
import { Video } from '../../../common/types/Video';

type State = {
  hasError: boolean;
};

type Props = {
  playerId: string;
  video: Video;
  videoUrl: string;
  isPlayerScriptReady: boolean;
};

class VideoPlayer extends React.Component<Props, State> {
  static defaultProps: Props;

  state = {
    hasError: false,
  };

  videoPlayerRef = React.createRef();
  player = null;

  getPlayer = () => {
    if (!this.player) {
      this.player = setupJwPlayer(this.videoPlayerRef.current);
    }
    return this.player;
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (nextProps.videoUrl !== this.props.videoUrl) {
      return true;
    }

    if (this.state.hasError !== nextState.hasError) {
      return true;
    }

    if (nextProps.isPlayerScriptReady !== this.props.isPlayerScriptReady) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps: Props) {
    const { videoUrl, video } = this.props;
    let inputType;

    // don't init jw if it's an embed
    if (video.source === 'supapass') {
      this.configureJwPlayer();
      // TODO // BUG this definitly looks like a bug
      //@ts-ignore
      inputType = { playlist: [createSourcesList(videoUrl)] };
      if (this.player) {
        this.player.setup({ ...defaultJwConfig, playlist: inputType.playlist });
        this.player.play();
      }
    }
  }

  configureJwPlayer() {
    if (!this.player) {
      this.getPlayer();
    }

    if (!this.player) {
      return null;
    }

    // this.player.on('all', (event, thing) => {
    //   console.log('VP: ', event, thing);
    // });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.remove();
      this.player = null;
    }
  }

  render() {
    const { video } = this.props;
    const { hasError } = this.state;

    if (!video) {
      return <div>Loading Video</div>;
    }

    if (hasError) {
      return (
        <div>
          <img src={video.thumbnail} alt="" />
          <FormattedMessage
            id="app.video.error"
            default="Could not load the video"
          />
        </div>
      );
    }

    if (video.source === 'youtube') {
      const youTubeId = getYouTubeId(video.url);
      return (
        <YouTubeContainer>
          <iframe
            src={`//www.youtube.com/embed/${youTubeId}?modestbranding=1&rel=0&showinfo=0`}
            frameBorder="0"
            allow="autoplay"
            allowFullScreen
            title="Video"
          />
        </YouTubeContainer>
      );
    } else {
      return (
        <Player image={video.thumbnail}>
          {/*
          // @ts-ignore */}
          <div ref={this.videoPlayerRef} />
        </Player>
      );
    }
  }
}

const Player = styled.div`
  display: block;
  width: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  overflow: auto;
  max-width: ${(p) => p.theme.maxWidth.page};
  margin: 0 auto;
`;

export default VideoPlayer;
