export const selectArticleById = (state, id) =>
  state.entities.articles.byId[id];

export const selectArticles = (state) =>
  state.entities.articles.allIds.map((id) => selectArticleById(state, id));

export const selectArticleCollectionById = (state, id) =>
  state.entities.articleCollections.byId[id];

export const selectArticleCollections = (state) =>
  state.entities.articleCollections.allIds.map((id) =>
    selectArticleCollectionById(state, id)
  );

export const selectArticlesByCollectionId = (state, articleCollectionId) => {
  const articleCollection = selectArticleCollectionById(
    state,
    articleCollectionId
  );
  return articleCollection.items.map((id) => selectArticleById(state, id));
};
