import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup'; // for everything
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Head from 'next/head';
import Link from 'next/link';
import { LayoutContainer, PageBody } from '../../layout';
import { Spinner } from '../../common';
import { LicenseCheckboxLabel } from '../../subscribe/components/CheckoutAccountForm';
import { getValidationSchema as getValidationSchemaWithLicense } from './AccountForm';
import { withRegionCheck } from '../user.hooks';

import {
  H1,
  Form,
  StyledTextField,
  StyledNote,
  StyledRaisedButton,
  StyledFormError,
  StyledFieldset,
} from './styles';
import { Channel } from '../../common/types/Channel';
import { ConsentAnswers } from '../../common/types/ConsentAnswers';

function getValidationSchema(values) {
  return Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address.')
      .required('Email address is required.'),
    password: Yup.string()
      .min(6, 'Minimum of 6 characters.')
      .required('A password is required.'),
  });
}

const validate = (values) => {
  const validationSchema = getValidationSchema(values);
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
};

const validateLicense = (values) => {
  const validationSchema = getValidationSchemaWithLicense(values);
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
};

function getErrorsFromValidationError(validationError) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
}

type Props = {
  onSubmit: Function;
  isAuthenticating: boolean;
  authError: string;
  userAgreementsRequired: boolean;
  channel: Channel;
  consent: ConsentAnswers;
};

class LoginForm extends Component<Props, any> {
  static defaultProps = {
    error: false,
    isAuthenticating: false,
  };

  render() {
    const {
      authError,
      onSubmit,
      userAgreementsRequired,
      channel,
      consent,
    } = this.props;

    return (
      <LayoutContainer>
        <Head>
          <title>Login</title>
        </Head>
        <PageBody>
          <Formik
            validate={userAgreementsRequired ? validateLicense : validate}
            initialValues={{
              email: '',
              password: '',
              analytics: false,
              channelMarketingOptIn: channel.showChannelMarketingOption
                ? false
                : undefined,
              supapassMarketingOptIn: channel.showSupaPassMarketingOption
                ? false
                : undefined,
              license: false,
            }}
            onSubmit={(values, { setSubmitting, setFieldValue }) => {
              // add analytics consent value to submission
              values.analytics = consent.analytics || false;
              return onSubmit(values).then((action) => {
                setSubmitting(false);
              });
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} method="POST">
                <H1>
                  <FormattedMessage id="app.user.login" />
                </H1>

                <label htmlFor="email">Email</label>
                <StyledTextField
                  id="email"
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />

                {touched.email ? (
                  <StyledFormError>{errors.email}</StyledFormError>
                ) : null}

                <label htmlFor="password">Password</label>
                <StyledTextField
                  label="Password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />

                {touched.password ? (
                  <StyledFormError>{errors.password}</StyledFormError>
                ) : null}

                {authError && !userAgreementsRequired && (
                  <StyledError>{authError}</StyledError>
                )}

                {userAgreementsRequired && (
                  <>
                    <StyledError>Please agree to the agreements</StyledError>

                    <StyledFieldset>
                      <label>
                        <Field
                          type="checkbox"
                          name="license"
                          value={1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />{' '}
                        <LicenseCheckboxLabel channel={channel} />
                      </label>

                      {touched.license ? (
                        <StyledFormError>{errors.license}</StyledFormError>
                      ) : null}

                      {channel.showChannelMarketingOption ? (
                        <>
                          <label>
                            <input
                              type="checkbox"
                              name="channelMarketingOptIn"
                              value={values.channelMarketingOptIn}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />{' '}
                            I'd like to hear from {channel.name} with offers.
                          </label>
                        </>
                      ) : null}
                      {channel.showSupaPassMarketingOption ? (
                        <>
                          <label>
                            <input
                              type="checkbox"
                              name="supapassMarketingOptIn"
                              value={values.supapassMarketingOptIn}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />{' '}
                            I'd like to hear from SupaPass with offers.
                          </label>
                        </>
                      ) : null}
                    </StyledFieldset>
                  </>
                )}

                <StyledRaisedButton
                  variant="raised"
                  type="submit"
                  label="Sign in"
                  color="primary"
                  tabIndex="2"
                  disabled={
                    isSubmitting ||
                    values.password === '' ||
                    values.email === ''
                  }>
                  {isSubmitting ? (
                    <Spinner />
                  ) : (
                    <FormattedMessage id="app.user.login" />
                  )}
                </StyledRaisedButton>

                <StyledNote>
                  <Link href="/reset-password">
                    <a>Forgot password?</a>
                  </Link>
                </StyledNote>
                <StyledNote>
                  Don't have an account?{' '}
                  <Link href="/register">
                    <a>Sign up</a>
                  </Link>
                </StyledNote>
              </Form>
            )}
          </Formik>
        </PageBody>
      </LayoutContainer>
    );
  }
}

const StyledMessage = styled.p`
  margin-top: 12px;
`;

const StyledError = styled(StyledMessage)`
  color: red;
`;

export default withRegionCheck(LoginForm);
