import React from 'react';
import styled from 'styled-components';
import SubscribeButtonLink from './SubscribeButtonLink';
import {
  BundleBox,
  BundleName,
  BundleDesc,
  BundlePrice,
  BundlePeriod,
} from '../../layout/components/Bundle.styles';
import { Channel } from '../../common/types/Channel';

type Props = {
  readonly channel: Channel;
  readonly isSubscribed: boolean;
};

/**
 * Handle legacy checkout case where we hide the monthly subs by using a "- "
 * in the description. :/
 */
function showPricing(string) {
  if (string === '- ' || string === '-') {
    return false;
  }
  return true;
}

export default ({ channel, isSubscribed }: Props) => {
  const { premiumDesc, streamingDesc, vipDesc, price } = channel;
  if (!price) return <div>Pricing unavailable</div>;

  return (
    <Sidebar>
      {showPricing(streamingDesc) ? (
        <BundleBox>
          <BundleName>Monthly subscription</BundleName>
          <BundleDesc>
            {streamingDesc && streamingDesc !== '...' ? (
              <p>{streamingDesc}</p>
            ) : null}
            {showPricing(vipDesc) ? <p>{vipDesc}</p> : null}
          </BundleDesc>
          <BundlePrice>
            <BundlePeriod>Monthly</BundlePeriod>
            <SubscribeButtonLink
              narrow
              inline
              channel={channel}
              period="standard"
              label={`£${(price.monthly.streaming + price.monthly.vip).toFixed(
                2
              )}`}
            />
          </BundlePrice>
        </BundleBox>
      ) : null}

      {showPricing(premiumDesc) ? (
        <BundleBox>
          <BundleName>Yearly subscription</BundleName>
          <BundleDesc>{premiumDesc ? <p>{premiumDesc}</p> : null}</BundleDesc>
          <BundlePrice>
            <BundlePeriod>Yearly</BundlePeriod>
            <SubscribeButtonLink
              narrow
              inline
              channel={channel}
              period="vip"
              label={`£${price.yearly.toFixed(2)}`}
            />
          </BundlePrice>
        </BundleBox>
      ) : null}
    </Sidebar>
  );
};

const Sidebar = styled.div`
  color: ${(props) => props.theme.foreground};
  margin: 0 auto 24px auto;
`;
