import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup'; // for everything
import Link from 'next/link';
import Head from 'next/head';
import { withSubscription } from '../../withSubscription';
import { LicenseCheckboxLabel } from '../../subscribe/components/CheckoutAccountForm';
import { LayoutContainer, PageBody } from '../../layout';

import { RES_REGISTER_SUCCESS, RES_REGISTER_FAIL } from '../user.constants';

import {
  H1,
  Form,
  StyledTextField,
  StyledNote,
  StyledRaisedButton,
  StyledFormError,
  StyledFieldset,
  StyledLabel,
} from './styles';
import { withRegionCheck } from '../user.hooks';

export function getValidationSchema(values) {
  return Yup.object().shape({
    email: Yup.string()
      .email('Enter a valid email address.')
      .required('Email address is required.'),
    password: Yup.string()
      .min(8, 'Minimum of 8 characters.')
      .required('A password is required.'),
    license: Yup.boolean().oneOf(
      [true, '1'],
      'You must accept the terms and privacy.'
    ),
  });
}

const validate = (values) => {
  const validationSchema = getValidationSchema(values);
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
};

function getErrorsFromValidationError(validationError) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
}

class AccountForm extends React.Component {
  state = {
    passwordStrength: { score: 0 },
    serverError: '',
    conflictLogin: null,
  };

  componentDidUpdate(prevProps, prevState) {
    this.tryToLoginIfExists(prevProps, prevState);
  }

  tryToLoginIfExists(prevProps, prevState) {
    const { conflictLogin } = this.state;
    const { onRegisterSuccess } = this.props;

    if (conflictLogin !== null) {
      if (prevState.conflictLogin === null) {
        return onRegisterSuccess(conflictLogin);
      }
      if (conflictLogin.email === prevState.conflictLogin.email) return;
      onRegisterSuccess(conflictLogin);
    }
  }

  render() {
    const { onSubmit, onRegisterSuccess, channel, consent } = this.props;
    const { serverError } = this.state;
    return (
      <LayoutContainer>
        <Head>
          <title>Register</title>
        </Head>
        <PageBody isContainer>
          <Formik
            validate={validate}
            initialValues={{
              email: '',
              password: '',
              channelMarketingOptIn: channel.showChannelMarketingOption
                ? false
                : undefined,
              supapassMarketingOptIn: channel.showSupaPassMarketingOption
                ? false
                : undefined,
              license: false,
            }}
            onSubmit={(values, { setSubmitting, isValid }) => {
              this.setState({
                serverError: '',
              });

              // add analytics consent value to submission
              values.analytics = consent.analytics || false;
              return onSubmit(values).then((action) => {
                setSubmitting(false);

                if (action.type === RES_REGISTER_SUCCESS) {
                  onRegisterSuccess(values);
                }

                if (action.type === RES_REGISTER_FAIL) {
                  if (action.payload.status === 409) {
                    this.setState({ conflictLogin: values });
                  }

                  this.setState({
                    serverError:
                      (action.payload.response &&
                        action.payload.response.error) ||
                      'Sorry, there was a problem.',
                  });
                }
              });
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} method="POST">
                <H1>Create account</H1>
                <StyledLabel htmlFor="email">Email</StyledLabel>
                <StyledTextField
                  id="email"
                  type="email"
                  name="email"
                  placeholder="name@email.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {touched.email ? (
                  <StyledFormError>{errors.email}</StyledFormError>
                ) : null}
                <StyledLabel htmlFor="password">Password</StyledLabel>
                <StyledTextField
                  id="password"
                  type="password"
                  name="password"
                  placeholder="New password"
                  onChange={(e) => {
                    handleChange(e);
                    // this.setState({
                    //   passwordStrength: zxcvbn(values.password)
                    // });
                  }}
                  onBlur={handleBlur}
                  value={values.password}
                  flatBottom={values.password ? true : false}
                />
                {touched.password ? (
                  <StyledFormError>{errors.password}</StyledFormError>
                ) : null}
                {/* <StyledLabel htmlFor="confirm">Confirm password</StyledLabel>
              <StyledTextField
              id="confirm"
              type="password"
              name="confirm"
              placeholder="Confirm password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirm}
              />
              {touched.confirm ? (
              <StyledFormError>{errors.confirm}</StyledFormError>
              ) : null} */}

                <StyledFieldset>
                  <label>
                    <Field
                      type="checkbox"
                      name="license"
                      value={1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />{' '}
                    <LicenseCheckboxLabel channel={channel} />
                  </label>

                  {touched.license ? (
                    <StyledFormError>{errors.license}</StyledFormError>
                  ) : null}

                  {channel.showChannelMarketingOption ? (
                    <>
                      <label>
                        <input
                          type="checkbox"
                          name="channelMarketingOptIn"
                          value={values.channelMarketingOptIn}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />{' '}
                        I'd like to hear from {channel.name} with offers.
                      </label>
                    </>
                  ) : null}
                  {channel.showSupaPassMarketingOption ? (
                    <>
                      <label>
                        <input
                          type="checkbox"
                          name="supapassMarketingOptIn"
                          value={values.supapassMarketingOptIn}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />{' '}
                        I'd like to hear from SupaPass with offers.
                      </label>
                    </>
                  ) : null}
                </StyledFieldset>

                {serverError !== '' ? (
                  <StyledFormError>{serverError}</StyledFormError>
                ) : null}
                <StyledRaisedButton
                  type="submit"
                  disabled={isSubmitting || values.email === ''}
                  tabIndex="3">
                  {isSubmitting ? 'Creating...' : 'Create account'}
                </StyledRaisedButton>
                <StyledNote>
                  Already registered?{' '}
                  <Link href="/login">
                    <a>Sign in</a>
                  </Link>
                </StyledNote>
              </Form>
            )}
          </Formik>
        </PageBody>
      </LayoutContainer>
    );
  }
}

export default withRegionCheck(withSubscription(AccountForm));
