import { useEffect, useState } from 'react';

function useStripe(apiKey: string, connectedAccount: string = null) {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const stripeJs = document.createElement('script');
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      if (connectedAccount) {
        setStripe(
          window.Stripe(apiKey, {
            stripeAccount: connectedAccount,
          })
        );
      } else {
        setStripe(window.Stripe(apiKey));
      }
    };
    document.body && document.body.appendChild(stripeJs);
  }, [apiKey, connectedAccount]);

  return [stripe];
}
export default useStripe;
