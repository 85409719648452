import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import coreTheme from './themes/core';
import lightTheme from './themes/light';
import darkTheme from './themes/dark';
// const darkTheme = lightTheme;
// const lightTheme = darkTheme;

type Props = {
  activeTheme: string;
  accentColour: string;
  children?: any;
};

const SupaPassThemeProvider = ({
  activeTheme = 'light',
  accentColour,
  children,
}: Props) => {
  let currentTheme; // console.log('setting ', key, consentCookie[key]);
  switch (activeTheme) {
    case 'light':
      currentTheme = Object.assign({}, coreTheme, lightTheme, {
        accentColour: accentColour,
      });
      break;
    default:
      currentTheme = Object.assign({}, coreTheme, darkTheme, {
        accentColour: accentColour,
      });
  }

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
};

export default SupaPassThemeProvider;
