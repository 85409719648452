import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const CollectionTitle = styled.div`
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 300;
  padding-bottom: 10px;

  ${media.medium`
    font-size: 32px;
    line-height: 42px;
  `}
`;
