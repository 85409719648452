import styled from 'styled-components';

export default function CardList({ cards }) {
  if (!cards) return null;
  if (!cards.length) return null;
  return (
    <ul>
      {cards.map((card) => (
        <CardRow key={card.lastFourDigits}>
          <span>{card.brand}</span>
          <span>**** {card.lastFourDigits}</span>
          <span>
            {card.expiryMonth}/{card.expiryYear}
          </span>
        </CardRow>
      ))}
    </ul>
  );
}

const CardRow = styled.li`
  display: block;

  span {
    display: inline-block;
    padding: 0 ${(p) => p.theme.padding}px ${(p) => p.theme.padding * 2}px 0;
  }
`;
