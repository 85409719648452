export function sortBySortOrderAudio(entities) {
  return [...entities].sort((a, b) => a.sortOrderAudio - b.sortOrderAudio);
}

export function sortBySortOrderVideo(entities) {
  return [...entities].sort((a, b) => a.sortOrderVideo - b.sortOrderVideo);
}

export const sortByPosition = (entities) =>
  [...entities].sort((a, b) => {
    return a.position - b.position;
  });
