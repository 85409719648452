import { LayoutContainer, PageBody, PageTitle } from '../../layout';
import { HelmetIntl } from '../../common';
import { Button } from '../../layout/components/Button';

export function PleaseLogin() {
  return (
    <LayoutContainer>
      <HelmetIntl
        titleId="app.meta.settings.subscriptions.title"
        descriptionId="app.meta.settings.subscriptions.description"
      />
      <PageBody isContainer>
        <PageTitle>Please login</PageTitle>
        <Button href="/login">Login</Button>
      </PageBody>
    </LayoutContainer>
  );
}
