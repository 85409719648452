import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const BundleBox = styled.li`
  background: ${(props) => props.theme.backgroundCard};
  /* padding: ${(props) => props.theme.padding * 2}px; */
  margin: ${(props) => (props.align === 'left' ? 0 : 'auto')};
  margin-bottom: ${(props) => props.theme.padding * 4}px;
  border-radius: ${(props) => props.theme.radius}px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  box-shadow: ${({ theme }) => theme.shadow.heavy};
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth.text};
  overflow: hidden;
  padding-bottom: ${(p) => p.theme.padding * 2}px;

  &:last-child {
    /* margin-bottom: 0; */
  }
  
  img {
    width: 100%;
  }

  ${media.medium`    
    margin-left:${(props) => (props.align === 'left' ? 0 : 'auto')}
    margin-right: auto;    
  `};
`;

export const BundleBoxInner = styled.div`
  padding: 0 ${(p) => p.theme.padding * 4}px;
`;

export const BundleList = styled.ul`
  margin: ${(p) => p.theme.padding * 4}px auto;

  ${media.medium`
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${(p) => p.theme.padding * 3}px;
    grid-row-gap: ${(p) => p.theme.padding * 3}px; */
  `}

  ${media.large`  
    /* grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: ${(p) => p.theme.padding * 3}px;
    grid-row-gap: ${(p) => p.theme.padding * 3}px;
     */
    /* border: 1px solid red; */
    /* .div1 {
      grid-area: 1 / 1 / 2 / 3;
    } */  
  `}

  li {
    height: 100%;
    a {
      height: 100%;
    }
  }
`;

export const BundleName = styled.h2`
  font-size: 18px;
  margin: ${(p) => p.theme.padding * 2}px 0;
`;

export const BundleDesc = styled.div`
  color: ${(props) => props.theme.foreground2};
  font-size: ${(props) => props.theme.type.regular};
  line-height: 20px;
  margin: ${(props) => props.theme.padding * 2}px 0;

  ul {
    display: block;
    padding-left: 24px;
    list-style-type: disc;
    li {
      display: list-item;
    }
  }
`;

export const BundleImage = styled.img`
  border-radius: ${(p) => p.theme.radius * 3}px;
  max-width: 450px;
  width: 100%;
  display: block;
`;

export const BundlePrice = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 0;
  margin-left: ${(props) => props.theme.padding}px;
  border: ${(p) => (p.isActive ? '1px solid red' : '1px solid transparent')};
`;

export const BundlePeriod = styled.span`
  margin-right: ${(props) => props.theme.padding}px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.foreground2};
  font-size: ${(props) => props.theme.type.xsmall};
`;

export const PlanView = styled.span`
  display: flex;
  align-items: center;
  padding: ${(p) => p.theme.padding * 2}px;
  /* margin-bottom: ${(p) => p.theme.padding * 2}px; */
  background: ${(p) => (p.isActive ? p.theme.backgroundActive : 'transparent')};

  cursor: pointer;
`;

export const PlanLabel = styled.label`
  font-size: 20px;
  display: block;
`;

export const PlanRadio = styled.div`
  padding: ${(p) => p.theme.padding}px;
  padding-right: ${(p) => p.theme.padding * 2}px;
  padding-left: 0;

  /* input {
    appearance: none;
    border: 1px solid red;
    width: 40px;
    height: 40px;
  } */
`;

export const PlanPrice = styled.div`
  font-size: 20px;
`;

export const PlanList = styled.ul`
  margin-top: ${(p) => p.theme.padding}px;
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.peak};
`;

export const CouponButton = styled.button`
  margin-top: ${(p) => p.theme.padding}px;
  padding: 0;
  text-decoration: underline;
  font-size: 14px;
  color: ${(p) => p.theme.foreground2};
  float: right;
`;

export const Coupon = styled.p`
  margin-top: ${(p) => p.theme.padding}px;
  font-weight: bold;
  color: ${(p) => p.theme.foreground2};
`;
