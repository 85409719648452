import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import * as N from './Navigation.styles';
import { Channel } from '../../common/types/Channel';
import { User } from '../../common/types/User';
import { useConfig } from '../../withConfig';

type Props = {
  channel: Channel;
  isMenuOpen: boolean;
  isScrolled: boolean;
  reference: any;
  user: User;
  canSubscribe: boolean;
};

function Navigation({
  channel,
  user,
  reference,
  isMenuOpen,
  canSubscribe,
}: Props) {
  const router = useRouter();

  // TODO Remove this hack. Sorry. :(
  const { features } = useConfig(channel.cleanname);

  return (
    <N.NavList ref={reference} isMenuOpen={isMenuOpen} data-test="channel-tabs">
      {!features.isUtility && (
        <>
          {channel.counts.video ? (
            <N.NavItem
              active={router.pathname === '/video'}
              data-test="tab--video">
              <Link href="/video">
                <a>Video</a>
              </Link>
            </N.NavItem>
          ) : null}

          {channel.counts.album ? (
            <N.NavItem
              active={router.pathname === '/audio'}
              data-test="tab--audio">
              <Link href="/audio">
                <a>Audio</a>
              </Link>
            </N.NavItem>
          ) : null}

          {channel.counts.article ? (
            <N.NavItem
              active={router.pathname === '/articles'}
              data-test="tab--articles">
              <Link href="/articles">
                <a>Articles</a>
              </Link>
            </N.NavItem>
          ) : null}
        </>
      )}

      {!user.isAuthed && (
        <>
          <N.NavItem autoLeft active={router.pathname === '/login'}>
            <Link href="/login">
              <a>
                <FormattedMessage id="app.user.login" />
              </a>
            </Link>
          </N.NavItem>

          {canSubscribe && !features.isUtility ? (
            <N.NavItem active={router.pathname === '/register'}>
              <Link href="/register">
                <a>
                  <FormattedMessage id="app.user.register" />
                </a>
              </Link>
            </N.NavItem>
          ) : null}
        </>
      )}
    </N.NavList>
  );
}

export default Navigation;
