import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const PageBody = styled.div`
  position: relative;
  padding-top: 84px;

  padding-left: ${(props) =>
    props.isContainer ? `${props.theme.padding * 2.5}px` : '0px'};
  padding-right: ${(props) =>
    props.isContainer ? `${props.theme.padding * 2.5}px` : '0px'};

  ${media.medium`
    padding-top: 112px;
    padding-left: ${(props) => (props.isContainer ? '4%' : '0px')};
    padding-right: ${(props) => (props.isContainer ? '4%' : '0px')};
  `};
`;

export const PageBody2 = styled.div`
  position: relative;
  padding-top: 64px;
  overflow: hidden;
  /* padding-left: ${(props) => (props.isContainer ? '16px' : '0px')}; */
  /* padding-right: ${(props) => (props.isContainer ? '16px' : '0px')}; */

  ${media.medium`    
    padding-top: 84px;
    
  `};
`;
