import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const Footer = styled.footer`
  background: ${(props) => props.theme.footerBackground};
  color: ${(props) => props.theme.footerForeground};
  padding: ${(props) => props.theme.padding * 2.5}px;
  border-top: 1px solid ${(p) => p.theme.peak};
  flex-shrink: 0;
  z-index: 1100;
  font-size: 1.2em;

  ${media.medium`
    padding-left: 4%;
    padding-right: 4%;
  `}

  ${media.large`
    margin-bottom: ${(p) => (p.hasPlayer ? '84px' : '0px')};
  `}
`;

export const FooterInner = styled.div`
  /* max-width: ${({ theme }) => theme.maxWidth.page}; */
  margin-left: auto;
  margin-right: auto;
  padding-top: ${(props) => props.theme.padding * 4}px;
  padding-bottom: ${(props) => props.theme.padding * 4}px;
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;

  ul {
    margin-right: ${(props) => props.theme.padding * 6}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const PoweredBy = styled.div`
  display: block;
  text-align: center;
  margin-top: ${(p) => p.theme.padding * 2}px;
  color: ${(p) => p.theme.foreground3};

  ${media.medium`
    margin-top: 0;
    text-align: right;
  `};
`;
