import { useState } from 'react';
import styled, { withTheme } from 'styled-components';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  StripeProvider,
  Elements,
  injectStripe,
} from 'react-stripe-elements';
import { FormattedMessage } from 'react-intl';
import useStripe from '../useStripe';
import { callAPI } from '../../api';
import { Spinner } from '../../common';
import {
  StyledRaisedButton,
  StyledFormError,
  StyledLabelWrapper,
} from '../../user/components/styles';
import { media } from '../../layout/themes/mediaQueries';

function CheckoutStripeGate({
  planId = null,
  coupon,
  channel,
  onComplete,
  buttonLabelId = 'app.checkout.label.paynow',
}) {
  if (!channel.stripePublicKey) {
    return (
      <div>Checkout is currently unavailable. Payments are not activated.</div>
    );
  }
  return (
    <CheckoutFormWrapper
      planId={planId}
      coupon={coupon}
      channel={channel}
      onComplete={onComplete}
      buttonLabelId={buttonLabelId}
    />
  );
}

function CheckoutFormWrapper({
  planId,
  coupon,
  channel,
  onComplete,
  buttonLabelId,
}) {
  const [stripe] = useStripe(
    channel.stripePublicKey || 'pk_test_k9AAkzSGvakXO4hP5IA7VrzE',
    channel.stripeConnectedAccountId || null
  );

  return (
    <>
      <StripeProvider stripe={stripe}>
        <Elements>
          <StripeCheckoutForm
            planId={planId}
            coupon={coupon}
            channel={channel}
            onComplete={onComplete}
            buttonLabelId={buttonLabelId}
          />
        </Elements>
      </StripeProvider>
    </>
  );
}

function CheckoutForm({
  theme,
  stripe,
  planId,
  coupon,
  channel,
  onComplete,
  buttonLabelId,
}) {
  const [validationError, setValidationError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const stripeOptions = createStripeOptions(theme);

  const isChangeCardForm = planId === null;

  async function onPayNow(e) {
    e.preventDefault();
    try {
      setValidationError(null);
      setIsSubmitting(true);
      // request the payment token from stripe
      console.log('Calling stripe...');
      const { error, token } = await stripe.createToken();
      if (error) {
        console.error(error);
        setIsSubmitting(false);
        return setValidationError(error.message);
      }

      // if there's no token, something failed. Try again.
      if (!token) {
        setIsSubmitting(false);
        setValidationError('Payment token not received!');
        return false;
      }

      if (!isChangeCardForm) {
        const { status, paymentSecret } = await submitPaymentToken(token);

        // handle 3DS validation request response
        if (status === 'ACTION_REQUIRED') {
          return handle3DSVerification(paymentSecret);
        }

        if (status === 'PAYMENT_METHOD_REQUIRED') {
          setIsSubmitting(false);
          setValidationError(
            'We were unable to take your payment. Please try a different card.'
          );
        }

        if (status === 'SUCCESS') {
          setIsSubmitting(false);
          onComplete();
        }
      } else {
        await updatePaymentMethodToken(token);
        setIsSubmitting(false);
        onComplete();
      }
    } catch (e) {
      setIsSubmitting(false);
      setValidationError(
        'There was an error creating your subscription. Your payment was not taken. Please try again.'
      );
      console.error(e);
    }
  }

  async function handle3DSVerification(paymentSecret) {
    const { error, paymentIntent } = await stripe.handleCardPayment(
      paymentSecret
    );

    if (error) {
      console.error(error);
      setIsSubmitting(false);
      return setValidationError(error.code);
    }

    if (paymentIntent.status === 'succeeded') {
      setIsSubmitting(false);
      onComplete();
    }
  }

  // use the payment token to create a subscription
  async function submitPaymentToken(token) {
    try {
      const res = await callAPI(
        '/user/iap/subscription',
        {
          cardToken: token.id,
          channelIdentifier: `${channel.artistId}`,
          planId: planId,
          couponId: coupon,
        },
        true,
        'POST'
      );

      if (res.status === 402) {
        throw new Error('The card was declined.');
      }

      if (res.status !== 200 && res.status !== 204) {
        throw new Error('There was an issue creating your subscription.');
      }

      return res.data;
    } catch (e) {
      console.error(e, e.data);
    }
  }

  // update the payment card token without subscribing
  async function updatePaymentMethodToken(token) {
    try {
      const res = await callAPI(
        `/user/channels/${channel.artistId}/stripe/payments/default-method`,
        {
          cardToken: token.id,
        },
        true,
        'PUT'
      );

      if (res.status === 402) {
        throw new Error('The card was not accepted.');
      }

      if (res.status !== 200 && res.status !== 204) {
        throw new Error('There was an issue updating your payment method.');
      }

      return res.data;
    } catch (e) {
      console.error(e, e.data);
    }
  }

  return (
    <StyledCheckoutFormContainer>
      <StyledCheckoutForm onSubmit={onPayNow}>
        <StyledLabelWrapper data-test="input-card-number">
          <span>Card number</span>
          <CardNumberElement {...stripeOptions} />
        </StyledLabelWrapper>
        <StyledCardFields>
          <StyledLabelWrapper data-test="input-card-expiry">
            <span>Expiry</span>
            <CardExpiryElement {...stripeOptions} />
          </StyledLabelWrapper>
          <StyledLabelWrapper data-test="input-card-cvc">
            <span>CVC</span>
            <CardCVCElement {...stripeOptions} />
          </StyledLabelWrapper>
        </StyledCardFields>

        <StyledFormError data-test="checkout-error">
          {validationError ? <p>{validationError}</p> : null}
        </StyledFormError>
        <StyledRaisedButton
          type="submit"
          disabled={isSubmitting}
          data-test="button-checkout-submit">
          {isSubmitting ? <Spinner /> : <FormattedMessage id={buttonLabelId} />}
        </StyledRaisedButton>
      </StyledCheckoutForm>
    </StyledCheckoutFormContainer>
  );
}

const StripeCheckoutForm = injectStripe(withTheme(CheckoutForm));

// create a style object for the Stripe Elements Inputs
// using our Styled Components theme.
const createStripeOptions = (theme) => {
  return {
    style: {
      base: {
        fontSize: '18px',
        color: theme.foreground,

        '::placeholder': {
          color: theme.border,
        },
        transition: 'all 0.2s',
      },
      invalid: {
        color: theme.action1,
      },
    },
  };
};

const StyledCheckoutForm = styled.form`
  .StripeElement {
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.border};
    padding: 12px 14px;
    border-radius: ${(p) => p.theme.radius}px;
    margin-bottom: ${(p) => p.theme.padding}px;
    line-height: 1.2em;
    font-family: 'museo-sans', helvetica, sans-serif;
    font-size: 16px !important;
    transition: background-color, border-color 0.2s;
  }

  .StripeElement--complete {
  }

  .StripeElement--empty {
  }

  .StripeElement--focus {
    border: 1px solid ${(p) => p.theme.borderActive};
    background: ${(p) => p.theme.backgroundActive};
  }

  .StripeElement--invalid {
  }

  .StripeElement--webkit-autofill {
  }
`;

const StyledCheckoutFormContainer = styled.div`
  margin: ${(p) => p.theme.padding * 2}px auto;
  width: 100%;

  ${media.large`
    /* max-width: 400px; */
  `};
`;

const StyledCardFields = styled.div`
  ${media.large`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${(p) => p.theme.padding * 3}px;
  `}
`;

export default CheckoutStripeGate;
