export enum PaymentProviderCode {
  S = 'S', // stripe
  A = 'A', // apple
  G = 'G', // google
}

export enum BundleSubscriptionStatus {
  A = 'A', // active
  PC = 'PC', // pending cancellation
  C = 'C', // cancelled
}

export type BundleSubscription = {
  id: number;
  bundleId: number;
  bundleUniqueName: string;
  channelId: number;
  channelUniqueName: string;
  endDate: string;
  cancellationDate?: string;
  status: BundleSubscriptionStatus;
  paymentProviderCode: PaymentProviderCode;
};
