import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const Header = styled.header`
  display: flex;
  z-index: 100;
  align-items: center;
  min-height: 48px;
  max-height: 64px;
  width: 100%;
  padding-left: ${(p) => p.theme.padding * 2.5}px;
  padding-right: ${(p) => p.theme.padding * 2.5}px;
  padding-top: ${(p) => p.theme.padding * 1.5}px;
  padding-bottom: ${(p) => p.theme.padding * 1.5}px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(p) =>
    p.isScrolled ? p.theme.background : 'transparent'};
  transition: background-color ${(p) => p.theme.animationNormal}s,
    box-shadow ${(p) => p.theme.animationNormal}s,
    padding ${(p) => p.theme.animationNormal}s;
  box-shadow: ${(p) =>
    p.isScrolled ? '1px 3px 12px rgba(0,0,0,0.3)' : 'none'};

  ${media.medium`
    padding-left: 4%;
    padding-right: 4%;
    padding-top: ${(p) =>
      p.isScrolled
        ? 0 //`${p => p.theme.padding}px`
        : p.theme.padding * 2}px; 
    padding-bottom: ${(p) =>
      p.isScrolled
        ? 0 //`${p => p.theme.padding}px`
        : p.theme.padding * 2}px; 
    max-height: 88px;
  `};
`;

export const Logo = styled.div`
  min-height: 24px;
  max-height: 40px;
  max-width: 160px;
  min-width: 80px;
  display: block;
  margin-left: ${(p) => p.theme.padding * 2}px;
  margin-right: auto;
  z-index: 98;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  a,
  a img {
    border-radius: 6px;
    max-height: 40px;
  }

  ${media.medium`
    min-height: 32px;
    max-height: 56px;
    max-width: 200px;
    margin-left: 0;
    margin-right: ${(p) => p.theme.padding * 2}px;
    a,
    a img {
      max-height: 56px;
    }

  `}
`;

export const LogoWrapper = styled.span`
  z-index: 99;
`;

export const Burger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: ${(p) => p.theme.burgerSize}px;
  height: ${(p) => p.theme.burgerSize}px;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100;
  
  span {
    width: ${(p) => p.theme.burgerSize}px;
    height: 0.18rem;
    background: ${({ theme, isMenuOpen }) =>
      isMenuOpen ? theme.foreground : theme.foreground};
    border-radius: 10px;
    transition: all 0.2s ease-out;
    position: relative;
    transform-origin: 0px;
    :first-child {
      transform: ${({ isMenuOpen }) =>
        isMenuOpen ? 'rotate(45deg)' : 'rotate(0)'};
    }
    :nth-child(2) {
      opacity: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '1')};
      /* transform: ${({ isMenuOpen }) =>
        isMenuOpen ? 'translateX(20px)' : 'translateX(0)'}; */
    }
    :nth-child(3) {
      transform: ${({ isMenuOpen }) =>
        isMenuOpen ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

  ${media.medium`
    display:none;
  `};
`;

export const AppIconWide = styled.img`
  display: block;
`;
