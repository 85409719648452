import { RES_RELEASES_SUCCESS, RES_RELEASE } from '../channel';
import { Track } from '../common/types/Track';
import { AppState } from '../common/types/AppState';

export default function reducer(state: AppState, action: any) {
  switch (action.type) {
    case RES_RELEASES_SUCCESS: {
      const { albums } = action.payload;
      const tracks = {};
      albums.map((release) =>
        release.tracks.map((track) => (tracks[track.trackId] = track))
      );
      return Object.assign({}, state, tracks);
    }

    case RES_RELEASE: {
      const release = action.payload;
      const tracks = {};
      release.tracks.map((track) => (tracks[track.trackId] = track));
      return { ...state, ...tracks };
    }

    default:
      return state || {};
  }
}

export const selectTracksById = (state: AppState, trackId: number): Track => {
  return state.entities.tracks[trackId];
};
