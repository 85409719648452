import styled, { css } from 'styled-components';
import { lighten } from 'polished';

const buttonStyle = css`
  background-color: ${(props) =>
    props.secondary ? props.theme.background : props.theme.accentColour};
  border-radius: ${(p) => p.theme.radius}px;

  border: 3px solid ${(props) => props.theme.accentColour};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: ${(props) => (props.secondary ? props.theme.foreground : '#fff')};
  font-weight: 600;
  /* letter-spacing: ${(props) => (props.narrow ? '0px' : '2px')}; */
  margin: ${({ theme, padding }) => (padding ? theme.padding * 3 : 0)}px auto 0;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  padding: ${(props) => (props.narrow ? '3px 8px' : '6px 12px')};
  text-align: center;
  text-shadow: rgba(50, 50, 93, 0.3) 0px 5px 15px,
    rgba(0, 0, 0, 0.1) 0px 2px 2px;
  /* text-transform: uppercase; */
  width: ${(props) => (props.inline ? '100px' : 'auto')};
  font-size: 1.2em;
 

  &:hover {
    background-color: ${(props) =>
      props.accent
        ? lighten(0.1, props.theme.accentColour || '#ccc')
        : lighten(0.1, props.theme.accentColour || '#ccc')};
    border: 3px solid
      ${(props) => lighten(0.1, props.theme.accentColour || '#ccc')};
    color: ${(props) => (props.secondary ? props.theme.foreground : '#fff')};
  }
`;

export const Button = styled.a`
  ${buttonStyle};
`;

export const FormButton = styled.button`
  ${buttonStyle};
`; // TODO when we upgrade to styled components v4 we can use "as" button

export const ButtonLink = styled.button`
  text-decoration: underline;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;
