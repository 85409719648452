export default {
  theme: 'dark',
  background: '#111111',
  background2: '#18191C',
  backgroundActive: '#222222',
  backgroundCard: '#333333',
  base: '#29292B',
  peak: '#2B2C33',
  hairline: 'rgba(255,255,255,.10)',
  foreground: '#FFFFFF',
  foreground2: '#BCBFCC',
  foreground3: '#888888',
  hover: 'rgba(188,191,204,.3)',
  footerBackground: 'rgba(0,0,0,.1)',
  footerForeground: '#cccccc',
  border: '#888',
  borderActive: '#AAAAAA',
  textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
  rowGradientEnd: '#1c1c1e',
  rowGradientStart: '#111111',
};
