import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';
import { media } from '../../layout/themes/mediaQueries';

const FormBase = css`
  max-width: 420px;
  margin: 0px auto;
  padding: 36px;
  border-radius: ${(props) => props.theme.radius}px;

  /*Change text in autofill textbox*/
  input:-webkit-autofill {
    -webkit-text-fill-color: ${(props) => props.theme.foreground} !important;
    -webkit-box-shadow: inset 0 0 0px 9999px ${(props) => props.theme.peak};
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }

  label.hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export const Form = styled.form`
  ${FormBase}
`;

export const WideForm = styled.form`
  ${FormBase}
  margin: 0;
  padding: 36px 0;
  max-width: 550px;
`;

export const StyledTextField = styled.input`
  box-sizing: border-box;
  margin: 0px 0 0;
  font-size: 16px;
  width: 100%;
  line-height: 1.2em;
  color: ${(props) => props.theme.foreground};
  background: ${(p) => p.theme.background};
  border: 1px solid ${(p) => p.theme.border};
  padding: 12px 14px;
  border-radius: ${(p) => p.theme.radius}px;
  margin-bottom: ${(p) => p.theme.padding}px;
  border-radius: ${(props) => props.theme.radius}px;
  border-bottom-left-radius: ${(props) =>
    props.flatBottom ? 0 : props.theme.radius}px;
  border-bottom-right-radius: ${(props) =>
    props.flatBottom ? 0 : props.theme.radius}px;
  transition: all 0.2s;
  font-weight: 400;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(p) => p.theme.background} inset !important;
    -webkit-text-fill-color: ${(p) => p.theme.foreground} !important;
    font-size: 16px;
  }

  ::placeholder {
    color: ${(p) => p.theme.border};
  }

  &:focus {
    border: 1px solid ${(p) => p.theme.borderActive};
    background: ${(p) => p.theme.backgroundActive};
  }
`;

export const StyledRaisedButton = styled.button`
  margin-top: 24px;
  padding: 8px 16px;
  line-height: 24px;
  font-weight: 700;
  display: ${(p) => (p.inline ? 'inline-block' : 'block')};
  border-radius: 4px;
  /* background: ${(props) => props.theme.accentColour}; */
  background-color: ${(props) =>
    props.secondary
      ? props.transparent
        ? 'transparent'
        : props.theme.background
      : props.theme.accentColour};
  width: ${(p) => (p.inline ? 'auto' : '100%')};
  font-size: ${(props) => props.theme.type.small};
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${(props) => (props.secondary ? props.theme.foreground : '#fff')};;

  :hover {
    /* background: ${(props) => lighten(0.1, props.theme.accentColour)}; */
    background-color: ${(props) =>
      props.secondary
        ? props.theme.background
        : lighten(0.1, props.theme.accentColour)};
      color: ${(props) =>
        props.secondary ? lighten(0.2, props.theme.foreground) : '#fff'};
  }

  :disabled {
    background: ${(props) => darken(0.1, props.theme.accentColour)};
  }
`;

export const StyledNote = styled.p`
  margin-top: ${(props) => props.theme.padding * 2}px;
  font-size: ${(props) => props.theme.type.small};
  text-align: center;
  a {
    color: ${(props) => props.theme.accentColour};
  }
`;

export const StyledNoteFlat = styled(StyledNote)`
  margin: 0;
`;

export const StyledFormError = styled.span`
  color: ${(props) => props.theme.action1};
  font-size: 14px;
  display: block;
  margin-left: 14px;
`;

export const H1 = styled.h1`
  font-size: ${(props) => props.theme.type.h1};
  margin-bottom: ${(props) => props.theme.padding}px;
`;

export const styledLabelBase = css`
  font-size: 15px;
  margin-bottom: 0px;
  color: ${(p) => p.theme.foreground2};
  display: block;
  font-weight: bold;
  width: 100%;
  border-radius: ${(p) => p.theme.radius};
  overflow: hidden;
`;

export const StyledLabel = styled.label`
  ${styledLabelBase}
  margin-top: ${(p) => p.theme.padding}px !important;
  margin-bottom: ${(p) => p.theme.padding / 2}px;

  ${media.large`
    margin-top: ${(p) => p.theme.padding}px !important;
  `}
`;

export const StyledLabelWrapper = styled.label`
  ${styledLabelBase}
  span {
    display: inline-block;
    margin-bottom: ${(p) => p.theme.padding / 2}px;
    margin-top: ${(p) => p.theme.padding * 1}px !important;
  }
`;

export const StyledFieldset = styled.fieldset`
  border: none;
  margin: ${(p) => p.theme.padding}px 0 0 0;
  padding: 0;
  label {
    display: block;
    /* text-align: center; */
    font-size: 15px;
    color: ${(p) => p.theme.foreround2};
    a {
      text-decoration: underline;
    }
  }
`;

export const StyledCheckbox = styled.input`
  width: 15px;
  height: 15px;
`;

export const StyledRadioWrapper = styled.input`
  input[type='radio'] {
    display: none;
  }

  input[type='radio']:before {
    width: 27px;
    height: 27px;
    background-color: #fff;
    left: -35px;
    box-sizing: border-box;
    border: 3px solid transparent;
    transition: border-color 0.2s;
  }
  input[type='radio']:after {
    content: '\f00c';
    font-family: 'FontAwesome';
    left: -31px;
    top: 2px;
    color: transparent;
    transition: color 0.2s;
  }
  input[type='checkbox']:checked + label span.checkbox::after {
    color: #62afff;
  }
`;
