import { combineReducers } from 'redux';
import { activeModal, isModalOpen } from './layout.reducers';
import { activeTheme, accentColour } from './Layout.redux';

export { media } from './themes/mediaQueries';
export { PageBody } from './components/PageBody';
export { PageTitle } from './components/PageTitle';
export { PageBlock } from './components/PageBlock';
export { Content } from './components/Content';
export { ListBlock, ListExpandButton } from './components/ListBlock';
export { ListWrapper, ListWrapper2 } from './components/ListWrapper';
export { ListTitle } from './components/ListTitle';
export { Button, FormButton } from './components/Button';
export { CollectionImage } from './components/CollectionImage';
export { CollectionTitle } from './components/CollectionTitle';
export { CollectionDetails } from './components/CollectionDetails';
export { CollectionHeader } from './components/CollectionHeader';
export { CollectionMeta } from './components/CollectionMeta';
export { ContentLockLabel } from './components/ContentLockLabel';
export { Grid } from './components/Grid';
export { default as Offscreen } from './components/Offscreen';
export { default as StickyOffset } from './components/StickyOffset';
export { default as Dropdown, DropdownLabel } from './components/Dropdown';
export {
  FullscreenBackground,
  BackgroundFade,
  ContentDate,
  ContentTitle,
  ContentSubtitle,
  ContentPerformers,
  ContentBody,
  YouTubeContainer,
} from './components/styled';
export { default as LayoutContainer } from './LayoutContainer';
export { selectActiveModal, selectIsModalOpen } from './layout.selectors';
export { doModalOpen, doModalClose } from './layout.actions';
export {
  BundleBox,
  BundleBoxInner,
  BundleName,
  BundleDesc,
  BundlePrice,
  BundlePeriod,
  BundleList,
  BundleImage,
  CouponButton,
  Coupon,
  PlanView,
  PlanLabel,
  PlanRadio,
  PlanPrice,
  PlanList,
} from './components/Bundle.styles';

// styled components

export const LayoutReducer = combineReducers({
  activeTheme,
  accentColour,
  activeModal,
  isModalOpen,
});
