export const REQ_CHANNEL: 'REQ_CHANNEL' = 'REQ_CHANNEL';
export const RES_CHANNEL_SUCCESS: 'RES_CHANNEL_SUCCESS' = 'RES_CHANNEL_SUCCESS';
export const RES_CHANNEL_FAIL: 'RES_CHANNEL_FAIL' = 'RES_CHANNEL_FAIL';
export const SET_ACTIVE_CHANNEL: 'SET_ACTIVE_CHANNEL' = 'SET_ACTIVE_CHANNEL';

export const REQ_RELEASES: 'REQ_RELEASES' = 'REQ_RELEASES';
export const RES_RELEASES_SUCCESS: 'RES_RELEASES_SUCCESS' =
  'RES_RELEASES_SUCCESS';
export const RES_RELEASES_FAIL: 'RES_RELEASES_FAIL' = 'RES_RELEASES_FAIL';

export const REQ_VIDEOS: 'REQ_VIDEOS' = 'REQ_VIDEOS';
export const RES_VIDEOS_SUCCESS: 'RES_VIDEOS_SUCCESS' = 'RES_VIDEOS_SUCCESS';
export const RES_VIDEOS_FAIL: 'RES_VIDEOS_FAIL' = 'RES_VIDEOS_FAIL';

export const REQ_VIDEO: 'REQ_VIDEO' = 'REQ_VIDEO';
export const RES_VIDEO: 'RES_VIDEO' = 'RES_VIDEO';
export const RES_VIDEO_FAIL: 'RES_VIDEO_FAIL' = 'RES_VIDEO_FAIL';

export const REQ_VIDEO_LINK: 'REQ_VIDEO_LINK' = 'REQ_VIDEO_LINK';
export const RES_VIDEO_LINK_SUCCESS: 'RES_VIDEO_LINK_SUCCESS' =
  'RES_VIDEO_LINK_SUCCESS';
export const RES_VIDEO_LINK_FAIL: 'RES_VIDEO_LINK_FAIL' = 'RES_VIDEO_LINK_FAIL';

export const REQ_FEED: 'REQ_FEED' = 'REQ_FEED';
export const RES_FEED_SUCCESS: 'RES_FEED_SUCCESS' = 'RES_FEED_SUCCESS';
export const RES_FEED_FAIL: 'RES_FEED_FAIL' = 'RES_FEED_FAIL';

export const REQ_RELEASE: 'REQ_RELEASE' = 'REQ_RELEASE';
export const RES_RELEASE: 'RES_RELEASE' = 'RES_RELEASE';
export const RES_RELEASE_FAIL: 'RES_RELEASE_FAIL' = 'RES_RELEASE_FAIL';

export const REQ_POST: 'REQ_POST' = 'REQ_POST';
export const RES_POST_SUCCESS: 'RES_POST_SUCCESS' = 'RES_POST_SUCCESS';
export const RES_POST_FAIL: 'RES_POST_FAIL' = 'RES_POST_FAIL';

export const REQ_CONFIG: 'REQ_CONFIG' = 'REQ_CONFIG';
export const RES_CONFIG_SUCCESS: 'RES_CONFIG_SUCCESS' = 'RES_CONFIG_SUCCESS';
export const RES_CONFIG_FAIL: 'RES_CONFIG_FAIL' = 'RES_CONFIG_FAIL';

export const SET_ACTIVE_COLLECTION: 'SET_ACTIVE_COLLECTION' =
  'SET_ACTIVE_COLLECTION';
