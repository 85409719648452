/**
 * Extract the pertinent video ID from various
 * possible YouTube URL formats.
 *
 * Oh, for this to be done server side...
 */
export const getYouTubeId = (url: string): string => {
  var regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
  if (!url || !url.length) return '0';
  var match = url.match(regExp);
  if (match && match[1].length === 11) {
    return match[1];
  } else {
    return '0';
  }
};

export const createYouTubeThumbnailUrl = (videoId: string) => {
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
};
