import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

type Props = {
  title?: string;
  subtitle?: string;
};

const ListHeader = ({ title, subtitle }: Props) => (
  <Component>
    {title && (
      <Title>
        <FormattedMessage id={title} />
      </Title>
    )}
    {subtitle && (
      <Subtitle>
        <FormattedMessage id={subtitle} />
      </Subtitle>
    )}
  </Component>
);

const Component = styled.div`
  height: 52px;
  display: flex;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.foreground};
  padding: 12px;
`;

const Title = styled.span`
  align-self: flex-end;
  margin-bottom: 16px;
`;

const Subtitle = styled.span`
  display: block;
  width: 100%;
`;

export default ListHeader;
