// network actions
export const REQ_STREAM_AUDIO: 'REQ_STREAM_AUDIO' = 'REQ_STREAM_AUDIO';
export const RES_STREAM_AUDIO_SUCCESS: 'RES_STREAM_AUDIO_SUCCESS' =
  'RES_STREAM_AUDIO_SUCCESS';
export const RES_STREAM_AUDIO_FAILURE: 'RES_STREAM_AUDIO_FAILURE' =
  'RES_STREAM_AUDIO_FAILURE';

// user invoked public actions
export const DO_PLAYBACK_TRACK: 'DO_PLAYBACK_TRACK' = 'DO_PLAYBACK_TRACK';
export const DO_PLAYBACK_PLAY: 'DO_PLAYBACK_PLAY' = 'DO_PLAYBACK_PLAY';
export const DO_PLAYBACK_PAUSE: 'DO_PLAYBACK_PAUSE' = 'DO_PLAYBACK_PAUSE';
export const DO_PLAYBACK_SEEK: 'DO_PLAYBACK_SEEK' = 'DO_PLAYBACK_SEEK';
export const DO_PLAYBACK_STOP: 'DO_PLAYBACK_STOP' = 'DO_PLAYBACK_STOP';

// player invoked private actions
export const DID_PLAYER_READY: 'DID_PLAYER_READY' = 'DID_PLAYER_READY';
export const DID_PLAYBACK_PLAY: 'DID_PLAYBACK_PLAY' = 'DID_PLAYBACK_PLAY';
export const DID_PLAYBACK_PAUSE: 'DID_PLAYBACK_PAUSE' = 'DID_PLAYBACK_PAUSE';
export const DID_PLAYBACK_SEEK: 'DID_PLAYBACK_SEEK' = 'DID_PLAYBACK_SEEK';
export const DID_PLAYBACK_COMPLETE: 'DID_PLAYBACK_COMPLETE' =
  'DID_PLAYBACK_COMPLETE';
export const DID_PLAYBACK_ERROR: 'DID_PLAYBACK_ERROR' = 'DID_PLAYBACK_ERROR';

export const DID_PLAYER_SCRIPT_LOAD: 'DID_PLAYER_SCRIPT_LOAD' =
  'DID_PLAYER_SCRIPT_LOAD';
export const DID_PLAYER_SCRIPT_ERROR: 'DID_PLAYER_SCRIPT_ERROR' =
  'DID_PLAYER_SCRIPT_ERROR';
