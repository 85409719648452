import createEntityReducer from '../createEntityReducer';
import { RES_VIDEOS_SUCCESS, RES_VIDEO } from '../channel';
import { Video } from '../common/types/Video';
import { Action } from '../common/types/Actions';
import { AppState } from '../common/types/AppState';
import { StreamingUrlTypes } from '../common/types/StreamingUrlTypes';

export const VideosEntityReducer = createEntityReducer(
  { key: 'videos', id: 'id' },
  ['REQ_VIDEOS', 'RES_VIDEOS_SUCCESS', 'RES_VIDEOS_FAIL']
);

export const VideoCollectionsEntityReducer = createEntityReducer(
  { key: 'collections', id: 'id' },
  ['REQ_VIDEOS', 'RES_VIDEOS_SUCCESS', 'RES_VIDEOS_FAIL']
);

export const selectCollectionById = (state: AppState, id) =>
  state.entities.collections.byId[id];

export const selectCollectionsByIds = (state: AppState, ids) =>
  ids.map((id) => selectCollectionById(state, id));

export const selectCollectionIds = (state: AppState) =>
  state.entities.collections.allIds;

export const selectCollections = (state: AppState) => {
  const collectionIds = selectCollectionIds(state);
  return collectionIds.map((id) => selectCollectionById(state, id));
};

export const selectVideoById = (state: AppState, id: number) =>
  state.entities.videos.byId[id];

// TODO Remove legacy videos reducer, maybe moving to channelVideos
// relational lookup

/* The shape of our collection of Releases */
type VideosCollection = {
  [key: number]: Array<Video>;
};

/* The default state of the collection */
const defaultState = {};

/**
 * Reduce a list of videos into an object
 * containing an array of videos for
 * each channel id.
 *
 * {
 *   [channelId: number]: Array<Release>
 * }
 *
 * @param {State} state
 * @param {Object} action
 */
export default function reducer(
  state: VideosCollection = defaultState,
  // @ts-ignore
  action: Action
): VideosCollection {
  switch (action.type) {
    case RES_VIDEOS_SUCCESS: {
      const { artistId, videos } = action.payload;
      const videosObj = {
        [artistId]: [],
      };
      videos.map((video) => {
        return videosObj[artistId].push(video);
      });
      return Object.assign({}, state, videosObj);
    }
    case RES_VIDEO: {
      const { artistId: id } = action.payload;
      const obj = {
        [id]: [action.payload],
      };
      return { ...state, ...obj };
    }
    default:
      return state;
  }
}

/**
 * Select an array of releases by channel ID
 *
 * @param {JSONObject} state
 * @param {number} channelId
 */
export const selectVideosByChannelId = (
  state: AppState,
  channelId: number
): Array<Video> => {
  return state.entities.videos.allIds.map((id) => selectVideoById(state, id));
};

export const sortVideosByDate = (videos: Array<Video>): Array<Video> => {
  return videos.slice().sort((a, b): number => {
    if (a.createdAt > b.createdAt) return -1;
    if (a.createdAt < b.createdAt) return 1;
    return 0;
  });
};

export const sortVideosAlphabetically = (
  videos: Array<Video>
): Array<Video> => {
  return videos.slice().sort((a, b) => {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });
};

export const selectCurrentVideoUrl = (state: AppState): StreamingUrlTypes => {
  return state.currentVideo;
};
