// show overlay with accept and -> settings.
// clicking settings will show the option to disable advertising cookies
// you still have to click accept to allow
// prevent login /register / checkout until the user has accepted the cookie policy
import { useState } from 'react';
import styled from 'styled-components';
import Switch from 'react-switch';
import Cookies from 'js-cookie';
import { StyledRaisedButton } from './styles';
import { media } from '../../layout/themes/mediaQueries';
import { useDispatch, useSelector } from 'react-redux';
import { doSetCookieConsent } from '../user.actions';
import { selectConsent } from '../user.selectors';
import cookieParse from 'cookie-parse';

export const COOKIE_CONSENT_TOKEN = 'SPCONSENT';
export const cookieOptions = {
  expires: 1000,
};

export function extractConsentCookieFromRequest(req) {
  let cookies;

  if (!req) {
    return {};
  }

  if (req.headers.cookie) {
    cookies = cookieParse.parse(req.headers.cookie);
  }

  if (typeof cookies === 'undefined') {
    return {};
  }

  if (typeof cookies[COOKIE_CONSENT_TOKEN] === 'undefined') {
    return {};
  }

  return JSON.parse(cookies[COOKIE_CONSENT_TOKEN]);
}

function CookieConsent() {
  const [analyticsSwitch, setAnalyticsSwitch] = useState(true);
  const dispatch = useDispatch();
  const consent = useSelector(selectConsent);

  function handleAcceptCookies() {
    dispatch(doSetCookieConsent('seen', true));
    dispatch(doSetCookieConsent('analytics', analyticsSwitch));
    Cookies.set(
      COOKIE_CONSENT_TOKEN,
      {
        seen: true,
        analytics: analyticsSwitch,
      },
      cookieOptions
    );
  }

  function handleAnalyticsSwitchChanged(e) {
    setAnalyticsSwitch(e);
  }

  if (consent.seen) {
    return null;
  }

  return (
    <Component>
      <h2>Your privacy</h2>
      <p>
        We use cookies to give you the best experience. Please let us know you
        agree to this.
      </p>

      <SwitchAndLabel>
        <Switch
          onChange={handleAnalyticsSwitchChanged}
          checked={analyticsSwitch}
        />
        <span>
          Help improve my experience with anonymous analytics and error reports.
        </span>
      </SwitchAndLabel>

      <StyledRaisedButton onClick={handleAcceptCookies} inline>
        Agree
      </StyledRaisedButton>
      <StyledRaisedButton
        as="a"
        href="/eula"
        target="_new"
        inline
        secondary
        transparent>
        Privacy Policy
      </StyledRaisedButton>
    </Component>
  );
}

const Component = styled.div`
  position: fixed;
  display: ${(p) => (p.hide ? 'none' : 'block')};
  bottom: ${(p) => p.theme.padding * 2}px;
  right: ${(p) => p.theme.padding * 2}px;
  left: ${(p) => p.theme.padding * 2}px;
  background: ${(p) => p.theme.backgroundCard};
  color: ${(p) => p.theme.foreground};
  padding: ${(p) => p.theme.padding * 2}px;
  border-radius: ${(p) => p.theme.radius * 2}px;
  /* box-shadow: ${(p) => p.theme.shadow.heavy}; */
  box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
  z-index: 30000;
  max-width: 500px;
  border: 1px solid ${(p) => p.theme.peak};

  a,
  button {
    letter-spacing: 0;
    &:hover {
      pointer: cursor;
    }
  }

  ${media.large`
    left: auto;
    bottom: ${(p) => p.theme.padding * 3}px;
    right: ${(p) => p.theme.padding * 3}px;
  `}
`;

const SwitchAndLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-left: ${(p) => p.theme.padding * 2}px;
  }
`;

export default CookieConsent;
