import styled from 'styled-components';
import { media } from '../themes/mediaQueries';

export const Grid = styled.div`
  ${media.large`
    display: grid;
    grid-template-columns: repeat(2, 1fr);    
    grid-column-gap: ${(p) => p.theme.padding * 3}px;
  `}
`;
