import { FormattedMessage } from 'react-intl';
import { LayoutContainer, PageBody, PageTitle } from '../../layout';
import { HelmetIntl } from '../../common';
import SubscriptionCard from '../../subscribe/components/SubscriptionCard';
import PaymentMethod from '../../subscribe/components/PaymentMethod';
import { Button } from '../../layout/components/Button';
import { PageBlock } from '../../layout/components/PageBlock';
import { User } from '../../common/types/User';
import { Channel } from '../../common/types/Channel';
import { Bundle } from '../../common/types/Bundle';
import { PleaseLogin } from './PleaseLogin';

type Props = {
  channel: Channel;
  user: User;
  bundles: Bundle[];
};

function SubscriptionsPage({ channel, user, bundles }: Props) {
  if (!user.isAuthed) {
    return <PleaseLogin />;
  }

  const getBundleByUniqueName = (uniqueName) => {
    return bundles.find((bundle) => bundle.uniqueName === uniqueName);
  };

  // we only need the bundlePlans for the user on THIS channel
  const userBundleSubscriptionsForChannel = user.profile.bundleSubscriptions?.filter(
    (bundleSubscription) =>
      bundleSubscription.channelUniqueName === channel.cleanname
  );

  return (
    <LayoutContainer>
      <HelmetIntl
        titleId="app.meta.settings.subscriptions.title"
        descriptionId="app.meta.settings.subscriptions.description"
      />
      <PageBody isContainer>
        <PageTitle>Active Subscriptions</PageTitle>

        {userBundleSubscriptionsForChannel.length > 0 ? (
          userBundleSubscriptionsForChannel.map((bundleSubscription) => {
            return (
              <SubscriptionCard
                key={bundleSubscription.bundleUniqueName}
                subscription={bundleSubscription}
                channel={channel}
                bundle={getBundleByUniqueName(
                  bundleSubscription.bundleUniqueName
                )}
              />
            );
          })
        ) : (
          <>You have no active subscriptions.</>
        )}

        <PaymentMethod channel={channel} />
      </PageBody>
    </LayoutContainer>
  );
}

export default SubscriptionsPage;
