import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { media } from '../themes/mediaQueries';

export const NavList = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  background-color: ${(p) => p.theme.background};
  top: -30px;
  left: ${(p) => (p.isMenuOpen ? 0 : '-9999px')};
  width: 100%;
  margin: 0;
  padding: 64px 0 0 0;
  box-shadow: 1px 13px 12px rgba(0, 0, 0, 0.3);
  transition: ${(p) =>
    p.isMenuOpen
      ? 'opacity 0.25s, transform 0.25s, max-height 0s 0s'
      : 'opacity 0.25s 0.05s, transform 0.25s 0.05s, max-height 0s 2s, left 0s 1s'};

  transform: translateY(${(p) => (p.isMenuOpen ? '30px' : '0px')});
  max-height: ${(p) => (p.isMenuOpen ? '400px' : '0')};
  overflow: hidden;
  opacity: ${(p) => (p.isMenuOpen ? 1 : 0)};
  z-index: 1;
  pointer-events: ${(p) => (p.isMenuOpen ? 'auto' : 'none')};

  /* ::after {
    content: '';
    background-image: url(${(p) => p.image});
    background-size: cover;
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  } */

  span {
    position: relative;
    /* top: -20px; */
    /* transform: translateY(${(p) => (p.isMenuOpen ? '20px' : '0px')}); */
    transition: ${(p) =>
      p.isMenuOpen
        ? 'opacity 0.25s 0.1s, transform 0.25s .1s'
        : 'opacity 0.25s 0s, transform 0.25s 0s'};
  }

  span:nth-child(2) {
    transition: ${(p) =>
      p.isMenuOpen
        ? 'opacity 0.25s 0.15s, transform 0.25s .1s'
        : 'opacity 0.25s 0s, transform 0.25s 0s'};
  }

  span:nth-child(3) {
    transition: ${(p) =>
      p.isMenuOpen
        ? 'opacity 0.25s 0.2s, transform 0.25s .1s'
        : 'opacity 0.25s 0s, transform 0.25s 0s'};
  }

  span:nth-child(4) {
    transition: ${(p) =>
      p.isMenuOpen
        ? 'opacity 0.25s 0.25s, transform 0.25s .1s'
        : 'opacity 0.25s 0s, transform 0.25s 0s'};
  }

  span:nth-child(5) {
    transition: ${(p) =>
      p.isMenuOpen
        ? 'opacity 0.25s 0.3s, transform 0.25s .1s'
        : 'opacity 0.25s 0s, transform 0.25s 0s'};
  }

  /* ensures margin at the bottom of list on mobile nav, reset on medium*/
  span:last-child {
    margin-bottom: ${(p) => p.theme.padding * 2}px;
  }

  ${media.medium`
    top: 0;
    left: 0;
    padding: 0;
    opacity: 1;
    flex-direction: row;
    justify-content: flex-start;
    position: static;
    margin-right: auto;
    background: transparent;
    pointer-events: auto;
    box-shadow: none;
    height: 56px;
    max-height: 100%;

    span {
      position: relative;
      top: 0px;
      transform: none;
      transition: none
      opacity: 1;
    }

    span:last-child {
      margin-bottom: 0;
    }
  `};
`;

const buttonBase = css`
  display: inline-block;
  font-size: ${(p) => (p.small ? '16px' : '32px')};
  transition: all 0.3s ease-in-out;
  color: ${(p) => (p.active ? p.theme.foreground : p.theme.foreground2)};
  font-weight: ${(p) => (p.active ? 'bold' : 'normal')};
  justify-items: center;
  justify-content: center;
  padding: ${(p) => p.theme.padding / 3}px ${(p) => p.theme.padding}px;
  border-radius: ${(p) => p.theme.radius}px;
  color: ${(p) => p.theme.foreground};
  text-shadow: ${(p) => p.theme.textShadow};
  margin: 0 auto;
  
  
  &:hover {
    color: ${(p) => p.theme.foreground};
    background-color ${(p) => p.theme.peak};
  }

  ${media.medium`
    display: inline-block;
    font-size: 16px;
    height: auto;
    /* margin: 0 0; */
  `}`;

export const NavItem = styled.span`
  display: flex;
  white-space: nowrap;
  height: 56px;
  align-items: center;
  text-align: center;
  width: 100%;

  ${media.medium`
    width: auto;
    margin-left: ${(p) => (p.autoLeft ? 'auto' : `${p.theme.padding}px`)};
  `}

  a {
    ${buttonBase};
  }
`;

export const NavButton = styled.span`
  z-index: 100;
  text-shadow: rgba(50, 50, 93, 0.3) 0px 5px 15px,
    rgba(0, 0, 0, 0.1) 0px 2px 2px;

  ${media.medium`
    margin-left ${(p) => p.theme.padding}px;
  `}

  a {
    ${buttonBase};
    background-color: ${(p) => p.theme.accentColour};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    color: ${(p) => p.theme.white};
    font-size: 14px;
    font-weight: bold;
  }

  a:hover {
    background-color: ${(p) => darken(0.1, p.theme.accentColour)};
    color: ${(p) => p.theme.white};
  }
`;

export const UserNavigation = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserAccountButton = styled.span`
  display: flex;
  flex-direction: row;
  margin-right: ${(p) => p.theme.padding}px;
`;
