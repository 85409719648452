import axios from 'axios';

async function callLocalAPI(endpoint, req) {
  let host;

  if (typeof window === 'undefined') {
    throw new Error('window and request missing in API call.');
  }

  if (typeof window !== 'undefined') {
    host = `${window.location.protocol}//${window.location.hostname}${
      window.location.port !== '80' ? `:${window.location.port}` : null
    }`;
  }

  try {
    const res = await axios.get(`${host}${endpoint}`);
    return res.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function doGeoLookup(req = null) {
  const endpoint = `/api/geolookup`;
  const geo = await callLocalAPI(endpoint, req);
  return geo;
}
