import { ButtonLink } from '../../layout/components/Button';
import { callAPI } from '../../api';
import {
  BundleSubscription,
  BundleSubscriptionStatus,
} from '../../common/types/BundleSubscription';
import { useState } from 'react';
import { Spinner } from '../../common';

type Props = {
  subscription: BundleSubscription;
  channelId: number;
  onError: (error: string) => void;
  onSuccess: () => void;
};

export default function UnsubscribeButton({
  subscription,
  channelId,
  onError,
  onSuccess,
}: Props) {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  // if it isn't an active subscription we can't cancel it
  if (subscription.status !== BundleSubscriptionStatus.A) {
    return null;
  }

  async function handleClick() {
    if (
      window.confirm('Are you sure you want to cancel your subscription?') ===
      false
    ) {
      return;
    }
    try {
      setIsFetching(true);
      onError('');
      const res = await callAPI(
        `/user/channels/${channelId}/subscriptions/${subscription.id}`,
        null,
        true,
        'DELETE'
      );
      setIsFetching(false);
      if (res.status >= 400 && res.status <= 500) {
        return onError(
          'Sorry, there was a problem cancelling your subscription.'
        );
      }
      setIsSuccess(true);
      onSuccess();
    } catch (e) {
      setIsFetching(false);
      onError(e.message);
      console.error(e);
    }
  }

  return (
    <ButtonLink
      className="text-foreground-secondary"
      disabled={isFetching}
      onClick={handleClick}
      data-testid="button-unsubscribe">
      {!isSuccess && !isFetching && 'Cancel subscription'}{' '}
      {isFetching && <Spinner />}
    </ButtonLink>
  );
}
