import { combineReducers } from 'redux';
import {
  RES_CHANNEL_SUCCESS,
  REQ_RELEASE,
  RES_RELEASE,
  REQ_VIDEO,
  RES_VIDEO,
  SET_ACTIVE_CHANNEL,
  SET_ACTIVE_COLLECTION,
} from './channel.constants';

import { ChannelAction } from '../channel/channel.types';
import { Release } from '../releases';
import { Video } from '../common/types/Video';

export const channelId = (state: number = 2058, action: ChannelAction) => {
  switch (action.type) {
    // case REQ_CHANNEL:
    //   return 0;
    case RES_CHANNEL_SUCCESS: {
      const { artistId } = action.payload;
      return artistId;
    }
    case SET_ACTIVE_CHANNEL: {
      const { channelId } = action.payload;
      return channelId;
    }
    default:
      return state;
  }
};

export const currentRelease = (
  state: Release | null | undefined = null,
  action: ChannelAction
) => {
  switch (action.type) {
    case REQ_RELEASE:
      return null;
    case RES_RELEASE:
      return action.payload;
    default:
      return state;
  }
};

export const currentVideo = (
  state: Video | null | undefined = null,
  action: ChannelAction
) => {
  switch (action.type) {
    case REQ_VIDEO:
      return null;
    case RES_VIDEO:
      return action.payload;
    default:
      return state;
  }
};

export const currentReleaseChannel = (
  state: string = '',
  action: ChannelAction
) => {
  switch (action.type) {
    case RES_RELEASE: {
      // payload is a release
      // releae has array of tracks which have the artist name
      // if they all match use that, if they vary use "various artists"
      // @ts-ignore
      const { tracks } = action.payload;

      let channel = '';

      if (tracks.length > 0) {
        channel = tracks[0].artist || '';
        tracks.forEach((track) => {
          if (track.artist !== channel) {
            channel = 'Various Artists';
          }
        });
      }

      return channel;
    }
    default:
      return state;
  }
};

const currentCollection = (state = 0, action) => {
  switch (action.type) {
    case SET_ACTIVE_COLLECTION:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  channelId,
  currentRelease,
  currentReleaseChannel,
  currentVideo,
  currentCollection,
});
