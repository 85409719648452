import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../../common';
import { callAPI } from '../../api';
import CheckoutForm from './CheckoutForm';
import { PageTitle } from '../../layout/components/PageTitle';
import { PageBlock } from '../../layout/components/PageBlock';
import { ButtonLink } from '../../layout/components/Button';
import CardList from './CardList';

export default function PaymentMethod({ channel }) {
  const [paymentMethods, setPaymentMethods] = useState();
  const [isChangeCardFormVisible, setIsChangeCardFormVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const paymentMethods = await callAPI(
          `/user/channels/${channel.cleanname}/stripe/payments`,
          null,
          true
        );

        setIsLoading(false);

        if (paymentMethods?.data?.length > 0) {
          setPaymentMethods(paymentMethods.data);
        } else {
          // no payment methods. add one.
          setPaymentMethods([]);
          setIsChangeCardFormVisible(true);
        }

        // if we don't have a card on record, we're probably here to fill out the card form
        if (paymentMethods?.data?.length === 0) {
          setIsChangeCardFormVisible(true);
        }
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    })();
  }, [successMessage, channel.cleanname]);

  return (
    <PageBlock>
      <PageTitle className="mt-10">Payment Method</PageTitle>
      {isLoading && <Spinner />}

      <CardList cards={paymentMethods} />

      <ButtonLink
        onClick={() => {
          setIsChangeCardFormVisible(!isChangeCardFormVisible);
          setSuccessMessage('');
        }}>
        <FormattedMessage
          id={
            isChangeCardFormVisible
              ? 'app.checkout.label.dontchange'
              : 'app.checkout.label.changecard'
          }
        />
      </ButtonLink>

      {successMessage && <p>{successMessage}</p>}

      {isChangeCardFormVisible && (
        <CheckoutForm
          channel={channel}
          onComplete={() => {
            setIsChangeCardFormVisible(false);
            setSuccessMessage('Your card was updated successfully.');
          }}
          buttonLabelId="app.checkout.label.changecard"
        />
      )}
    </PageBlock>
  );
}
