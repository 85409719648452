import { RSAA } from 'redux-api-middleware';
import {
  REQ_BUNDLES,
  RES_BUNDLES_FAILURE,
  RES_BUNDLES_SUCCESS,
  REQ_BUNDLE_PLANS,
  RES_BUNDLE_PLANS_FAILURE,
  RES_BUNDLE_PLANS_SUCCESS,
} from './subscribe.constants';

export const doFetchBundlePlans = (uniqueName) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/channel/${uniqueName}/bundlePlans/stripe`,
      headers: { 'Content-Type': 'application/json' },
      types: [
        REQ_BUNDLE_PLANS,
        RES_BUNDLE_PLANS_SUCCESS,
        RES_BUNDLE_PLANS_FAILURE,
      ],
    },
  };
};

export const doFetchBundles = (uniqueName) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/channel/${uniqueName}/bundles`,
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_BUNDLES, RES_BUNDLES_SUCCESS, RES_BUNDLES_FAILURE],
    },
  };
};
