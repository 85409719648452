import styled from 'styled-components';

export default function Disallowed({ geo }) {
  return geo === null ? null : (
    <Overlay>
      <p>This service is not available in your region.</p>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  color: ${(p) => p.theme.foreground};
  font-weight: bold;
  font-size: 20px;
`;
